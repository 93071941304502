import { Button, Link, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, Switch, Route } from 'react-router-dom';
import { selectAccountRoles } from '../../features/session';
import { includesSome } from '../../utils';

const AppBarNavListItem = ({ to, title, active, onSelected, isBusy, disabled, noContractor }) => (
  <Link tabIndex="-1" as={RouterLink} to={to} onClick={onSelected}>
    <Button
      colorScheme="brand"
      color="white"
      variant="link"
      disabled={isBusy || noContractor || disabled}
      fontSize={active && 'larger'}
      fontWeight={active && 'bold'}
    >
      {title}
    </Button>
  </Link>
);

AppBarNavListItem.defaultProps = {
  active: false,
  to: null,
  isBusy: false,
  noContractor: true,
  onSelected: undefined,
  disabled: false,
};
AppBarNavListItem.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onSelected: PropTypes.func,
  isBusy: PropTypes.bool,
  noContractor: PropTypes.bool,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

const AppBarNavItem = (props) => {
  const { to: linkTo } = props;
  return (
    <Switch>
      <Route exact path={linkTo} render={() => <AppBarNavListItem active {...props} />} />
      <Route path="/" render={() => <AppBarNavListItem {...props} />} />
    </Switch>
  );
};

AppBarNavItem.propTypes = {
  to: PropTypes.string.isRequired,
};

const AppBarNavList = (props) => {
  const { pages, onSelected, isBusy, display, noContractor } = props;
  const { t } = useTranslation();
  const accountRoles = useSelector(selectAccountRoles);
  return (
    <HStack spacing={6} display={display}>
      {pages
        .filter((page) => page.roles.length === 0 || includesSome(page.roles, accountRoles))
        .map((page) => (
          <AppBarNavItem
            key={page.index}
            to={page.to}
            title={t(`${page.title}`, `${page.defaultTitle}`)}
            isBusy={isBusy}
            noContractor={page.checkContractor === true ? noContractor : false}
            onSelected={onSelected}
          />
        ))}
    </HStack>
  );
};
AppBarNavList.defaultProps = {
  isBusy: false,
  noContractor: true,
  onSelected: undefined,
  display: 'block',
};

AppBarNavList.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({ index: PropTypes.number, to: PropTypes.string, title: PropTypes.string }))
    .isRequired,
  onSelected: PropTypes.func,
  isBusy: PropTypes.bool,
  noContractor: PropTypes.bool,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AppBarNavList;
