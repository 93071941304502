import {
  Flex,
  Box,
  Heading,
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
  useMediaQuery,
  Radio,
  RadioGroup,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Divider,
  Text,
  Input,
  Spacer,
  HStack,
  Select,
  Tooltip,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {
  adjustmentFundsAsync,
  topUpFundsAsync,
  increaseContractorFundsAsync,
  selectContractor,
  selectContractorView,
} from '../features/account';
import { selectIsBusy } from '../features/app';
import {
  PAYMENT_METHOD,
  showStripeAsync,
  finalizeStripeP24PaymentAsync,
  selectStripeWaitingForPaymentSucceeded,
} from '../features/stripe';
import { selectAccountRoles } from '../features/session';
import { includesSome } from '../utils';

const TopUpPanel = () => {
  const [paymentType, setPaymentType] = useState(PAYMENT_METHOD.CARD);
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isBusy = useSelector(selectIsBusy);
  const contractorView = useSelector(selectContractorView);
  const waitingForPaymentSucceeded = useSelector(selectStripeWaitingForPaymentSucceeded);
  const contractor = useSelector(selectContractor);
  const [customValue, setCustomValue] = useState(10);
  const accountRoles = useSelector(selectAccountRoles);
  const admin = includesSome(['admin'], accountRoles);
  const [adjustmentDescription, setAdjustmentDescription] = useState('');
  const [adjustmentValue, setAdjustmentValue] = useState(0);
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [invoiceValue, setInvoiceValue] = useState(0);
  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState('Cash');

  useEffect(() => {
    if (parsed.redirect_status) {
      dispatch(finalizeStripeP24PaymentAsync({ contractorId: contractorView?.id, complete: parsed }));
      history.push(location.pathname);
    }
  }, [parsed]);

  const handleTopUp = (amount, paymentMethod) => () => {
    dispatch(
      showStripeAsync({
        paymentMethod,
        description: adjustmentDescription,
        contractorId: contractorView.id,
        title: `${t('pages.funds.AccountTopUp', 'Account top up')} ${amount.toFixed(2)} PLN`,
        items: [{ stockId: 4, quantity: amount }],
        actions: {
          success: ({ paymentId }) =>
            increaseContractorFundsAsync({
              contractorId: contractorView.id,
              paymentId,
            }),
        },
      }),
    );
  };

  const resetAdjustmentState = () => {
    setAdjustmentDescription('');
    setAdjustmentValue(0);
  };

  const handleAdjustmentFunds = (amount) => () => {
    dispatch(adjustmentFundsAsync({ contractorId: contractorView.id, amount, description: adjustmentDescription }));
    resetAdjustmentState();
  };

  const resetInvoiceState = () => {
    setInvoiceDescription('');
    setInvoiceValue(0);
    setInvoicePaymentMethod('Cash');
  };

  const handleTopUpInvoice = (amount) => () => {
    dispatch(
      topUpFundsAsync({
        contractorId: contractorView.id,
        amount,
        description: invoiceDescription,
        paymentMethod: invoicePaymentMethod,
      }),
    );
    resetInvoiceState();
  };

  const disabled = isBusy || contractor == null;
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const size = isLargerThan800 ? 'md' : 'xs';

  return admin && contractor?.id !== contractorView?.id ? (
    <HStack mt={4}>
      <Box
        textAlign="center"
        borderWidth={1}
        px={4}
        borderRadius="xl"
        boxShadow="lg"
        minWidth={[350, 400, 500]}
        maxWidth={[400, 500, 500]}
      >
        <Box textAlign="left">
          <Heading color="gray" mt="3" size="sm">
            {t('pages.funds.Invoice', 'Invoice')}
          </Heading>
          <VStack>
            <Divider orientation="horizontal" />
          </VStack>
          <Stack mb={8} mt={4}>
            <HStack>
              <VStack alignItems="flex-start">
                <Text size="xs">{t('pages.funds.Amount', 'Amount')}</Text>
                <NumberInput
                  step={10}
                  min={0}
                  size="sm"
                  focusBorderColor="brand.500"
                  value={invoiceValue}
                  onChange={(valueString) => setInvoiceValue(valueString)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
              <VStack alignItems="flex-start">
                <Text size="xs">{t('pages.funds.PaymentMethod', 'Payment method')}</Text>
                <Select
                  focusBorderColor="brand.500"
                  size="sm"
                  type="type"
                  onChange={(event) => setInvoicePaymentMethod(event.target.value)}
                >
                  <option focusBorderColor="brand.500" value="Cash" selected={invoicePaymentMethod === 'Cash'}>
                    {t('pages.funds.Cash', 'Cash')}
                  </option>
                  <option value="Card" selected={invoicePaymentMethod === 'Card'}>
                    {t('pages.funds.Card', 'Card')}
                  </option>
                  <option value="P7" selected={invoicePaymentMethod === 'P7'}>
                    {t('pages.funds.P7', 'Transfer 7 days')}
                  </option>
                  <option value="P14" selected={invoicePaymentMethod === 'P14'}>
                    {t('pages.funds.P14', 'Transfer 14 days')}
                  </option>
                </Select>
              </VStack>
            </HStack>
            <Text size="md">{t('pages.funds.Description', 'Description')}</Text>
            <Input
              focusBorderColor="brand.500"
              size="sm"
              value={invoiceDescription}
              onChange={(event) => setInvoiceDescription(event.target.value)}
            />
            <Spacer />
            <Button
              width="full"
              colorScheme="brand"
              isLoading={isBusy}
              disabled={invoiceValue <= 0 || invoiceValue === '' || invoiceValue === '0'}
              onClick={handleTopUpInvoice(+invoiceValue)}
            >
              {t('pages.funds.Confirm', 'Confirm')}
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box
        textAlign="center"
        borderWidth={1}
        px={4}
        borderRadius="xl"
        boxShadow="lg"
        minWidth={[350, 400, 500]}
        maxWidth={[400, 500, 500]}
      >
        <Box textAlign="left">
          <Heading color="gray" mt="3" size="sm">
            {t('pages.funds.AccountFundsAdjustment', 'Account funds adjustment')}
          </Heading>
          <Divider orientation="horizontal" mb={4} />
          <Stack mb={8} mt={4}>
            <Text size="xs">{t('pages.funds.Amount', 'Amount')}</Text>
            <NumberInput
              value={adjustmentValue}
              step={10}
              size="sm"
              focusBorderColor="brand.500"
              onChange={(valueString) => setAdjustmentValue(valueString)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text size="md">{t('pages.funds.Description', 'Description')}</Text>
            <Input
              focusBorderColor="brand.500"
              size="sm"
              value={adjustmentDescription}
              onChange={(event) => setAdjustmentDescription(event.target.value)}
            />
            <Spacer />
            <Button
              width="full"
              colorScheme="brand"
              isLoading={isBusy}
              disabled={adjustmentValue === '0' || adjustmentValue === '' || adjustmentValue === 0}
              onClick={handleAdjustmentFunds(+adjustmentValue)}
            >
              {t('pages.funds.Confirm', 'Confirm')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </HStack>
  ) : (
    <Box>
      <RadioGroup mt={4} onChange={setPaymentType} value={paymentType}>
        <Stack alignItems="center">
          <Stack>
            <Radio isDisabled={waitingForPaymentSucceeded} colorScheme="brand" value={PAYMENT_METHOD.CARD}>
              {t('pages.funds.CreditCard', 'I want to pay by credit card')}
            </Radio>
            <Radio
              isDisabled={waitingForPaymentSucceeded}
              colorScheme="brand"
              value={PAYMENT_METHOD.P24} /* isDisabled */
            >
              {t('pages.funds.P24', 'I want to use the Przelewy24 service')}
              {/* <Text alignContent="flex-start" fontSize="xs">
                {t('pages.funds.P24Sorry', '(option temporarily unavailable)')}
              </Text> */}
            </Radio>
          </Stack>
        </Stack>
      </RadioGroup>
      <ButtonGroup colorScheme="brand" mt={4} size={size} isAttached>
        <Button disabled>{t('pages.funds.TopUpFor', 'Top up for')}</Button>
        <Button
          disabled={disabled || waitingForPaymentSucceeded}
          onClick={() => {
            setCustomValue(20);
          }}
        >
          20
        </Button>
        <Button
          disabled={disabled || waitingForPaymentSucceeded}
          onClick={() => {
            setCustomValue(50);
          }}
        >
          50
        </Button>
        <Button
          disabled={disabled || waitingForPaymentSucceeded}
          onClick={() => {
            setCustomValue(100);
          }}
        >
          100
        </Button>
        <Button
          disabled={disabled || waitingForPaymentSucceeded}
          onClick={() => {
            setCustomValue(200);
          }}
        >
          200
        </Button>
        <Button
          disabled={disabled || waitingForPaymentSucceeded}
          onClick={() => {
            setCustomValue(500);
          }}
        >
          500
        </Button>
        <Button disabled>PLN</Button>
      </ButtonGroup>
      <HStack mt={8}>
        <Tooltip label={t('pages.funds.TopUpAmountFull', 'Amount [PLN]')}>
          <VStack alignItems="flex-start">
            <Text textAlign="left" size="md">
              {t('pages.funds.TopUpAmount', 'Amount [PLN]')}
            </Text>
            <NumberInput
              disabled={waitingForPaymentSucceeded}
              focusBorderColor="brand.500"
              allowMouseWheel
              defaultValue={10}
              width={125}
              value={customValue}
              max={10000}
              min={10}
              size="sm"
              clampValueOnBlur
              keepWithinRange
              onChange={(valueString) => setCustomValue(valueString)}
            >
              <NumberInputField disabled={waitingForPaymentSucceeded} sx={{ borderRadius: 0, textAlign: 'right' }} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </VStack>
        </Tooltip>
        <Tooltip label={t('pages.funds.AdditionalInvoiceDescription', 'Additional description of invoice')}>
          <VStack alignItems="flex-start">
            <Text textAlign="left" size="md">
              {t('pages.funds.Description', 'Description')}
            </Text>
            <Input
              disabled={waitingForPaymentSucceeded}
              focusBorderColor="brand.500"
              size="sm"
              value={adjustmentDescription}
              onChange={(event) => setAdjustmentDescription(event.target.value)}
              width={365}
            />
          </VStack>
        </Tooltip>
      </HStack>
      <Button
        mt={4}
        width="full"
        colorScheme="brand"
        isLoading={isBusy}
        disabled={customValue === '0' || customValue === '' || customValue === 0 || waitingForPaymentSucceeded}
        onClick={handleTopUp(+customValue, paymentType)}
      >
        {t('pages.funds.AcceptAndPay', 'Accept and pay')}
      </Button>
    </Box>
  );
};

const Funds = () => {
  const contractorView = useSelector(selectContractorView);
  const contractor = useSelector(selectContractor);
  const waitingForPaymentSucceeded = useSelector(selectStripeWaitingForPaymentSucceeded);
  const funds = `${(contractorView?.funds ?? 0).toFixed(2)} PLN`;
  const { t } = useTranslation();
  return (
    <Flex grow="1" m={4} direction="column" justifyItems="center">
      <Box
        textAlign="center"
        borderWidth={1}
        px={4}
        borderRadius="xl"
        boxShadow="lg"
        minWidth={[350, 400, 500]}
        maxWidth={[400, 500, 500]}
      >
        <Box textAlign="left">
          <Heading color="gray" mt="3" size="sm">
            {t('pages.funds.Available funds', 'Available funds')}
          </Heading>
        </Box>
        <Heading mt="2" mb={waitingForPaymentSucceeded ? 2 : 10}>
          {funds}
        </Heading>
        {waitingForPaymentSucceeded === true && (
          <Spinner mb="10" thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.500" size="xl" />
        )}
      </Box>
      <Box
        textAlign="center"
        alignContent="center"
        alignItems="center"
        minWidth={[350, 400, 500]}
        maxWidth={[400, 500, 500]}
      >
        {contractor != null && <TopUpPanel />}
        {contractor == null && (
          <Alert mt={4} status="error">
            <AlertIcon />
            <AlertDescription>
              {t(
                'pages.funds.NoContractor',
                'The account has not been linked with the contractor. Topping up is not possible. Fill data on Contractor tab and try again',
              )}
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Flex>
  );
};

export default Funds;
