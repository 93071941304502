/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  useBoolean,
  Button,
  Divider,
  HStack,
  VStack,
  Spacer,
  Heading,
  Text,
  Input,
  Checkbox,
  CheckboxGroup,
  Stack,
  Tooltip,
  Select,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { includesSome } from '../utils';
import { selectAccountRoles } from '../features/session';
import {
  loadData,
  selectSummary,
  selectAllRowsCount,
  openPaymentsReportAsync,
  selectIsPrinting,
  selectIsExporting,
  saveAsCsvAsync,
} from '../features/payments';
import { selectContractor, selectContractorView } from '../features/account';
import { selectIsBusy } from '../features/app';

const dateFormatMask = 'yyyy-mm-dd';

const PaymentsFilterBar = ({
  additionalGroupBy1,
  setAdditionalGroupBy1,
  additionalGroupBy2,
  setAdditionalGroupBy2,
  filter,
  setFilter,
  groupBy,
  setGroupBy,
  contractorId,
  admin,
}) => {
  const dispatch = useDispatch();
  const isBusy = useSelector(selectIsBusy);
  const isPrinting = useSelector(selectIsPrinting);
  const isExporting = useSelector(selectIsExporting);
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const handleClearFilters = () => {
    setUseFromDate.off();
    setUseToDate.off();
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setFilter([]);
  };

  const handleApplyFilters = () => {
    const newFilter = [];

    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    if (useFromDate || useToDate) {
      newFilter.push({
        name: 'createdAt',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    setFilter(newFilter);
  };
  const handleGroupBy = (value) => {
    setGroupBy(JSON.stringify(value));
  };
  const handlePrintClick = () => {
    dispatch(openPaymentsReportAsync);
  };
  const handleExportClick = () => {
    dispatch(saveAsCsvAsync);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Button
        isLoading={isPrinting}
        disabled={isBusy || isExporting}
        minWidth={282}
        colorScheme="brand"
        onClick={handlePrintClick}
      >
        {t('modules.payments.Print', 'Print')}
      </Button>
      <Button
        isLoading={isExporting}
        disabled={isBusy || isPrinting}
        minWidth={282}
        colorScheme="brand"
        onClick={handleExportClick}
      >
        {t('modules.payments.CSV', 'Export to CSV')}
      </Button>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.payments.GroupBy', 'Group by')}
      </Heading>
      <Divider orientation="horizontal" />
      <CheckboxGroup disabled={isBusy} colorScheme="brand" defaultValue={[]} onChange={handleGroupBy}>
        <Stack spacing={[1, 5]} direction="row">
          <Checkbox value="months">{t('modules.payments.Months', 'Months')}</Checkbox>
          <Checkbox value="weeks">{t('modules.payments.Weeks', 'Weeks')}</Checkbox>
          <Checkbox value="days">{t('modules.payments.Days', 'Days')}</Checkbox>
        </Stack>
      </CheckboxGroup>
      <Stack direction="column">
        <Select
          minWidth={282}
          focusBorderColor="brand.500"
          size="xs"
          type="type"
          onChange={(event) => {
            setAdditionalGroupBy1(event.target.value);
          }}
        >
          <option focusBorderColor="brand.500" value="" selected={additionalGroupBy1 === ''}>
            {t('modules.payments.None', '-')}
          </option>
          <option focusBorderColor="brand.500" value="devices" selected={additionalGroupBy1 === 'devices'}>
            {t('modules.payments.Devices', 'Devices')}
          </option>
          <option focusBorderColor="brand.500" value="payments" selected={additionalGroupBy1 === 'payments'}>
            {t('modules.payments.Payments', 'Payments kinds')}
          </option>
        </Select>
        <Select
          minWidth={196}
          focusBorderColor="brand.500"
          size="xs"
          type="type"
          onChange={(event) => {
            setAdditionalGroupBy2(event.target.value);
          }}
        >
          <option focusBorderColor="brand.500" value="" selected={additionalGroupBy2 === ''}>
            {t('modules.payments.None', '-')}
          </option>
          <option focusBorderColor="brand.500" value="devices" selected={additionalGroupBy2 === 'devices'}>
            {t('modules.payments.Devices', 'Devices')}
          </option>
          <option focusBorderColor="brand.500" value="payments" selected={additionalGroupBy2 === 'payments'}>
            {t('modules.payments.Payments', 'Payments kinds')}
          </option>
        </Select>
      </Stack>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.payments.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
        {t('modules.invoices.FromDate', 'From date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        disabled={!useFromDate}
        minWidth={196}
        type="date"
        size="xs"
        value={dateFrom}
        onChange={(event) => setDateFrom(event.currentTarget.value)}
      />
      <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
        {t('modules.invoices.ToDate', 'To date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        minWidth={196}
        disabled={!useToDate}
        size="xs"
        type="date"
        value={dateTo}
        onChange={(event) => setDateTo(event.currentTarget.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button disabled={isBusy} colorScheme="brand" minWidth={282} onClick={handleApplyFilters}>
        {t('modules.payments.ApplyFilters', 'Apply filters')}
      </Button>
      <Button disabled={isBusy} colorScheme="gray" minWidth={282} onClick={handleClearFilters}>
        {t('modules.payments.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.payments.Index', 'No'),
    textAlign: 'end',
    maxWidth: 75,
    sortable: false,
  },
  {
    name: 'month',
    header: i18n.t('modules.payments.Month', 'Month'),
    sortable: false,
    maxWidth: 100,
  },
  {
    name: 'week',
    type: 'number',
    header: i18n.t('modules.payments.Week', 'Week'),
    textAlign: 'end',
    sortable: false,
    maxWidth: 100,
  },
  {
    name: 'textWeekDates',
    header: i18n.t('modules.payments.TextWeekDates', 'From To'),
    sortable: false,
    minWidth: 200,
  },
  {
    name: 'textDate',
    header: i18n.t('modules.payments.Date', 'Date'),
    sortable: false,
    maxWidth: 100,
  },
  {
    name: 'deviceId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.payments.DeviceId', 'Device Id'),
    sortable: false,
  },
  {
    name: 'deviceName',
    defaultFlex: 1,
    header: i18n.t('modules.payments.DeviceName', 'Device'),
    sortable: false,
  },
  {
    name: 'transactionTypeId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.payments.TransactionTypeId', 'Transaction type Id'),
    sortable: false,
  },
  {
    name: 'transactionTypeName',
    defaultFlex: 1,
    header: i18n.t('modules.payments.TransactionTypeName', 'Payment type'),
    sortable: false,
  },
  {
    name: 'sum',
    type: 'number',
    header: i18n.t('modules.payments.Amount', 'Kwota [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'sumTokens',
    type: 'number',
    header: i18n.t('modules.payments.AmountTokens', 'Kwota [Żetony S,L]'),
    textAlign: 'end',
    sortable: false,
  },
  {
    defaultFlex: 1,
    sortable: false,
  },
];
const PaymentsSummary = () => {
  const { t } = useTranslation();
  const summary = useSelector(selectSummary);
  const count = useSelector(selectAllRowsCount);
  const { sumsValue: sumsSummary, sumTsValue: sumTsSummary } = summary;
  return (
    <VStack>
      <HStack alignSelf="stretch">
        <Spacer />
        <Tooltip
          label={`${t('modules.payments.SummaryInfoPrefix', 'Summary contains sum of all')} ${count} ${t(
            'modules.payments.SummaryInfoPostfix',
            'rows',
          )}`}
        >
          <HStack alignSelf="stretch" spacing="8">
            <Heading size="md">{t('modules.payments.Summary', 'Summary:')}</Heading>
            <HStack>
              <Heading size="md">{(sumsSummary ?? 0).toFixed(2)}</Heading>
              <Heading size="md">PLN</Heading>
            </HStack>
            <HStack>
              <Heading size="md">{(sumTsSummary ?? 0).toFixed(2)}</Heading>
              <Heading size="md">Żetony S,L</Heading>
            </HStack>
          </HStack>
        </Tooltip>
      </HStack>
    </VStack>
  );
};
// eslint-disable-next-line react/prop-types
const Payments = ({ height, upAdmin }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [groupBy, setGroupBy] = useState([]);
  const [additionalGroupBy1, setAdditionalGroupBy1] = useState('');
  const [additionalGroupBy2, setAdditionalGroupBy2] = useState('');
  const accountRoles = useSelector(selectAccountRoles);
  const dataSource = useCallback(loadData(dispatch)({ filter, groupBy, additionalGroupBy1, additionalGroupBy2 }), [
    filter,
    groupBy,
    additionalGroupBy1,
    additionalGroupBy2,
    dispatch,
  ]);
  const admin = includesSome(['admin', 'backoffice'], accountRoles);

  return (
    <VStack alignItems="stretch">
      <HStack alignItems="flex-start">
        <PaymentsFilterBar
          admin={admin}
          filter={filter}
          setFilter={setFilter}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          additionalGroupBy1={additionalGroupBy1}
          additionalGroupBy2={additionalGroupBy2}
          setAdditionalGroupBy1={setAdditionalGroupBy1}
          setAdditionalGroupBy2={setAdditionalGroupBy2}
        />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          pagination="remote"
          skip={0}
          limit={50}
          allowUnsort={false}
          idProperty="index"
        />
      </HStack>
      <PaymentsSummary />
    </VStack>
  );
};
export default Payments;
