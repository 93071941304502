const translations = {
  Index: 'L.p.',
  TransactionId: 'Id',
  RFIDTag: 'Id użytkownika',
  InvoiceNo: 'Nr faktury',
  OperationDate: 'Data',
  OperationType: 'Rodzaj',
  Source: 'Źródło',
  ContractorId: 'Id kontrahenta',
  Contractor: 'Kontrahent',
  TypeDescription: 'Rodzaj',
  Properties: 'Właściwości',
};

export default translations;
