import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { Box } from '@chakra-ui/react';
import { useRef, useEffect, useState, Children, isValidElement, cloneElement } from 'react';

const debounce = (fn, ms) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this);
    }, ms);
  };
};

const StretchBox = ({ yOffset = 0, children }) => {
  const [cookies] = useCookies(['cookie-consent-allowed']);
  const cookieConsent = cookies['cookie-consent-allowed'];
  const div = useRef();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 10);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });
  const calculatedHeight = `${dimensions.height - (+yOffset + (cookieConsent == null ? 56 : 0))}px`;
  const newChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      const result = cloneElement(child, { height: calculatedHeight });
      return result;
    }

    return child;
  });
  return (
    <Box w="100%" ref={div} h={calculatedHeight}>
      {newChildren}
    </Box>
  );
};

StretchBox.propTypes = {
  yOffset: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
export default StretchBox;
