/* eslint-disable react/prop-types */
import { Flex, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectContractor } from '../../features/account';
import ContractorForm from '../../modules/contractor-form';

const formId = 'b7d56eeb-53ca-4dd4-8f3c-f69cf9fb6b4c';

const ContractorEdit = () => {
  const contractor = useSelector(selectContractor);
  return (
    <Flex grow="1" alignItems="flex-start" direction="column">
      <Box minWidth={[350, 400, 500]} maxWidth={[400, 500, 500]}>
        <Box p={4}>
          <ContractorForm contractor={contractor} gdprConsent={true} formId={formId} />
        </Box>
      </Box>
    </Flex>
  );
};

export default ContractorEdit;
