import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@chakra-ui/react';

const LangSwitcher = ({ size }) => {
  const { i18n } = useTranslation();
  const en = i18n.language.startsWith('en');
  const pl = i18n.language.startsWith('pl');
  const handleClickEN = () => {
    i18n.changeLanguage('en');
  };
  const handleClickPL = () => {
    i18n.changeLanguage('pl');
  };
  return (
    <>
      {pl && (
        <Tooltip label={i18n.t('components.ui.lang-switcher.en-tooltip', 'switch to english')}>
          <Button variant="ghost" size={size} onClick={handleClickEN}>
            EN
          </Button>
        </Tooltip>
      )}
      {en && (
        <Tooltip label={i18n.t('components.ui.lang-switcher.pl-tooltip', 'switch to polish')}>
          <Button variant="ghost" size={size} onClick={handleClickPL}>
            PL
          </Button>
        </Tooltip>
      )}
    </>
  );
};

LangSwitcher.defaultProps = {
  size: 'xs',
};

LangSwitcher.propTypes = {
  size: PropTypes.string,
};

export default LangSwitcher;
