import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';
import env from '../../environment';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInOperationsSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'operations',
  initialState: {},
});

const loadData =
  (dispatch) =>
  ({ contractorId, filter: filterValue }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        const grouping = env.GROUP_CONTRACTOR_OPERATIONS;
        dispatch(setIsBusy(true));
        const response = await apiClient.get(`/contractors/${contractorId}/operations`, {
          params: { limit, offset: skip, sortInfo, filterValue: { filterValue }, grouping },
        });

        const { count } = response.data.operations;
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.operations.rows.map((operation) => ({
            ...operation,
            value: operation.value.toFixed(2),
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

export { registerGetStateInOperationsSlice, slice, loadData };

export default slice.reducer;
