import { Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAccountName } from '../../features/session';
import Account from './account';
import Contractor from './contractor';

const Layout = () => {
  const { t } = useTranslation();
  const accountName = useSelector(selectAccountName);
  return (
    <Flex p="0" grow="1" alignItems="stretch" direction="column" justifyContent="stretch">
      <Heading mb="4">{`${accountName}: ${t('pages.account.profileManagement', 'profile management')}`}</Heading>
      <Tabs grow="1" isLazy variant="enclosed" colorScheme="brand">
        <TabList>
          <Tab>{t('pages.account.Contractor', 'Contractor')}</Tab>
          <Tab>{t('pages.account.Account', 'Account')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Contractor />
          </TabPanel>
          <TabPanel>
            <Account />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default Layout;
