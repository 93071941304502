import { Flex, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Invoices from '../../modules/invoices';
import StretchBox from '../../core/components/stretch-box';
import Transactions from '../../modules/transactions';
import RfidCards from '../../modules/rfid-cards';
import Report from '../../modules/report';
import SppLayout from './spp-layout';
import UserAccounts from '../../modules/user-accounts';
import Devices from '../../modules/devices';
import Payments from '../../modules/payments';

const AdminPanel = () => {
  const { t } = useTranslation();
  return (
    <Flex p="0" grow="1" alignItems="stretch" direction="column" justifyContent="stretch">
      <Tabs isLazy variant="enclosed" colorScheme="brand">
        <TabList>
          <Tab>{t('pages.adminPanel.layout.Transactions', 'Transactions')}</Tab>
          <Tab>{t('pages.adminPanel.layout.RFIDCards', 'Users')}</Tab>
          <Tab>{t('pages.adminPanel.layout.Invoices', 'Invoices')}</Tab>
          <Tab>{t('pages.adminPanel.layout.Report', 'Report')}</Tab>
          <Tab>{t('pages.adminPanel.layout.Payments', 'Payments')}</Tab>
          <Tab>{t('pages.adminPanel.layout.Spp', 'SPP')}</Tab>
          <Tab>{t('pages.adminPanel.layout.Devices', 'Devices')}</Tab>
          <Tab>{t('pages.adminPanel.layout.UserAccounts', 'UserAccounts')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <StretchBox yOffset={193}>
              <Transactions upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <RfidCards upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <Invoices upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <Report upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <Payments upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <SppLayout upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <Devices upAdmin={true} />
            </StretchBox>
          </TabPanel>
          <TabPanel>
            <StretchBox yOffset={193}>
              <UserAccounts upAdmin={true} />
            </StretchBox>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
export default AdminPanel;
