import { Alert, Text, AlertTitle, AlertDescription, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Flex grow="1" alignItems="center" direction="column" justifyContent="center">
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
        colorScheme="brand"
        background="transparent"
      >
        <Text fontWeight="bold" fontSize="4xl">
          404
        </Text>
        <AlertTitle mb="2" fontSize="lg">
          {t('We are sorry, page not found!', 'We are sorry, page not found!')}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {t(
            'The page you are looking for might have been removed had its name changed or is temporarily unavailable',
            'The page you are looking for might have been removed had its name changed or is temporarily unavailable',
          )}
        </AlertDescription>
      </Alert>
    </Flex>
  );
};

export default NotFound;
