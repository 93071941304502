const translations = {
  Index: 'L.p.',
  FromDate: 'Od daty',
  ToDate: 'Do daty',
  Refresh: 'Odśwież',
  RefreshBy: 'Odśwież wg zadanego zakresu dat',
  OrderBy: 'Sortuj według',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Date: 'Data',
  DateTime: 'Data czas',
  Amount: 'Kwota',
  TransactionType: 'Typ transakcji',
  PaymentType: 'Forma płatności',
};

export default translations;
