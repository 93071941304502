import keyMirror from 'keymirror';
import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { showToastWithApiClientErrorInfo } from './toast';
import { showLovAsync, LOV_SIZE } from './lov';
import i18n from '../../translations/i18n';
import { MESSAGE_DIALOG_BUTTONS, MESSAGE_DIALOG_TYPE, showMessageDialogAsync } from './message-dialog';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInRfidCardsSlice = (storeGetState) => {
  getState = storeGetState;
};

const RFID_CARD_CRUD_MODE = keyMirror({
  NONE: null,
  ADD: null,
  EDIT: null,
  DELETE: null,
});

const validateCrudItem = (crudItem) => {
  if (crudItem == null) {
    return null;
  }

  const toValidate = crudItem;

  if (toValidate.rfidTag?.value == null || toValidate.rfidTag?.value === '') {
    toValidate.rfidTag.error = 'Value in this field is required';
  }

  if (toValidate.contractorId?.value == null || toValidate.contractorId?.value === '') {
    toValidate.contractorId.error = 'Value in this field is required';
  }

  return toValidate;
};

const slice = createSlice({
  name: 'rfidCards',
  initialState: {
    selectedId: null,
    crudMode: RFID_CARD_CRUD_MODE.NONE,
    crudItem: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      const stateRef = state;
      stateRef.selectedId = action.payload;
    },
    setSelectedData: (state, action) => {
      const stateRef = state;
      stateRef.selectedData = action.payload;
    },
    setCrudMode: (state, action) => {
      const stateRef = state;
      stateRef.crudMode = action.payload;
    },
    setCrudItem: (state, action) => {
      const stateRef = state;
      stateRef.crudItem = action.payload;
    },
  },
});

const { setSelectedId, setSelectedData, setCrudItem, setCrudMode } = slice.actions;

const selectSelectedId = (state) => state.rfidCards.selectedId;
const selectSelectedData = (state) => state.rfidCards.selectedData;
const selectCrudMode = (state) => state.rfidCards.crudMode;
const selectCrudItem = (state) => state.rfidCards.crudItem;

const loadData =
  (dispatch) =>
  ({ contractorId, filter: filterValue }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));
        dispatch(setSelectedId(null));

        const response = await apiClient.get(
          contractorId == null ? '/rfid-cards' : `/contractors/${contractorId}/rfid-cards`,
          {
            params: { limit, offset: skip, sortInfo, filterValue: { filterValue } },
          },
        );

        const { count } = response.data.rfidCards;
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.rfidCards.rows.map((rfidCard, index) => ({
            ...rfidCard,
            index: index + 1,
            weeklyLimit: rfidCard.weeklyLimit.toFixed(2),
            weeklyLimitUsage: rfidCard.weeklyLimitUsage.toFixed(2),
            monthlyLimit: rfidCard.monthlyLimit == null ? '-' : rfidCard.monthlyLimit.toFixed(2),
            monthlyLimitLeft: rfidCard.monthlyLimitLeft == null ? '-' : rfidCard.monthlyLimitLeft.toFixed(2),
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

const flatCrudItem = (crudItem) => ({
  id: crudItem.id.value,
  rfidTag: crudItem.rfidTag.value,
  rfidDesc: crudItem.rfidDesc.value,
  rfidComment: crudItem.rfidComment.value,
  weeklyLimit: crudItem.weeklyLimit.value,
  monthlyLimit: crudItem.monthlyLimit.value === '-' ? 0 : crudItem.monthlyLimit.value,
  contractorId: crudItem.contractorId.value,
  contractorName: crudItem.contractorName.value,
  locked: crudItem.locked.value,
  accessModeId: crudItem.accessModeId.value,
});

const applyCrudChanges =
  ({ crudItem, crudMode, reload, setReload }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const item = flatCrudItem(crudItem);
      // eslint-disable-next-line default-case
      switch (crudMode) {
        case RFID_CARD_CRUD_MODE.ADD:
          await apiClient.post('/rfid-cards', item);
          if (setReload != null) {
            setReload(reload + 1);
          }
          break;
        case RFID_CARD_CRUD_MODE.EDIT:
          await apiClient.patch(`/rfid-cards/${item.id}`, item);
          if (setReload != null) {
            setReload(reload + 1);
          }
          break;
        case RFID_CARD_CRUD_MODE.DELETE:
          dispatch(
            showMessageDialogAsync({
              type: MESSAGE_DIALOG_TYPE.CONFIRMATION,
              buttons: MESSAGE_DIALOG_BUTTONS.YES_NO,
              text: i18n.t('modules.rfidCards.RfidCardDeleteConfirm', 'Selected user will be deleted. To continue?'),
              actions: {
                yes: async () => {
                  try {
                    await apiClient.delete(`/rfid-cards/${item.id}`);
                  } catch (error) {
                    showToastWithApiClientErrorInfo({ title: 'Apply crud operation', error });
                  }

                  if (setReload != null) {
                    setReload(reload + 1);
                  }
                },
              },
            }),
          );
          break;
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Apply crud operation', error });
    }
    dispatch(setCrudMode(RFID_CARD_CRUD_MODE.NONE));
    dispatch(setCrudItem());
    dispatch(setIsBusy(false));
  };

const selectContractorFromDatabaseAsync =
  ({ callback }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get('/contractors', {
        params: { limit: null, offset: 0, sortInfo: null, filterValue: { filterValue: [] } },
      });
      let lovContractorsData = [];
      if (response.status === 200) {
        lovContractorsData = response.data.contractors.rows.map((contractor) => {
          const result = {
            id: contractor.id,
            name: contractor.name,
            address1: contractor.address1,
            address2: contractor.address2,
            vatin: contractor.vatin,
          };

          return result;
        });

        dispatch(
          showLovAsync({
            multiSelect: false,
            title: i18n.t('features.rfidCards.Contractors.lovTitle', 'Contractors'),
            size: LOV_SIZE.XL4,
            actions: {
              ok: (selectedIds) => () => {
                Object.entries(selectedIds).forEach((entry) => {
                  if (entry[1] === true) {
                    if (callback != null) {
                      setImmediate(() => {
                        callback(lovContractorsData[entry[0]]);
                      });
                    }
                  }
                });
              },
            },
            data: lovContractorsData,
            columns: [
              {
                Header: i18n.t('features.rfidCards.Contractors.Name', 'Name'),
                accessor: 'name',
                width: 300,
              },
              {
                Header: i18n.t('features.rfidCards.Contractors.Address1', 'Address1'),
                accessor: 'address1',
                width: 200,
              },
              {
                Header: i18n.t('features.rfidCards.Contractors.Address2', 'Address2'),
                accessor: 'address2',
                width: 200,
              },
              {
                Header: i18n.t('features.rfidCards.Contractors.Vatin', 'Vatin'),
                accessor: 'vatin',
                width: 100,
              },
            ],
          }),
        );
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Fetch contractor from GUS error', error });
    }

    dispatch(setIsBusy(false));
  };

const checkRfidExistsByRfidTag = async (rfidTag) => {
  try {
    const response = await apiClient.get(`/rfid-cards/rfid-tag/${rfidTag}`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response?.status === 404) {
      return false;
    }

    throw error;
  }

  return false;
};
export {
  registerGetStateInRfidCardsSlice,
  slice,
  setSelectedId,
  selectSelectedId,
  loadData,
  selectCrudItem,
  selectCrudMode,
  setCrudItem,
  setCrudMode,
  RFID_CARD_CRUD_MODE,
  validateCrudItem,
  applyCrudChanges,
  selectContractorFromDatabaseAsync,
  checkRfidExistsByRfidTag,
  setSelectedData,
  selectSelectedData,
};

export default slice.reducer;
