import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Flex, HStack, Link, Spacer, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [serverInfo, setServerInfo] = useState({});

  const getServerInfo = async () => {
    const promise = await axios.get('/api');
    const { status } = promise;
    if (status === 200) {
      const { data } = promise;
      setServerInfo(data);
    }
  };
  useEffect(async () => {
    await getServerInfo();
  }, []);

  const { t } = useTranslation();
  return (
    <Flex
      background="brand.500"
      minHeight="32px"
      direction="row"
      justifyContent="center"
      alignItems="center"
      color="white"
    >
      <Spacer />
      <Flex
        background="brand.500"
        minHeight="32px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <VStack>
          <HStack>
            <Text fontSize="small">IMEX RZESZÓW SP. Z O.O.</Text>
            <Text fontSize="small">|</Text>
            <Text fontSize="small">ul. Przemysłowa 18</Text>
            <Text fontSize="small">|</Text>
            <Text fontSize="small">35-105 Rzeszów</Text>
            <Text fontSize="small">|</Text>
            <Text fontSize="small">e-mail: rzeszow.imex@gmail.com</Text>
            <Text fontSize="small">|</Text>
            <Text fontSize="small">tel.: +48 606-303-117</Text>
            <Text fontSize="small">|</Text>
            <Link as={RouterLink} to="/regulations">
              <Text fontSize="small">{t('pages.regulations.RegulationsFooter', 'Regulations')}</Text>
            </Link>
            <Text fontSize="small">|</Text>
            <Link as={RouterLink} to="/privacy-policy">
              <Text fontSize="small">{t('pages.privacyPolicy.PrivacyPolicy', 'Privacy Policy')}</Text>
            </Link>
          </HStack>
        </VStack>
      </Flex>
      <Spacer />
      <Flex
        background="brand.500"
        minHeight="32px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <HStack mr={1}>
          <Text color="red.400" fontSize="small">
            {`v.${serverInfo.moduleVersion}`}
          </Text>
        </HStack>
      </Flex>
    </Flex>
  );
};
export default Footer;
