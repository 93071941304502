const translations = {
  'Available funds': 'Dostępne środki',
  Invoice: 'Faktura',
  AccountTopUp: 'Doładowanie konta',
  TopUpFor: 'Doładuj za:',
  AccountFundsAdjustment: 'Korekta/Bonus',
  NoContractor:
    'Konto nie zostało powiązane z kontrahentem. Doładowanie nie jest możliwe. Uzupełnij dane na zakładce "Kontrahent" i spróbuj ponownie',
  CreditCard: 'Chcę zapłacić kartą kredytową',
  P24: 'Chcę skorzystać z serwisu Przelewy24',
  P24Sorry: '(opcja chwilowo niedostępna PRZEPRASZAMY)',
  EnteredCustomValue: 'Podana wartość',
  Amount: 'Kwota',
  TopUpAmount: 'Kwota [PLN]',
  TopUpAmountFull: 'Kwota doładowania [PLN]',
  AcceptAndPay: 'Akceptuję i płacę',
  Description: 'Opis',
  AdditionalInvoiceDescription: 'Dodatkowy opis faktury',
  Confirm: 'Zatwierdź',
  PaymentMethod: 'Forma płatności',
  Cash: 'Gotówka',
  Card: 'Karta kredytowa',
  P7: 'Przelew 7 dni',
  P14: 'Przelew 14 dni',
};

export default translations;
