import { FaArrowLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Flex, Heading, Box, Center, ListItem, UnorderedList, HStack, Button, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StretchBox from '../core/components/stretch-box';
import { selectIsSignedIn } from '../features/session';

const ParagraphNo = ({ number }) => (
  <Center m={4}>
    <Heading size="md">{`§ ${number}.`}</Heading>
  </Center>
);

ParagraphNo.propTypes = {
  number: PropTypes.number.isRequired,
};

const ParagraphHeader = ({ text }) => (
  <Center m={4}>
    <Heading size="md">{(text ?? '').toUpperCase()}</Heading>
  </Center>
);
const ParagraphContent = ({ text }) => (
  <Center m={4}>
    <Heading size="sm">{(text ?? '').toUpperCase()}</Heading>
  </Center>
);

const UList = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, mt } = props;
  return <UnorderedList mt={mt}>{children}</UnorderedList>;
};

const LItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, mt } = props;
  return <ListItem mt={mt}>{children}</ListItem>;
};

ParagraphHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

ParagraphContent.propTypes = {
  text: PropTypes.string.isRequired,
};
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isSignedIn = useSelector(selectIsSignedIn);
  return (
    <Flex grow="1" direction="column" justifyContent="stretch" p="4">
      <Button
        mb={4}
        colorScheme="brand"
        variant="outline"
        display={isSignedIn ? 'none' : 'block'}
        onClick={() => {
          history.push('/');
        }}
      >
        <HStack>
          <Icon as={FaArrowLeft} />
          <Heading size="md">{t('pages.privacyPolicy.BackToApplication', 'Go back to application')}</Heading>
        </HStack>
      </Button>
      <Heading mb={4}>{t('pages.privacyPolicy.PrivacyPolicy', 'Privacy Policy')}</Heading>
      <StretchBox yOffset={isSignedIn ? 195 : 250}>
        <Box p={4} sx={{ 'overflow-y': 'scroll' }}>
          <ParagraphHeader text="Informacja o przetwarzaniu i ochronie danych osobowych" />
          <>
            Administratorem Danych Osobowych w rozumieniu Art. 4 Rozporządzenia Parlamentu Europejskiego i Rady (UE)
            2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: „RODO”)
            jest IMEX RZESZÓW SP. Z O.O. z siedzibą w Rzeszowie ul. Przemysłowa 18 35-301 Rzeszów, Polska, NIP 8133913896
            (dalej jako „Imex”).
          </>
          <UList mt={4}>
            <LItem>
              Imex będzie przetwarzał dane osobowe w następujących celach oraz na następujących podstawach prawnych :
            </LItem>
            <UList>
              <LItem>
                dane osobowe Użytkownika tj. osoby fizycznej prowadzącej działalność gospodarczą na podstawie art. 6
                ust. 1 lit.
              </LItem>
              <LItem>
                dane osobowe Użytkownika, tj. osoby fizycznej prowadzącej działalność gospodarczą na podstawie art. 6
                ust. 1 lit. c) RODO, w celu spełniania wymogów wynikających z przepisów prawa (w tym podatkowego i
                rachunkowości).
              </LItem>
              <LItem>
                dane osobowe użytkowników Konta Użytkownika, na podstawie art. 6 ust. 1 lit. f) RODO, tj. na podstawie
                uzasadnionego interesu „Imex”.
              </LItem>
              <LItem>
                dane osobowe osób reprezentujących Użytkownika na podstawie art. 6 ust. 1 lit. f) RODO, w celu
                zapewnienia stałego kontaktu
              </LItem>
              <LItem>
                Możemy przetwarzać Twoje dane osobowe również na podstawie art. 6 ust. 1lit. f) ogólnego rozporządzenia
                o ochronie danych osobowych z dnia 27 kwietnia 2016 r. w celu:
              </LItem>
              <UList>
                <LItem>
                  zapewnienia pełnej obsługi, w tym rozwiązywania problemów technicznych i udostępniania odpowiednich
                  funkcji,
                </LItem>
                <LItem>
                  kontaktowania się, w szczególności w celach związanych ze świadczeniem usług, obsługą, dozwolonymi
                  działaniami marketingowymi i reklamowymi,
                </LItem>
                <LItem>przeprowadzania badań i analiz w celu poprawy działania dostępnych usług,</LItem>
              </UList>
            </UList>
            <LItem mt={4}>Dane Osobowe mogą być przekazane następującym odbiorcom:</LItem>
            <UList>
              <LItem>
                osobom upoważnionym przez Imex, pracownikom i współpracownikom, którzy muszą mieć dostęp do danych, aby
                wykonywać swoje obowiązki,
              </LItem>
              <LItem>
                podmiotom przetwarzającym, którym Imex zlecił to zadanie, m.in. firmom zajmującym się obsługą systemów
                informatycznych, firmom świadczącym usługi najmu przestrzeni serwerowej,
              </LItem>
              <LItem>podwykonawcom Imex, w tym firmom świadczącym usługi windykacyjne lub księgowe,</LItem>
              <LItem>Instytucjom Pośredniczącym na potrzeby realizacji Usług</LItem>
            </UList>
            <LItem mt={4}>
              Okres przetwarzania danych zależy od celu przetwarzania. W przypadku gdy podstawą do przetwarzania jest
              zawarcie i realizacja umowy, dane będą przetwarzane do czasu rozwiązania usunięcia konta użytkownika. W
              przypadku gdy podstawą do przetwarzania danych jest uzasadniony interes administratora, dane przetwarzane
              będą przez okres umożliwiający realizację tego interesu lub do zgłoszenia sprzeciwu, który zostanie uznany
              przez Imex. Dane osobowe, które przetwarzamy w oparciu o Twoją zgodę, jesteśmy uprawnieni przetwarzać́ do
              momentu odwołania wyrażonej przez Ciebie zgody. Okres przetwarzania może zostać wydłużony jeżeli podstawą
              do przetwarzania jest dochodzenie oraz obrona przed roszczeniami oraz jeżeli przetwarzanie jest niezbędne
              w celu wypełnienia obowiązku prawnego ciążącego na Administratorze np. cele archiwizacyjne, związane z
              wypełnieniem obowiązków związanych z płaceniem podatków i prowadzeniem ksiąg podatkowych.
            </LItem>
            <LItem mt={4}>W związku z przetwarzaniem przez Imex danych osobowych przysługują prawa:</LItem>
            <UList>
              <LItem>
                dostępu do treści swoich danych oraz ich sprostowania, usunięcia lub ograniczenia przetwarzania.
              </LItem>
              <LItem>
                żądania usunięcia danych osobowych z wyjątkiem, gdy ich przetwarzanie jest niezbędne do wywiązania się z
                prawnego obowiązku ciążącego na Imex.
              </LItem>
              <LItem>
                wniesienia skargi do organu nadzorczego (od 25 maja 2018 r. Prezesa Urzędu Ochrony Danych), gdy
                przetwarzanie danych osobowych narusza przepisy RODO.
              </LItem>
            </UList>
            <LItem mt={4}>Kontakt z Administratorem:</LItem>
            <UList>
              <LItem>email: biuro@speedwasch.pl</LItem>
              <LItem>telefonicznie pod numerem +48 606-303-117</LItem>
              <LItem>
                lub listownie pod adresem IMEX RZESZÓW SP. Z O.O. z siedzibą w Rzeszowie ul. Przemysłowa 18 35-301 Rzeszów,
                Polska.
              </LItem>
            </UList>
          </UList>
        </Box>
      </StretchBox>
    </Flex>
  );
};

export default PrivacyPolicy;
