import { useCookies } from 'react-cookie';
import { Alert, AlertIcon, AlertDescription, CloseButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookie-consent-allowed']);
  const cookieConsent = cookies['cookie-consent-allowed'];
  const { t } = useTranslation();
  const handleClose = () => {
    setCookie('cookie-consent-allowed', true, { maxAge: 31536000 });
  };
  return (
    !cookieConsent && (
      <Alert
        colorScheme="brand"
        status="success"
        variant="subtle"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon />
        <AlertDescription display="block">
          {t(
            'By continuing to use the website, you consent to the use of cookies',
            'By continuing to use the website, you consent to the use of cookies',
          )}
        </AlertDescription>
        <CloseButton onClick={handleClose} />
      </Alert>
    )
  );
};

export default CookieConsent;
