import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInAppSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'app',
  initialState: {
    isBusy: false,
  },
  reducers: {
    setIsBusy: (state, action) => {
      const stateRef = state;
      stateRef.isBusy = action.payload;
    },
  },
});

const { setIsBusy } = slice.actions;

const selectIsBusy = (state) => state.app.isBusy;

export { slice, setIsBusy, selectIsBusy, registerGetStateInAppSlice };

export default slice.reducer;
