import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInTransactionsSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'transactions',
  initialState: {
    selectedId: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      const stateRef = state;
      stateRef.selectedId = action.payload;
    },
  },
});

const { setSelectedId } = slice.actions;

const selectSelectedId = (state) => state.transactions.selectedId;

const loadData =
  (dispatch) =>
  ({ skip, limit, sortInfo, filterValue }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));
        dispatch(setSelectedId(null));
        const response = await apiClient.get('/transactions', {
          params: { limit, offset: skip, sortInfo, filterValue: { filterValue } },
        });

        const { count } = response.data.transactions;
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.transactions.rows.map((transaction, index) => ({
            ...transaction,
            index: index + 1,
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

export { registerGetStateInTransactionsSlice, slice, setSelectedId, selectSelectedId, loadData };

export default slice.reducer;
