import validator from 'validator';
import {
  Flex,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  Input,
  Divider,
  PinInput,
  PinInputField,
  HStack,
  VStack,
  Text,
  useColorModeValue,
  Tooltip,
  FormHelperText,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  accountChangeAsync,
  selectAccountName,
  sendPinForSessionAccountAsync,
  signOutAsync,
} from '../../features/session';
import { showToast, TOAST_TYPE } from '../../features/toast';
import { useFocus } from '../../core/hooks';
import { checkUserExistsByLogin, selectAccount, setAccount } from '../../features/account';
import { selectFormProps, setFormProps } from '../../features/forms';
import { selectIsBusy } from '../../features/app';

const formId = 'c3020334-92e8-40aa-9a8b-59d95fb5c60b';

const AccountEditForm = () => {
  const history = useHistory();
  const [pinRef, setPinFocus] = useFocus();
  const [confirmRef, setConfirmFocus] = useFocus();
  const [pin, setPin] = useState();
  const [allowEnterPin, setAllowEnterPin] = useState(false);
  const dispatch = useDispatch();
  const accountName = useSelector(selectAccountName);
  const formProps = useSelector(selectFormProps(formId)) ?? {};
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const account = useSelector(selectAccount);
  const isBusy = useSelector(selectIsBusy);
  const { t } = useTranslation();
  const loginIsUnique = async (login) => {
    try {
      if (login === accountName) {
        return true;
      }

      const exists = await checkUserExistsByLogin(login);
      if (exists) {
        return `${t('pages.account.account.This login is already in use', 'This login is already in use')}`;
      }

      return true;
    } catch (error) {
      showToast({
        title: t(
          'pages.account.account.Field "Login" remote validation error',
          'Field "Login" remote validation error',
        ),
        description: `${t('pages.account.account.Message', 'Message')}: ${error.response?.statusText ?? error.message}`,
        type: TOAST_TYPE.ERROR,
        duration: 10000,
      });
    }

    return true;
  };

  const validateLogin = async (value) => {
    if (!value) {
      return t('pages.account.account.A value in this field is required', 'A value in this field is required');
    }

    if (!validator.isEmail(value)) {
      return t('pages.sign-up.LoginMustBeEmail', 'Login must be a valid e-mail adres');
    }

    return loginIsUnique(value);
  };

  const validateMobileNumber = (value) => {
    if (!value) {
      return t('pages.account.account.A value in this field is required', 'A value in this field is required');
    }

    if (!validator.isMobilePhone(value, 'pl-PL')) {
      return t('pages.account.account.Mobile number has incorrect format', 'Mobile number has incorrect format');
    }

    return true;
  };

  const change = (values) => {
    dispatch(
      accountChangeAsync({
        credentials: { ...values, pin },
        callback: () => {
          if (values.login !== '') {
            dispatch(
              signOutAsync({
                accountName,
              }),
            );
            dispatch(setAccount());
            history.push('/');
          }
        },
      }),
    );
  };

  const sendPinCode = (values) => {
    dispatch(
      sendPinForSessionAccountAsync({
        credentials: { mobileNumber: values.mobileNumber },
        callback: () => {
          setPinFocus();
        },
      }),
    );
  };

  const onSubmit = (values) => {
    setAllowEnterPin(true);
    sendPinCode(values);
  };

  const pinCompleted = (value) => {
    setPin(value);
    setTimeout(() => {
      setConfirmFocus();
    }, 100);
  };

  const pinChanged = (value) => {
    setPin(value);
  };

  const handleConfirmButtonClick = () => {
    change(getValues());
  };

  const handleResendButtonClick = () => {
    sendPinCode(getValues());
  };

  const setDefaultValues = () => {
    if (formProps.defaultSet) {
      return;
    }

    const values = getValues();
    if (values.login === '') {
      setValue('login', account?.login ?? '');
    }

    if (values.mobileNumber === '') {
      setValue('mobileNumber', account?.mobileNumber ?? '');
    }

    dispatch(setFormProps({ id: formId, props: { defaultSet: true } }));
  };

  const handleClick = () => {
    setDefaultValues();
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      setDefaultValues();
    }
  };

  const color = useColorModeValue('gray.400', 'gray.600');
  const isLoading = formState.isSubmitting;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormControl mt={4} isInvalid={errors.login?.message}>
          <FormLabel htmlFor="login">{t('pages.account.account.Login', 'Login (e-mail address)')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            autoFocus
            onKeyDown={handleKeyDown}
            defaultValue={account.login}
            {...register('login', { validate: validateLogin })}
            type="login"
            placeholder={t('pages.account.account.enter your new login name', 'enter your new login name')}
            onChange={(event) => {
              const { value } = event.target;
              // eslint-disable-next-line no-param-reassign
              event.target.value = (value ?? '').toLowerCase();
            }}
          />
          <FormErrorMessage>{errors.login?.message}</FormErrorMessage>
          <FormHelperText>
            {t('pages.account.account.warning', 'WARNING: After changing your login you will need to log in again')}
          </FormHelperText>
        </FormControl>
        <FormControl mt={4} isInvalid={errors.mobileNumber?.message}>
          <FormLabel htmlFor="mobileNumber">{t('pages.account.account.Mobile number', 'Mobile number')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            onKeyDown={handleKeyDown}
            defaultValue={account.mobileNumber}
            {...register('mobileNumber', { validate: validateMobileNumber })}
            type="mobile"
            placeholder={t('pages.account.account.enter your new mobile number', 'enter your new mobile number')}
          />
          <FormErrorMessage>{errors.mobileNumber?.message}</FormErrorMessage>
        </FormControl>
        <Button
          width="full"
          mt={8}
          colorScheme="brand"
          isLoading={isLoading}
          disabled={isBusy}
          type="submit"
          onClick={handleClick}
        >
          {t('pages.account.account.submit', 'Save changes')}
        </Button>
      </Box>
      <Divider my={4} />
      <VStack alignItems="flex-end">
        <HStack>
          <PinInput size="sm" onComplete={pinCompleted} onChange={pinChanged} isDisabled={!allowEnterPin}>
            <PinInputField ref={pinRef} />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Tooltip label={t('pages.account.account.SubmitTooltip', 'verify received code')}>
            <Button
              size="sm"
              ref={confirmRef}
              colorScheme="silver"
              minWidth="75"
              onClick={handleConfirmButtonClick}
              disabled={(pin?.length !== 4 && !allowEnterPin) || isBusy}
            >
              {t('pages.account.account.Submit', 'Submit')}
            </Button>
          </Tooltip>
          <Tooltip label={t('pages.account.account.ResendTooltip', 'send me new verification code')}>
            <Button
              size="sm"
              colorScheme="silver"
              minWidth="75"
              onClick={handleResendButtonClick}
              disabled={(pin?.length !== 4 && !allowEnterPin) || isBusy}
            >
              {t('pages.account.account.Resend', 'Resend')}
            </Button>
          </Tooltip>
        </HStack>
        <Text color={color} fontSize="small">
          {t(
            'pages.account.account.Enter verification code which we sent to your mobile',
            'Enter verification code which we sent to your mobile',
          )}
        </Text>
      </VStack>
    </form>
  );
};

const AccountEdit = () => (
  <Flex grow="1" alignItems="flex-start" direction="column">
    <Box minWidth={[350, 400, 500]} maxWidth={[400, 500, 500]}>
      <Box p={4}>
        <AccountEditForm />
      </Box>
    </Box>
  </Flex>
);

export default AccountEdit;
