import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectContractorView } from '../features/account';
import StretchBox from '../core/components/stretch-box';
import RfidCards from '../modules/rfid-cards';

const Home = () => {
  const contractor = useSelector(selectContractorView);
  return (
    <Flex grow="1" alignItems="stretch" direction="column" justifyContent="stretch" p="4">
      {contractor == null ? (
        <></>
      ) : (
        <StretchBox yOffset={150}>
          <RfidCards contractorId={contractor?.id} contractorName={contractor?.name} upAdmin={false} />
        </StretchBox>
      )}
    </Flex>
  );
};
export default Home;
