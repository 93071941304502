import {
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  Button,
  useColorModeValue,
  Checkbox,
  Text,
  Link,
  useBoolean,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, P24BankElement } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import env from '../../../environment';
import {
  resetStripeProps,
  createCardPaymentIntentAsync,
  createP24PaymentIntentAsync,
  selectStripeClientSecret,
  selectStripeIsOpen,
  selectStripeProps,
  setStripeIsOpen,
  setStripeError,
  setStripeDisabled,
  confirmCardPaymentAsync,
  confirmP24PaymentAsync,
  openInvoiceAsync,
  PAYMENT_METHOD,
} from '../../features/stripe';
import { selectIsBusy } from '../../features/app';

const promise = loadStripe(env.STRIPE_SECRET);

const CardCheckoutForm = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const clientSecret = useSelector(selectStripeClientSecret);
  const { succeeded, error, disabled, processing, actions, items, contractorId, invoice, paymentDescription } =
    useSelector(selectStripeProps);
  const isBusy = useSelector(selectIsBusy);

  useEffect(() => {
    dispatch(
      createCardPaymentIntentAsync({
        items,
        contractorId,
        description: paymentDescription,
      }),
    );
  }, [dispatch]);

  const placeHolderColor = useColorModeValue('#A0AEC0', '#4A5568');
  const color = useColorModeValue('#1A202C', '#E2E8F0');

  const cardStyle = {
    style: {
      base: {
        color,
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          placeHolderColor,
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChange = async () => {
    dispatch(setStripeDisabled(false));
    dispatch(setStripeError('false'));
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    dispatch(
      confirmCardPaymentAsync({
        card: elements.getElement(CardElement),
        stripe,
        clientSecret,
        items,
        successAction: actions.success,
        contractorId,
      }),
    );
  };

  const handleOpenInvoice = () => {
    dispatch(openInvoiceAsync({ invoiceLocation: invoice.invoiceLocation, invoiceNo: invoice.invoiceNo }));
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <Button
        mt={8}
        mb={4}
        isFullWidth
        colorScheme="brand"
        isLoading={processing}
        disabled={isBusy || disabled}
        id="submit"
        type="submit"
      >
        {t('components.ui.stripe.Pay now', 'Pay now')}
      </Button>
      {succeeded === true && (
        <Alert
          mb={8}
          status="success"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon />
          <AlertTitle mr={2}>Success</AlertTitle>
          <AlertDescription>
            {t(
              'components.ui.stripe.PaymentSucceed',
              'Payment succeed. Go to the page: "Invoices" for see details, or click on the link below to open document in the browser.',
            )}
          </AlertDescription>
          <Button colorScheme="brand" variant="link" disabled={isBusy} onClick={handleOpenInvoice}>
            {`${t('components.ui.stripe.Invoice', 'Invoice')}: ${invoice.invoiceNo}`}
          </Button>
        </Alert>
      )}
      {error !== '' && error !== 'false' && error !== false && (
        <Alert
          mb={8}
          status="error"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </form>
  );
};

const P24CheckoutForm = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const clientSecret = useSelector(selectStripeClientSecret);
  const { succeeded, error, disabled, processing, actions, items, contractorId, invoice, paymentDescription } =
    useSelector(selectStripeProps);
  const isBusy = useSelector(selectIsBusy);

  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => setEmail(event.target.value);

  useEffect(() => {
    dispatch(
      createP24PaymentIntentAsync({
        items,
        contractorId,
        description: paymentDescription,
      }),
    );
  }, [dispatch]);

  const backgroundColor = useColorModeValue('transparent', '#2D3748');
  const placeHolderColor = useColorModeValue('#A0AEC0', '#4A5568');
  const color = useColorModeValue('#1A202C', '#E2E8F0');

  const bankStyle = {
    style: {
      base: {
        color,
        backgroundColor,
        padding: '10px 12px',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          placeHolderColor,
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChange = async () => {
    dispatch(setStripeDisabled(false));
    dispatch(setStripeError('false'));
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    dispatch(
      confirmP24PaymentAsync({
        p24Bank: elements.getElement(P24BankElement),
        stripe,
        clientSecret,
        items,
        successAction: actions.success,
        email,
      }),
    );
  };

  const handleOpenInvoice = () => {
    dispatch(openInvoiceAsync({ invoiceLocation: invoice.invoiceLocation, invoiceNo: invoice.invoiceNo }));
  };

  const [p24Consent, setP24Consent] = useBoolean();

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <FormControl id="email" isRequired mb={4}>
        <FormLabel>{t('components.ui.stripe.EmailAddress', 'Email address')}</FormLabel>
        <Input focusBorderColor="brand.500" value={email} type="email" onChange={handleEmailChange} />
        <FormHelperText>
          {t('components.ui.stripe.EmailAddressIsRequired', 'Value in the Email address field is required')}
        </FormHelperText>
      </FormControl>
      <Checkbox colorScheme="brand" mb={4} isChecked={p24Consent} onChange={setP24Consent.toggle}>
        <Text>
          {t('components.ui.stripe.P24Consent1', 'I declare that I have familiarized myself with the')}
          <Link isExternal mx={1} color="brand.500" href="https://www.przelewy24.pl/regulamin">
            {t('components.ui.stripe.P24Consent2', 'regulations')}
          </Link>
          {t('components.ui.stripe.P24Consent3', 'and')}
          <Link isExternal mx={1} color="brand.500" href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik">
            {t('components.ui.stripe.P24Consent4', 'information obligation')}
          </Link>
          {t('components.ui.stripe.P24Consent5', 'of the Przelewy24 service.')}
        </Text>
      </Checkbox>
      <Box borderRadius="md" borderWidth={2} padding={2} borderColor={placeHolderColor}>
        <P24BankElement options={bankStyle} onChange={handleChange} />
      </Box>
      <Button
        mt={8}
        mb={4}
        isFullWidth
        colorScheme="brand"
        isLoading={processing}
        disabled={isBusy || disabled || !p24Consent || email === '' || email == null}
        id="submit"
        type="submit"
      >
        {t('components.ui.stripe.Pay now', 'Pay now')}
      </Button>
      {succeeded === true && (
        <Alert
          mb={8}
          status="success"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon />
          <AlertTitle mr={2}>Success</AlertTitle>
          <AlertDescription>
            {t(
              'components.ui.stripe.PaymentSucceed',
              'Payment succeed. Go to the page: "Invoices" for see details, or click on the link below to open document in the browser.',
            )}
          </AlertDescription>
          <Button colorScheme="brand" variant="link" disabled={isBusy} onClick={handleOpenInvoice}>
            {`${t('components.ui.stripe.Invoice', 'Invoice')}: ${invoice.invoiceNo}`}
          </Button>
        </Alert>
      )}
      {error !== '' && error !== 'false' && error !== false && (
        <Alert
          mb={8}
          status="error"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </form>
  );
};

const StripeButton = (props) => <Button size="sm" minWidth={75} {...props} />;

const Stripe = () => {
  const { t } = useTranslation();
  const { title, paymentMethod } = useSelector(selectStripeProps);
  const isOpen = useSelector(selectStripeIsOpen);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setStripeIsOpen(false));
    dispatch(resetStripeProps);
  };

  return (
    <Box zIndex="stripe">
      <AlertDialog size="xl" isOpen={isOpen} isCentered onEsc={handleClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <Divider />
            <Box my={0}>
              <AlertDialogBody pt={8}>
                <Elements stripe={promise}>
                  {paymentMethod === PAYMENT_METHOD.CARD ? <CardCheckoutForm /> : <P24CheckoutForm />}
                </Elements>
              </AlertDialogBody>
            </Box>
            <Divider />
            <AlertDialogFooter>
              <StripeButton onClick={handleClose}>{t('components.ui.stripe.Close', 'Close')}</StripeButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Stripe;
