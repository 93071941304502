import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInSppLogsSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'sppLogs',
  initialState: {
    allRowsCount: 0,
  },
  reducers: {
    setAllRowsCount: (state, action) => {
      const stateRef = state;
      stateRef.allRowsCount = action.payload;
    },
    reset: (state) => {
      const stateRef = state;
      stateRef.allRowsCount = 0;
    },
  },
});

const { setAllRowsCount, reset } = slice.actions;

const selectContractorId = (state) => state.report.contractorId;
const selectAllRowsCount = (state) => state.report.allRowsCount;
const selectSummary = (state) => state.report.summary;

const loadData =
  (dispatch) =>
  ({ filter: filterValue }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));
        const response = await apiClient.get('/spp-logs', {
          params: { limit, offset: skip, sortInfo, filterValue: { filterValue } },
        });

        const { count } = response.data.sppLogs;
        dispatch(setAllRowsCount(count));
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.sppLogs.rows.map((row, index) => ({
            ...row,
            index: index + 1,
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

export {
  registerGetStateInSppLogsSlice,
  slice,
  reset,
  selectContractorId,
  selectAllRowsCount,
  selectSummary,
  loadData,
};
export default slice.reducer;
