const translations = {
  Index: 'L.p.',
  RFIDTag: 'Nr karty/breloka',
  CardDescription: 'Użytkownik',
  CardComment: 'Opis',
  Contractor: 'Kontrahent',
  WeeklyLimit: 'Limit tygodniowy [PLN]',
  WeeklyLimitUsage: 'Wykorzystanie limitu tygodniowego',
  MonthlyLimit: 'Limit miesięczny [PLN]',
  MonthlyLimitUsage: 'Wykorzystanie limitu miesięcznego',
  WL: 'Wartość',
  WLU: 'Wykorzystano',
  ML: 'Wartość',
  MLL: 'Pozostało',
  AccessMode: 'Tryb dostępu',
  Locked: 'Blokada',
  LCK: 'BLK',
  Yes: 'Tak',
  No: 'Nie',
  AddNewCard: 'Dodaj użytkownika',
  DeleteSelectedCard: 'Usuń użytkownika',
  EditSelectedCard: 'Edytuj użytkownika',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  FieldsWithStarAreRequired: 'UWAGA: Pola z czerwoną gwiazdką są wymagane',
  Save: 'Zapisz',
  Cancel: 'Anuluj',
  AddingTitle: 'Dodawanie nowego użytkownika',
  EditingTitle: 'Edycja bieżącego użytkownika',
  RfidTagIsRequired: 'Id użytkownika jest wymagane',
  RfidTagIsRequiredDesc: 'Pole "Id użytkownika" musi mieć wartość',
  IdIsRequired: 'Id jest wymagane',
  IdIsRequiredDesc: 'Id musi mieć wartość',
  ContractorIdIsRequired: 'Kontrahent jest wymagany',
  ContractorIdIsRequiredDesc: 'Pole: "Kontrahent" musi mieć wartość',
  ChooseFromTheList: 'Wybierz z listy',
  'Field "User id" remote validation error': 'Błąd zdalnej walidacji User id',
  Message: 'Komunikat',
  RfidTagIsInUse: 'Podane Id użytkownika jest zajęte',
  RfidTagIsInUseDesc: 'Wprowadź inne Id użytkownika',
  Customer: 'Klient',
  Employee: 'Pracownik',
  Admin: 'Administrator',
  RfidCardDeleteConfirm: 'Wybrany użytkownik zostanie usunięty. Kontynuować?',
};

export default translations;
