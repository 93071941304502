import { createStandaloneToast } from '@chakra-ui/react';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInToastSlice = (storeGetState) => {
  getState = storeGetState;
};

const TOAST_POSITION = {
  TOP: 'top',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

const TOAST_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

const TOAST_VARIANT = {
  SOLID: 'solid',
  SUBTLE: 'subtle',
  LEFT_ACCENT: 'left-accent',
  TOP_ACCENT: 'top-accent',
};

const toast = createStandaloneToast();
const showToast = ({ id, title, description, type, duration, isClosable, position, variant, onCloseComplete }) => {
  if (!toast.isActive(id) && (title || description)) {
    toast({
      id,
      title,
      description,
      status: type,
      duration,
      isClosable: isClosable || duration > 5000 || duration === null,
      position: position || TOAST_POSITION.BOTTOM_LEFT,
      variant: variant || TOAST_VARIANT.SOLID,
      onCloseComplete,
    });
  }
};

const showToastWithApiClientErrorInfo = ({ title, error }) => {
  showToast({
    title,
    description: `Message: ${error.response?.statusText ? error.response.statusText : error.message}`,
    type: TOAST_TYPE.ERROR,
    duration: 10000,
  });
};

const showToastNotImplemented = () => {
  toast({
    title: 'Not implemented',
    description: "functionality hasn't been implemented yet",
    status: TOAST_TYPE.ERROR,
    position: TOAST_POSITION.BOTTOM_RIGHT,
    variant: TOAST_VARIANT.SOLID,
  });
};

const showToastDiagInfo = ({ description }) => {
  toast({
    title: 'Diag',
    description,
    status: TOAST_TYPE.WARNING,
    position: TOAST_POSITION.BOTTOM_RIGHT,
    variant: TOAST_VARIANT.SOLID,
  });
};

const closeAllToasts = () => {
  toast.closeAll();
};

const toastIsActive = (id) => toast.isActive(id);

export {
  TOAST_POSITION,
  TOAST_TYPE,
  TOAST_VARIANT,
  registerGetStateInToastSlice,
  showToast,
  showToastNotImplemented,
  showToastDiagInfo,
  showToastWithApiClientErrorInfo,
  closeAllToasts,
  toastIsActive,
};
