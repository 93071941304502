import dateFormat from 'dateformat';

const dateFormatMask = 'yyyy-mm-dd';

const includesSome = (arr, target) => {
  const result = target.some((v) => arr.includes(v));
  return result;
};

const isNullOrEmptyString = (value) => value == null || value === '';

const buildDateFilter = ({ fromDate, toDate, numDays }) => {
  if (isNullOrEmptyString(numDays)) {
    return {
      fromDate: isNullOrEmptyString(fromDate) ? null : dateFormat(Date.parse(fromDate), dateFormatMask),
      toDate: isNullOrEmptyString(toDate) ? null : dateFormat(Date.parse(toDate), dateFormatMask),
    };
  }

  const now = Date.now();
  return {
    fromDate: dateFormat(now - new Date(numDays * 24 * 60 * 60 * 1000), dateFormatMask),
    toDate: dateFormat(now, dateFormatMask),
  };
};

const normalizeRfidTag = (value) => {
  let result = value ?? '';
  result = result.replace(/[^a-fA-F0-9]/g, '');
  return result.toUpperCase();
};

const normalizeNumber = (value, defaultValue) => (value === '' || value == null ? defaultValue : value);

export { includesSome, buildDateFilter, isNullOrEmptyString, normalizeRfidTag, normalizeNumber };
