const translations = {
  Contractors: {
    lovTitle: 'Kontrahenci',
    Name: 'Nazwa',
    Address1: 'Ulica nr domu',
    Address2: 'Kod pocztowy',
    Vatin: 'NIP',
  },
  RfidCardDeleteConfirm: 'Wybrany użytkownik zostanie usunięty. Kontynuować?',
};

export default translations;
