import keyMirror from 'keymirror';
import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { showToastWithApiClientErrorInfo } from './toast';
import i18n from '../../translations/i18n';
import { MESSAGE_DIALOG_BUTTONS, MESSAGE_DIALOG_TYPE, showMessageDialogAsync } from './message-dialog';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInDevicesSlice = (storeGetState) => {
  getState = storeGetState;
};

const DEVICE_CRUD_MODE = keyMirror({
  NONE: null,
  ADD: null,
  EDIT: null,
  DELETE: null,
  SWITCH_VCR: null,
});

const slice = createSlice({
  name: 'devices',
  initialState: {
    selectedId: null,
    crudMode: DEVICE_CRUD_MODE.NONE,
    crudItem: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      const stateRef = state;
      stateRef.selectedId = action.payload;
    },
    setSelectedData: (state, action) => {
      const stateRef = state;
      stateRef.selectedData = action.payload;
    },
    setCrudMode: (state, action) => {
      const stateRef = state;
      stateRef.crudMode = action.payload;
    },
    setCrudItem: (state, action) => {
      const stateRef = state;
      stateRef.crudItem = action.payload;
    },
  },
});

const { setSelectedId, setSelectedData, setCrudItem, setCrudMode } = slice.actions;

const selectSelectedId = (state) => state.devices.selectedId;
const selectSelectedData = (state) => state.devices.selectedData;

const loadData =
  (dispatch) =>
  ({ filter: filterValue }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));
        dispatch(setSelectedId(null));

        const response = await apiClient.get('/devices', {
          params: { limit, offset: skip, sortInfo, filterValue: { filterValue } },
        });

        const { count } = response.data.devices;
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.devices.rows.map((device, index) => ({
            ...device,
            index: index + 1,
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

const flatCrudItem = (crudItem) => ({
  id: crudItem.id.value,
  name: crudItem.name.value,
  isVcrEnabled: crudItem.isVcrEnabled.value,
});

const applyCrudChanges =
  ({ crudItem, crudMode, reload, setReload }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const item = flatCrudItem(crudItem);
      // eslint-disable-next-line default-case
      switch (crudMode) {
        case DEVICE_CRUD_MODE.ADD:
          await apiClient.post('/devices', item);
          if (setReload != null) {
            setReload(reload + 1);
          }
          break;
        case DEVICE_CRUD_MODE.EDIT:
          await apiClient.patch(`/devices/${item.id}`, item);
          if (setReload != null) {
            setReload(reload + 1);
          }
          break;
        case DEVICE_CRUD_MODE.DELETE:
          dispatch(
            showMessageDialogAsync({
              type: MESSAGE_DIALOG_TYPE.CONFIRMATION,
              buttons: MESSAGE_DIALOG_BUTTONS.YES_NO,
              text: i18n.t('features.devices.DeviceDeleteConfirm', 'Selected device will be deleted. To continue?'),
              actions: {
                yes: async () => {
                  try {
                    await apiClient.delete(`/devices/${item.id}`);
                  } catch (error) {
                    showToastWithApiClientErrorInfo({ title: 'Apply crud operation', error });
                  }

                  if (setReload != null) {
                    setReload(reload + 1);
                  }
                },
              },
            }),
          );
          break;
        case DEVICE_CRUD_MODE.SWITCH_VCR:
          dispatch(
            showMessageDialogAsync({
              type: MESSAGE_DIALOG_TYPE.CONFIRMATION,
              buttons: MESSAGE_DIALOG_BUTTONS.YES_NO,
              text: i18n.t(
                'features.devices.VCRSwitchConfirm',
                'The VCR flag in selected device will be changed to the opposite. To continue?',
              ),
              actions: {
                yes: async () => {
                  try {
                    await apiClient.post(`/devices/${item.id}/switch-vcr`);
                  } catch (error) {
                    showToastWithApiClientErrorInfo({ title: 'Apply crud operation', error });
                  }

                  if (setReload != null) {
                    setReload(reload + 1);
                  }
                },
              },
            }),
          );
          break;
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Apply crud operation', error });
    }
    dispatch(setCrudMode(DEVICE_CRUD_MODE.NONE));
    dispatch(setCrudItem());
    dispatch(setIsBusy(false));
  };

export {
  registerGetStateInDevicesSlice,
  slice,
  loadData,
  setSelectedId,
  selectSelectedId,
  DEVICE_CRUD_MODE,
  setCrudItem,
  setCrudMode,
  applyCrudChanges,
  setSelectedData,
  selectSelectedData,
};

export default slice.reducer;
