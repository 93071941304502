/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  useBoolean,
  Button,
  Divider,
  HStack,
  VStack,
  Spacer,
  Heading,
  Text,
  Input,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { includesSome } from '../utils';
import { selectAccountRoles } from '../features/session';
import { loadData, selectSummary, selectAllRowsCount, openContractorsFundsReportAsync } from '../features/report';
import { selectContractor, selectContractorView } from '../features/account';

const dateFormatMask = 'yyyy-mm-dd';

const ReportFilterBar = ({ filter, setFilter, groupBy, setGroupBy, contractorId, admin }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [contractorName, setContractorName] = useState('');
  const handleClearFilters = () => {
    setUseFromDate.off();
    setUseToDate.off();
    setContractorName('');
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setFilter([]);
    setGroupBy('day');
  };

  const handleApplyFilters = () => {
    const newFilter = [];

    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    if (useFromDate || useToDate) {
      newFilter.push({
        name: 'createdAt',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    if (contractorName !== '') {
      newFilter.push({
        name: 'contractorName',
        operator: 'contains',
        type: 'string',
        value: contractorName,
      });
    }

    setFilter(newFilter);
  };
  const handleGroupByDaysClick = () => {
    setGroupBy('day');
  };
  const handleGroupByMonthsClick = () => {
    setGroupBy('month');
  };
  const handleGroupByYearsClick = () => {
    setGroupBy('year');
  };
  const handleFundsReportClick = () => {
    dispatch(openContractorsFundsReportAsync);
  };
  const handleTodayClick = () => {
    setContractorName('');
    const now = Date.now();
    const fromDate = dateFormat(Date.parse(now), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentWeekClick = () => {
    setContractorName('');
    const now = Date.now();
    let day = new Date().getDay();
    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentMonthClick = () => {
    setContractorName('');
    const now = Date.now();
    const day = new Date().getDate() - 1;
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handlePreviousMonthClick = () => {
    setContractorName('');
    const date = new Date();
    const now = Date.now();
    const dayTo = new Date().getDate();
    const fromDate = dateFormat(Date.parse(new Date(date.getFullYear(), date.getMonth() - 1, 1)), dateFormatMask);
    const toDate = dateFormat(Date.parse(new Date(now - dayTo * 24 * 60 * 60 * 1000)), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Tooltip label={t('modules.report.DownloadFundsReport', 'Download contractors funds report')}>
        <Button minWidth={222} colorScheme="brand" onClick={handleFundsReportClick}>
          {t('modules.report.FundsReport', 'Funds report')}
        </Button>
      </Tooltip>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.report.GroupBy', 'Group by')}
      </Heading>
      <Divider orientation="horizontal" />
      <HStack>
        <Button colorScheme="brand" minWidth={108} size="sm" onClick={handleGroupByDaysClick}>
          {t('modules.report.Days', 'Days')}
        </Button>
        <Button minWidth={108} colorScheme="brand" size="sm" onClick={handleGroupByMonthsClick}>
          {t('modules.report.Months', 'Months')}
        </Button>
      </HStack>
      <Button minWidth={222} colorScheme="brand" size="sm" onClick={handleGroupByYearsClick}>
        {t('modules.report.Years', 'Years')}
      </Button>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.report.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <HStack>
        <Button colorScheme="brand" minWidth={75} size="sm" onClick={handleTodayClick}>
          {t('modules.invoices.Today', 'Today')}
        </Button>
        <Button colorScheme="brand" size="sm" minWidth={138} onClick={handleCurrentWeekClick}>
          {t('modules.invoices.CurrentWeek', 'Current week')}
        </Button>
      </HStack>
      <Button minWidth={222} colorScheme="brand" size="sm" onClick={handleCurrentMonthClick}>
        {t('modules.invoices.CurrentMonth', 'Current month')}
      </Button>
      <Button minWidth={222} colorScheme="brand" size="sm" onClick={handlePreviousMonthClick}>
        {t('modules.invoices.PreviousMonth', 'Previous month')}{' '}
      </Button>
      <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
        {t('modules.invoices.FromDate', 'From date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        disabled={!useFromDate}
        minWidth={196}
        type="date"
        size="xs"
        value={dateFrom}
        onChange={(event) => setDateFrom(event.currentTarget.value)}
      />
      <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
        {t('modules.invoices.ToDate', 'To date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        minWidth={196}
        disabled={!useToDate}
        size="xs"
        type="date"
        value={dateTo}
        onChange={(event) => setDateTo(event.currentTarget.value)}
      />
      <Divider orientation="horizontal" />
      <Text size="xs">{t('modules.report.ContractorName', 'Contractor')}</Text>
      <Input
        minWidth={222}
        focusBorderColor="brand.500"
        size="xs"
        value={contractorName}
        onChange={(event) => setContractorName(event.target.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={222} onClick={handleApplyFilters}>
        {t('modules.report.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={222} onClick={handleClearFilters}>
        {t('modules.report.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.report.Index', 'No'),
    textAlign: 'end',
    maxWidth: 75,
    sortable: false,
  },
  { name: 'createdAt', header: i18n.t('modules.report.Date', 'Date'), allowUnsort: false },
  {
    name: 'contractorId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.report.ContractorId', 'Contractor Id'),
  },
  {
    name: 'contractorName',
    defaultFlex: 2,
    header: i18n.t('modules.report.ContractorName', 'Contractor'),
  },
  {
    name: 'receipts',
    type: 'number',
    header: i18n.t('modules.report.Receipts', 'Receipts [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'expenses',
    type: 'number',
    header: i18n.t('modules.report.Expenses', 'Expenses [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'balance',
    type: 'number',
    header: i18n.t('modules.report.Balance', 'Balance [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
];
const ReportSummary = () => {
  const { t } = useTranslation();
  const summary = useSelector(selectSummary);
  const count = useSelector(selectAllRowsCount);
  const { receiptsValue: receiptsSummary, expensesValue: expensesSummary, balanceValue: balanceSummary } = summary;
  return (
    <VStack>
      <HStack alignSelf="stretch">
        <Spacer />
        <Tooltip
          label={`${t('modules.report.SummaryInfoPrefix', 'Summary contains sum of all')} ${count} ${t(
            'modules.report.SummaryInfoPostfix',
            'rows',
          )}`}
        >
          <HStack alignSelf="stretch">
            <Heading size="sm">{t('modules.report.Summary', 'Summary [PLN]:')}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.report.ReceiptsSummary', 'receipts:')}</Heading>
            <Heading size="md">{(receiptsSummary ?? 0).toFixed(2)}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.report.ExpensesSummary', 'expenses:')}</Heading>
            <Heading size="md">{(expensesSummary ?? 0).toFixed(2)}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.report.BalanceSummary', 'balance:')}</Heading>
            <Heading size="md">{(balanceSummary ?? 0).toFixed(2)}</Heading>
          </HStack>
        </Tooltip>
      </HStack>
    </VStack>
  );
};
// eslint-disable-next-line react/prop-types
const Report = ({ height, contractorId, upAdmin }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [groupBy, setGroupBy] = useState('day');
  const accountRoles = useSelector(selectAccountRoles);
  const dataSource = useCallback(loadData(dispatch)({ contractorId, filter, groupBy }), [
    contractorId,
    filter,
    groupBy,
    dispatch,
  ]);
  const { loginContractor } = useSelector(selectContractor) ?? {};
  const { loginContractorView } = useSelector(selectContractorView) ?? {};
  const admin = includesSome(['admin', 'backoffice'], accountRoles) && loginContractor === loginContractorView;

  return (
    <VStack alignItems="stretch">
      <HStack alignItems="flex-start">
        <ReportFilterBar
          admin={admin}
          filter={filter}
          setFilter={setFilter}
          contractorId={contractorId}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
        />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'createdAt', dir: -1 }}
          allowUnsort={false}
          idProperty="index"
        />
      </HStack>
      <ReportSummary />
    </VStack>
  );
};
export default Report;
