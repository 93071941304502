import FileSaver from 'file-saver';
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../translations/i18n';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { showToast, showToastWithApiClientErrorInfo, TOAST_TYPE } from './toast';
import env from '../../environment';
import { increaseContractorFundsAsync } from './account';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInStripeSlice = (storeGetState) => {
  getState = storeGetState;
};

const PAYMENT_METHOD = {
  CARD: 'card',
  P24: 'p24',
};

const slice = createSlice({
  name: 'stripe',
  initialState: {
    items: [],
    clientSecret: '',
    isOpen: false,
    title: '',
    actions: {},
    succeeded: false,
    error: '',
    processing: false,
    disabled: true,
    invoice: {},
    paymentMethod: PAYMENT_METHOD.CARD,
    waitingForPaymentSucceeded: false,
  },
  reducers: {
    setStripeProps: (state, action) => {
      const { title, actions, contractorId, paymentMethod, description } = action.payload;
      const stateRef = state;
      stateRef.title = title;
      stateRef.contractorId = contractorId;
      stateRef.actions = actions ?? {};
      stateRef.paymentMethod = paymentMethod;
      stateRef.paymentDescription = description;
    },
    resetStripeProps: (state) => {
      const stateRef = state;
      stateRef.items = [];
      stateRef.clientSecret = '';
      stateRef.isOpen = false;
      stateRef.title = null;
      stateRef.actions = {};
      stateRef.succeeded = false;
      stateRef.succeededMessage = '';
      stateRef.error = '';
      stateRef.processing = false;
      stateRef.disabled = true;
      stateRef.invoice = {};
      stateRef.paymentMethod = PAYMENT_METHOD.CARD;
      stateRef.paymentDescription = '';
    },
    setStripeInvoice: (state, action) => {
      const stateRef = state;
      stateRef.invoice = action.payload;
    },
    setStripeItems: (state, action) => {
      const stateRef = state;
      stateRef.items = action.payload;
    },
    setStripeClientSecret: (state, action) => {
      const stateRef = state;
      stateRef.clientSecret = action.payload;
    },
    setStripeSucceeded: (state, action) => {
      const stateRef = state;
      stateRef.succeeded = action.payload;
    },
    setStripeProcessing: (state, action) => {
      const stateRef = state;
      stateRef.processing = action.payload;
    },
    setStripeDisabled: (state, action) => {
      const stateRef = state;
      stateRef.disabled = action.payload;
    },
    setStripeError: (state, action) => {
      const stateRef = state;
      stateRef.error = action.payload;
    },

    clearStripeItems: (state) => {
      const stateRef = state;
      stateRef.items = [];
    },
    setStripeIsOpen: (state, action) => {
      const stateRef = state;
      stateRef.isOpen = action.payload;
    },
    setStripeWaitingForPaymentSucceeded: (state, action) => {
      const stateRef = state;
      stateRef.waitingForPaymentSucceeded = action.payload;
    },
  },
});

const {
  setStripeItems,
  clearStripeItems,
  setStripeClientSecret,
  setStripeIsOpen,
  setStripeProps,
  resetStripeProps,
  setStripeDisabled,
  setStripeError,
  setStripeProcessing,
  setStripeSucceeded,
  setStripeInvoice,
  setStripeWaitingForPaymentSucceeded,
} = slice.actions;

const selectStripeIsOpen = (state) => state.stripe.isOpen;
const selectStripeWaitingForPaymentSucceeded = (state) => state.stripe.waitingForPaymentSucceeded;
const selectStripeItems = (state) => state.stripe.items;
const selectStripeProps = (state) => state.stripe;
const selectStripeClientSecret = (state) => {
  const st = state;
  return st.stripe.clientSecret;
};

const createCardPaymentIntentAsync =
  ({ items, contractorId, callback, description }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.post('/stripe/payments/cards', { items, contractorId, description });
      if (response.status === 200) {
        dispatch(setStripeClientSecret(response.data.clientSecret));
        dispatch(setStripeItems(items));
      }

      if (callback != null) {
        setImmediate(() => {
          callback(response.data.clientSecret);
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Create payment intent error', error });
    }

    dispatch(setIsBusy(false));
  };

const confirmCardPaymentAsync =
  ({ card, stripe, clientSecret, successAction, contractorId, items }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    dispatch(setStripeProcessing(true));
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (payload.error) {
      const id = payload.paymentIntent == null ? clientSecret.substring(0, 27) : payload.paymentIntent.id;
      try {
        await apiClient.delete(`/stripe/payments/cards/${id}`);
        dispatch(createCardPaymentIntentAsync({ items, contractorId }));
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: 'Cancel payment error', error });
      }

      dispatch(setStripeError(`Payment failed ${payload.error.message}`));
      dispatch(setStripeSucceeded(false));
    } else {
      let invoiceLocation = '';
      let invoiceNo = '';
      try {
        const confirmPaymentResponse = await apiClient.patch('/stripe/payments/cards', payload.paymentIntent);
        if (confirmPaymentResponse.status === 200) {
          invoiceLocation = confirmPaymentResponse.data.invoiceLocation;
          invoiceNo = confirmPaymentResponse.data.invoiceNo;
        }
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: 'Confirm payment error', error });
      }

      dispatch(successAction({ paymentId: payload.paymentIntent.id }));
      dispatch(setStripeError(''));
      dispatch(setStripeInvoice({ invoiceLocation, invoiceNo }));
      dispatch(setStripeSucceeded(true));
      dispatch(setStripeDisabled(true));
    }

    dispatch(setStripeProcessing(false));
    dispatch(setIsBusy(false));
  };

const createP24PaymentIntentAsync =
  ({ items, contractorId, callback, description }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.post('/stripe/payments/p24', { items, contractorId, description });
      if (response.status === 200) {
        dispatch(setStripeClientSecret(response.data.clientSecret));
        dispatch(setStripeItems(items));
      }

      if (callback != null) {
        setImmediate(() => {
          callback(response.data.clientSecret);
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Create payment intent error', error });
    }

    dispatch(setIsBusy(false));
  };

const confirmP24PaymentAsync =
  ({ p24Bank, stripe, clientSecret, email }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    dispatch(setStripeProcessing(true));
    const payload = await stripe.confirmP24Payment(clientSecret, {
      payment_method: {
        p24: p24Bank,
        billing_details: {
          email,
        },
      },
      payment_method_options: {
        p24: {
          tos_shown_and_accepted: true,
        },
      },
      return_url: env.STRIPE_P24_COMPLETE_URL,
    });

    if (payload.error) {
      const id = payload.paymentIntent == null ? clientSecret.substring(0, 27) : payload.paymentIntent;
      try {
        await apiClient.delete(`/stripe/payments/p24/${id}`);
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: 'Cancel payment error', error });
      }

      dispatch(setStripeError(`Payment failed ${payload.error.message}`));
      dispatch(setStripeSucceeded(false));
    }

    dispatch(setStripeProcessing(false));
    dispatch(setIsBusy(false));
  };

const finalizeStripeP24PaymentAsync =
  ({ complete, contractorId }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    if (complete.redirect_status === 'failed') {
      const id =
        complete.payment_intent == null
          ? complete.payment_intent_client_secret.substring(0, 27)
          : complete.payment_intent;
      try {
        await apiClient.delete(`/stripe/payments/p24/${id}`);
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: 'Cancel payment error', error });
      }

      dispatch(setStripeError('Payment failed'));
      dispatch(setStripeSucceeded(false));

      showToast({
        title: i18n.t('features.stripe.PaymentFailed', 'Payment failed'),
        description: `${i18n.t(
          'features.stripe.PaymentFailedDescription',
          'Payment was unsuccessful. It has not been authorized or there are insufficient funds in the account.',
        )}`,
        type: TOAST_TYPE.ERROR,
        duration: 10000,
      });
    } else {
      dispatch(setStripeWaitingForPaymentSucceeded(true));
      let invoiceLocation = '';
      let invoiceNo = '';
      let status = '';
      try {
        const confirmPaymentResponse = await apiClient.patch('/stripe/payments/p24', { id: complete.payment_intent });
        if (confirmPaymentResponse.status === 200) {
          invoiceLocation = confirmPaymentResponse.data.invoiceLocation;
          invoiceNo = confirmPaymentResponse.data.invoiceNo;
          status = confirmPaymentResponse.data.status;
        }

        if (status === 'succeeded') {
          showToast({
            title: i18n.t('features.stripe.PaymentSucceed', 'Payment succeed'),
            description: `${i18n.t(
              'features.stripe.PaymentSucceedDescription',
              'Payment succeed. Go to the page: "Invoices" for see details. Invoice no: ',
            )}${invoiceNo}`,
            type: TOAST_TYPE.SUCCESS,
            duration: 10000,
          });

          dispatch(increaseContractorFundsAsync({ contractorId, paymentId: complete.payment_intent }));
          dispatch(setStripeError(''));
          dispatch(setStripeInvoice({ invoiceLocation, invoiceNo }));
          dispatch(setStripeSucceeded(true));
          dispatch(setStripeDisabled(true));
        } else {
          dispatch(setStripeError('Payment failed'));
          dispatch(setStripeSucceeded(false));
          showToast({
            title: i18n.t('features.stripe.PaymentFailed', 'Payment failed'),
            description: `${i18n.t(
              'features.stripe.PaymentFailedDescription',
              'Payment was unsuccessful. It has not been authorized or there are insufficient funds in the account.',
            )}`,
            type: TOAST_TYPE.ERROR,
            duration: 10000,
          });
        }
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: 'Confirm payment error', error });
      }
    }

    dispatch(setStripeWaitingForPaymentSucceeded(false));
    dispatch(setStripeProcessing(false));
    dispatch(setIsBusy(false));
  };

const showStripeAsync =
  ({ paymentMethod, title, items, actions, contractorId, description }) =>
  async (dispatch) => {
    dispatch(resetStripeProps());
    dispatch(setStripeProps({ title, actions, contractorId, paymentMethod, description }));
    dispatch(setStripeItems(items));
    dispatch(setStripeIsOpen(true));
  };

const downloadInvoiceAsync =
  ({ invoiceLocation, invoiceNo }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get(`${invoiceLocation}/pdf`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${invoiceNo}.pdf`);
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Download invoice error', error });
    }

    dispatch(setIsBusy(false));
  };

const openInvoiceAsync =
  ({ invoiceLocation }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get(`${invoiceLocation}/pdf`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(blob);
      const w = window.open(fileUrl, '_blank');
      w?.focus();
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Open invoice error', error });
    }
    dispatch(setIsBusy(false));
  };

export {
  slice,
  selectStripeItems,
  selectStripeProps,
  selectStripeClientSecret,
  setStripeClientSecret,
  setStripeItems,
  clearStripeItems,
  registerGetStateInStripeSlice,
  createCardPaymentIntentAsync,
  createP24PaymentIntentAsync,
  setStripeIsOpen,
  setStripeProps,
  resetStripeProps,
  selectStripeIsOpen,
  showStripeAsync,
  confirmCardPaymentAsync,
  confirmP24PaymentAsync,
  setStripeDisabled,
  setStripeError,
  setStripeProcessing,
  setStripeSucceeded,
  downloadInvoiceAsync,
  openInvoiceAsync,
  PAYMENT_METHOD,
  finalizeStripeP24PaymentAsync,
  selectStripeWaitingForPaymentSucceeded,
  setStripeWaitingForPaymentSucceeded,
};

export default slice.reducer;
