import { Button, Link, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, Switch, Route } from 'react-router-dom';
import { selectAccountRoles } from '../../features/session';
import { includesSome } from '../../utils';

const DrawerNavListItem = ({ to, title, active, onSelected, isBusy, noContractor }) => (
  <Link tabIndex="-1" as={RouterLink} to={to} onClick={onSelected}>
    <Button
      colorScheme="brand"
      color="white"
      variant="link"
      disabled={isBusy || noContractor}
      fontSize={active && 'larger'}
      fontWeight={active && 'bold'}
    >
      {title}
    </Button>
  </Link>
);

DrawerNavListItem.defaultProps = {
  active: false,
  to: null,
  isBusy: false,
  noContractor: true,
  onSelected: undefined,
};
DrawerNavListItem.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  to: PropTypes.string,
  onSelected: PropTypes.func,
  isBusy: PropTypes.bool,
  noContractor: PropTypes.bool,
};

const DrawerNavItem = (props) => {
  const { to: linkTo } = props;
  return (
    <Switch>
      <Route exact path={linkTo} render={() => <DrawerNavListItem active {...props} />} />
      <Route path="/" render={() => <DrawerNavListItem {...props} />} />
    </Switch>
  );
};

DrawerNavItem.propTypes = {
  to: PropTypes.string.isRequired,
};

const DrawerNavList = (props) => {
  const { t } = useTranslation();
  const { pages, onSelected, isBusy, noContractor } = props;
  const accountRoles = useSelector(selectAccountRoles);
  return (
    <VStack alignItems="flex-start">
      {pages
        .filter((page) => page.roles.length === 0 || includesSome(page.roles, accountRoles))
        .map((page) => (
          <DrawerNavItem
            key={page.index}
            to={page.to}
            title={t(`${page.title}`, `${page.defaultTitle}`)}
            onSelected={onSelected}
            isBusy={isBusy}
            noContractor={page.checkContractor === true ? noContractor : false}
          />
        ))}
    </VStack>
  );
};

DrawerNavList.defaultProps = {
  isBusy: false,
  noContractor: true,
  onSelected: undefined,
};

DrawerNavList.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({ index: PropTypes.number, to: PropTypes.string, title: PropTypes.string }))
    .isRequired,
  onSelected: PropTypes.func,
  isBusy: PropTypes.bool,
  noContractor: PropTypes.bool,
};

export default DrawerNavList;
