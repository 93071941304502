import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../translations/i18n';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { MESSAGE_DIALOG_BUTTONS, MESSAGE_DIALOG_TYPE, showMessageDialogAsync } from './message-dialog';
import { showToast, showToastWithApiClientErrorInfo, TOAST_TYPE } from './toast';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInInvoicesSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'invoices',
  initialState: {
    selectedId: null,
    idDictionary: [],
    contractorId: null,
    allRowsCount: 0,
    sortInfo: null,
    summary: { grossValue: 0, taxValue: 0, netValue: 0 },
  },
  reducers: {
    setSortInfo: (state, action) => {
      const stateRef = state;
      stateRef.sortInfo = action.payload;
    },
    setSelectedId: (state, action) => {
      const stateRef = state;
      stateRef.selectedId = action.payload;
    },
    setContractorId: (state, action) => {
      const stateRef = state;
      stateRef.contractorId = action.payload;
    },
    setAllRowsCount: (state, action) => {
      const stateRef = state;
      stateRef.allRowsCount = action.payload;
    },
    setSummary: (state, action) => {
      const stateRef = state;
      stateRef.summary = action.payload;
    },
    reset: (state) => {
      const stateRef = state;
      stateRef.selectedId = null;
      stateRef.idDictionary = [];
      stateRef.contractorId = null;
      stateRef.allRowsCount = 0;
      stateRef.sortInfo = null;
      stateRef.summary = { grossValue: 0, taxValue: 0, netValue: 0 };
    },
  },
});

const { setSelectedId, setSortInfo, setContractorId, setAllRowsCount, setSummary, reset } = slice.actions;

const selectIdDictionary = (state) => state.invoices.idDictionary;
const selectSelectedId = (state) => state.invoices.selectedId;
const selectContractorId = (state) => state.invoices.contractorId;
const selectLoadedDataExists = (state) => state.invoices.allRowsCount > 0;
const selectAllRowsCount = (state) => state.invoices.allRowsCount;
const selectSortInfo = (state) => state.invoices.sortInfo;
const selectSummary = (state) => state.invoices.summary;

const loadData =
  (dispatch) =>
  ({ contractorId, filter: filterValue }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));

        const response = await apiClient.get(
          contractorId == null ? '/invoices' : `/contractors/${contractorId}/invoices`,
          {
            params: { limit, offset: skip, sortInfo, filterValue: { filterValue } },
          },
        );

        const { count, summary } = response.data.invoices;
        dispatch(setSummary(summary));
        dispatch(setAllRowsCount(count));
        dispatch(setSortInfo(sortInfo));
        dispatch(setContractorId(contractorId));
        dispatch(setSelectedId(null));
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.invoices.rows.map((invoice, index) => ({
            ...invoice,
            index: index + 1,
            grossValue: invoice.grossValue.toFixed(2),
            netValue: invoice.netValue.toFixed(2),
            taxValue: invoice.taxValue.toFixed(2),
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

const openInvoiceAsync =
  ({ invoiceId, contractorId }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const invoicesUri =
        contractorId == null ? `/invoices/${invoiceId}/pdf` : `/contractors/${contractorId}/invoices/${invoiceId}/pdf`;
      const response = await apiClient.get(invoicesUri, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(blob);
      const w = window.open(fileUrl, '_blank');
      w?.focus();
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Open invoice error', error });
    }
    dispatch(setIsBusy(false));
  };

const openInvoicesReportAsync =
  ({ contractorId, filter: filterValue, sortInfo }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get(
        contractorId == null ? '/invoices/pdf' : `/contractors/${contractorId}/invoices/pdf`,
        {
          responseType: 'blob',
          params: { sortInfo, filterValue: { filterValue } },
        },
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(blob);
      const w = window.open(fileUrl, '_blank');
      w?.focus();
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Open invoices report error', error });
    }
    dispatch(setIsBusy(false));
  };

const deleteLastInvoiceAsync =
  ({ reload, setReload }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get('/invoices/last/info');
      dispatch(
        showMessageDialogAsync({
          type: MESSAGE_DIALOG_TYPE.CONFIRMATION,
          buttons: MESSAGE_DIALOG_BUTTONS.YES_NO,
          text: `Faktura nr ${response.data.invoice_no} wystawiona na kontrahenta: ${response.data.contractor_name} (NIP: ${response.data.contractor_vatin}) zostanie bezpowrotnie usunięta z systemu. Kontynuować?`,
          actions: {
            yes: async () => {
              try {
                // eslint-disable-next-line no-unused-vars
                const deleteResponse = await apiClient.delete(`/invoices/${response.data.id}`);
                showToast({
                  title: i18n.t('features.invoices.InvoiceDeleteSucceed', 'Invoice delete succeed'),
                  description: `${i18n.t(
                    'features.invoices.InvoiceDeleteSucceedDescription',
                    'Invoice delete succeed. Deleted invoice no: ',
                  )}${response.data.invoice_no}`,
                  type: TOAST_TYPE.SUCCESS,
                  duration: 10000,
                });
              } catch (error) {
                showToastWithApiClientErrorInfo({ title: 'Delete last invoice error', error });
              }

              if (setReload != null) {
                setReload(reload + 1);
              }
            },
          },
        }),
      );
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Delete last invoice error', error });
    }
    dispatch(setIsBusy(false));
  };

export {
  registerGetStateInInvoicesSlice,
  slice,
  setSelectedId,
  reset,
  selectSortInfo,
  selectSelectedId,
  selectIdDictionary,
  selectContractorId,
  selectLoadedDataExists,
  selectAllRowsCount,
  selectSummary,
  loadData,
  openInvoiceAsync,
  openInvoicesReportAsync,
  deleteLastInvoiceAsync,
};
export default slice.reducer;
