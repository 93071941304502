import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import './translations/i18n';

const loader = document.querySelector('.loader');
const loaderText = document.querySelector('.loader-text');

// if you want to show the loader when React loads data again
const showLoader = () => {
  loader.classList.remove('loader--hide');
  loaderText.classList.remove('loader--hide');
};
const hideLoader = () => {
  loader.classList.add('loader--hide');
  loaderText.classList.add('loader--hide');
};

ReactDOM.render(
  <Provider store={store}>
    <App hideLoader={hideLoader} showLoader={showLoader} />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
