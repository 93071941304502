import { FaChevronDown, FaEye, FaEyeSlash, FaPowerOff } from 'react-icons/fa';
import {
  useMediaQuery,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Button,
  HStack,
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Tooltip,
  Text,
  useColorModeValue,
  VStack,
  Icon,
  Spacer,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectAccountName,
  selectAccountRoles,
  selectIsSignedIn,
  selectIsSigning,
  signOutAsync,
} from '../../features/session';
import AppBarNavList from './app-bar-nav-list';
import DrawerNavList from './drawer-nav-list';
import { navBarPages as pages } from '../../pages';
import {
  disableContractorView,
  enableContractorView,
  selectContractor,
  selectContractorView,
  setAccount,
} from '../../features/account';
import { selectIsBusy } from '../../features/app';
import { LogoFull } from './brand/logo';
import { includesSome } from '../../utils';
import { selectContractorFromDatabaseAsync } from '../../features/rfid-cards';
import ContractorForm from '../../modules/contractor-form';

const formId = '17d3a0be-55c5-431d-b229-916c25a4a234';

const LandingHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const isSigning = useSelector(selectIsSigning);
  const isSignedIn = useSelector(selectIsSignedIn);
  const isBusy = useSelector(selectIsBusy);
  const accountName = useSelector(selectAccountName);
  const contractor = useSelector(selectContractor);
  const contractorView = useSelector(selectContractorView);
  const accountFunds = `${(contractor?.funds ?? 0).toFixed(2)} PLN`;
  const accountRoles = useSelector(selectAccountRoles);
  const viewFunds = `${(contractorView?.funds ?? 0).toFixed(2)} PLN`;
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(
      signOutAsync({
        accountName,
      }),
    );
    dispatch(setAccount());
  };
  const handleDisableContractorView = () => {
    dispatch(disableContractorView);
    window.location.reload(false);
  };
  const setContractorView = ({ id, name }) => {
    dispatch(enableContractorView({ id, name }));
  };
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const handleEnableContractorView = () => {
    dispatch(
      selectContractorFromDatabaseAsync({
        slim: isLargerThan800 === false,
        callback: (selectedContractor) => {
          setContractorView({ id: selectedContractor.id, name: selectedContractor.name });
        },
      }),
    );
  };
  const admin = includesSome(['admin'], accountRoles);

  return (
    <Flex background="brand.500" minHeight="64px" alignItems="center" justifyContent="space-between">
      <LogoFull display={isSignedIn ? { base: 'none', md: 'block' } : 'block'} color="white" mx={2} />
      <IconButton
        display={isSignedIn ? { base: 'block', md: 'none' } : 'none'}
        color="white"
        icon={<GiHamburgerMenu fontSize="2em" />}
        size="lmd"
        mx={2}
        onClick={onDrawerOpen}
        variant="link"
        disabled={isBusy}
      />
      <AppBarNavList
        pages={pages}
        isBusy={isBusy}
        noContractor={contractor == null}
        isSignedIn={isSignedIn}
        display={isSignedIn ? { base: 'none', md: 'block' } : 'none'}
      />
      <HStack>
        <Popover closeOnBlur={false} closeOnEsc={false} placement="bottom-end">
          {({ onClose: onPopoverClose }) => (
            <>
              {admin && contractor?.id !== contractorView?.id && (
                <HStack>
                  <PopoverTrigger>
                    <Button
                      textColor={useColorModeValue('#E41937', 'white')}
                      disabled={isBusy}
                      display={isSignedIn ? 'block' : 'none'}
                    >
                      <Tooltip mr={4} label={contractorView?.name}>
                        <HStack>
                          <Heading size="md">{contractorView?.login}</Heading>
                          <Icon as={FaChevronDown} />
                        </HStack>
                      </Tooltip>
                    </Button>
                  </PopoverTrigger>
                  {admin && contractor?.id !== contractorView?.id && (
                    <HStack>
                      <Button
                        textColor={useColorModeValue('#E41937', 'white')}
                        disabled={isBusy}
                        display={isSignedIn ? 'block' : 'none'}
                        onClick={() => {
                          history.push('/funds');
                        }}
                      >
                        <Tooltip
                          mr={4}
                          label={t(
                            'components.sections.landing-header.AvailableFundsContractorView',
                            'Available funds (contractor view)',
                          )}
                        >
                          <Heading size="md">{viewFunds}</Heading>
                        </Tooltip>
                      </Button>
                    </HStack>
                  )}
                </HStack>
              )}
              <PopoverContent minWidth={500}>
                <Flex grow="1" alignItems="flex-start" direction="column">
                  <Box minWidth={[350, 400, 500]} maxWidth={[400, 500, 500]}>
                    <Box p={4}>
                      <ContractorForm
                        close={onPopoverClose}
                        contractor={contractorView}
                        contractorView={contractor}
                        gdprConsent={false}
                        formId={formId}
                      />
                    </Box>
                  </Box>
                </Flex>
              </PopoverContent>
            </>
          )}
        </Popover>
        <Spacer />
        <Popover closeOnEsc placement="bottom-end">
          {({ onClose: onPopoverClose }) => (
            <>
              <Box>
                <HStack pr={3}>
                  {contractor?.id === contractorView?.id
                    ? admin && (
                        <Tooltip
                          label={t('components.sections.landing-header.EnableContractorView', 'Enable contractor view')}
                        >
                          <Button
                            pl={2}
                            as={Button}
                            textColor={useColorModeValue('#E41937', 'white')}
                            rightIcon={<FaEye />}
                            onClick={handleEnableContractorView}
                            disabled={isBusy}
                            display={isSignedIn ? 'block' : 'none'}
                          />
                        </Tooltip>
                      )
                    : admin && (
                        <Tooltip
                          label={t(
                            'components.sections.landing-header.DisableContractorView',
                            'Disable contractor view',
                          )}
                        >
                          <Button
                            pl={2}
                            as={Button}
                            textColor={useColorModeValue('#E41937', 'white')}
                            rightIcon={<FaEyeSlash />}
                            onClick={handleDisableContractorView}
                            disabled={isBusy}
                            display={isSignedIn ? 'block' : 'none'}
                          />
                        </Tooltip>
                      )}
                  <PopoverTrigger>
                    <Button
                      disabled={isBusy}
                      display={isSignedIn ? 'block' : 'none'}
                      textColor={useColorModeValue('#E41937', 'white')}
                    >
                      <Tooltip mr={4} label={t('components.sections.landing-header.popover-trigger', 'Logged in user')}>
                        <HStack>
                          <Heading size="md">{accountName}</Heading>
                          <Icon as={FaChevronDown} />
                        </HStack>
                      </Tooltip>
                    </Button>
                  </PopoverTrigger>
                  <Tooltip mr={4} label={t('components.sections.landing-header.logout-button', 'Logout')}>
                    <Button
                      pl={2}
                      as={Button}
                      textColor={useColorModeValue('#E41937', 'white')}
                      rightIcon={<FaPowerOff />}
                      onClick={handleLogout}
                      disabled={isBusy}
                      display={isSignedIn ? 'block' : 'none'}
                    ></Button>
                  </Tooltip>
                  {contractor?.id === contractorView?.id && (
                    <Button
                      textColor={useColorModeValue('#E41937', 'white')}
                      disabled={isBusy}
                      display={isSignedIn ? 'block' : 'none'}
                      onClick={() => {
                        history.push('/funds');
                      }}
                    >
                      <Tooltip mr={4} label={t('components.sections.landing-header.AvailableFunds', 'Available funds')}>
                        <Heading size="md">{accountFunds}</Heading>
                      </Tooltip>
                    </Button>
                  )}
                </HStack>
              </Box>
              <PopoverContent>
                <PopoverHeader>
                  <VStack alignItems="flex-start">
                    <Text>{t('components.sections.landing-header.popover-trigger', 'Logged in user')}</Text>
                    <Heading size="sm">{accountName}</Heading>
                  </VStack>
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <HStack justifyContent="center">
                    <Button
                      colorScheme="brand"
                      onClick={() => {
                        onPopoverClose();
                        history.push('/account');
                      }}
                      minWidth={125}
                      disabled={isSigning || isBusy}
                    >
                      {t('components.sections.landing-header.manage-button', 'Manage')}
                    </Button>
                    <Button
                      colorScheme="silver"
                      onClick={() => {
                        onPopoverClose();
                        handleLogout();
                      }}
                      minWidth={125}
                      isLoading={isSigning}
                      disabled={isBusy}
                    >
                      {t('components.sections.landing-header.logout-button', 'Logout')}
                    </Button>
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </HStack>
      <Drawer placement="left" onClose={onDrawerClose} isOpen={isDrawerOpen}>
        <DrawerOverlay>
          <DrawerContent background="brand.500">
            <DrawerCloseButton color="white" />
            <DrawerBody>
              <DrawerNavList
                pages={pages}
                isBusy={isBusy}
                noContractor={contractor == null}
                onSelected={onDrawerClose}
              />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default LandingHeader;
