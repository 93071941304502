/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-key */
import React from 'react';
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import {
  Table as ChTable,
  Thead as ChThead,
  Tbody as ChTbody,
  Tr as ChTr,
  Th as ChTh,
  Td as ChTd,
  Flex,
  HStack,
  Text,
  Input,
  Tooltip,
  Divider,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setLovSelectedRowIds } from '../../../features/lov';

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const headerProps = (props, { column }) => getStyles(props, column.align);
const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input focusBorderColor="brand.500" type="checkbox" ref={resolvedRef} {...rest} />;
});

const buildSelectionColumn = (multiSelect) => {
  const selection = {
    id: 'selection',
    disableResizing: true,
    minWidth: 35,
    width: 35,
    maxWidth: 35,
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  };

  if (multiSelect) {
    selection.Header = ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    );
  }

  return selection;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const { t } = useTranslation();

  return (
    <HStack>
      <Text size="xs">{t('components.ui.lovTable.GlobalFilterLabel', 'Search')}</Text>
      <Tooltip label={t('components.ui.lovTable.GlobalFilterTooltip', 'Enter a search value to filter the list')}>
        <Input
          minWidth={222}
          focusBorderColor="brand.500"
          size="xs"
          value={value || ''}
          placeholder={t('components.ui.lovTable.GlobalFilterPlaceholder', 'The value you are looking for')}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </Tooltip>
    </HStack>
  );
};

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => <></>;

const LovTable = ({ columns, data, multiSelect = false }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds, globalFilter },
    toggleAllRowsSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useGlobalFilter,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [buildSelectionColumn(multiSelect), ...columns]);
    },
  );

  const dispatch = useDispatch();
  dispatch(setLovSelectedRowIds(selectedRowIds));
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Divider mt={2} />
      <ChTable w="100%" variant="striped" colorScheme="brand" size="sm" {...getTableProps()}>
        <ChThead w="100%" sx={{ 'overflow-y': 'auto', 'overflow-x': 'hidden' }}>
          {headerGroups.map((headerGroup) => (
            <ChTr {...headerGroup.getHeaderGroupProps({})}>
              {headerGroup.headers.map((column) => (
                <ChTh {...column.getHeaderProps(headerProps)}>{column.render('Header')}</ChTh>
              ))}
            </ChTr>
          ))}
        </ChThead>
        <Flex w="100%" overflowY="auto" height="200px">
          <ChTbody w="100%" sx={{ 'overflow-y': 'scroll', 'overflow-x': 'hidden', height: '200px' }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ChTr
                  {...row.getRowProps({
                    onClick: () => {
                      if (multiSelect === false) {
                        toggleAllRowsSelected(false);
                      }

                      row.toggleRowSelected(!row.isSelected);
                      dispatch(setLovSelectedRowIds(selectedRowIds));
                    },
                  })}
                >
                  {row.cells.map((cell) => (
                    <ChTd {...cell.getCellProps(cellProps)}>{cell.render('Cell')}</ChTd>
                  ))}
                </ChTr>
              );
            })}
          </ChTbody>
        </Flex>
      </ChTable>
    </>
  );
};

export default LovTable;
