import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { MESSAGE_DIALOG_TYPE, showMessageDialogAsync } from './message-dialog';
import { showToast, showToastWithApiClientErrorInfo, TOAST_TYPE } from './toast';
import i18n from '../../translations/i18n';
import { setIsBusy } from './app';
import {
  fetchContractorByUserLoginOrIdAsync,
  fetchUserByLoginAsync,
  setAccount,
  setContractor,
  setContractorView,
} from './account';
import { reset as resetInvoices } from './invoices';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInSessionSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'session',
  initialState: {
    isSigning: false,
    isSignedIn: false,
    isRestoring: false,
    accountName: undefined,
    accountRoles: [],
  },
  reducers: {
    setIsSigning: (state, action) => {
      const stateRef = state;
      stateRef.isSigning = action.payload;
    },
    setIsRestoring: (state, action) => {
      const stateRef = state;
      stateRef.isRestoring = action.payload;
    },
    setIsSignedIn: (state, action) => {
      const stateRef = state;
      stateRef.isSignedIn = action.payload;
    },
    setAccountName: (state, action) => {
      const stateRef = state;
      stateRef.accountName = action.payload;
    },
    setAccountRoles: (state, action) => {
      const stateRef = state;
      stateRef.accountRoles = action.payload;
    },
  },
});

const { setIsSigning, setIsSignedIn, setIsRestoring, setAccountName, setAccountRoles } = slice.actions;
const selectIsSignedIn = (state) => state.session.isSignedIn;
const selectIsSigning = (state) => state.session.isSigning;
const selectIsRestoring = (state) => state.session.isRestoring;
const selectAccountName = (state) => state.session.accountName;
const selectAccountRoles = (state) => state.session.accountRoles;

const signInAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    const { login, password } = credentials;
    try {
      const signInResponse = await apiClient.post('/auth/sign-in', { login, password });
      showToast({
        title: i18n.t('features.session.Signed In', 'Signed In'),
        description: `${i18n.t('features.session.User', 'User')} ${login} ${i18n.t(
          'features.session.has been signed in',
          'has been signed in',
        )}`,
        type: TOAST_TYPE.SUCCESS,
      });
      dispatch(setAccountName(signInResponse.data.accountInfo.login));
      dispatch(setAccountRoles(signInResponse.data.accountInfo.roles));
      dispatch(setIsSignedIn(true));

      dispatch(fetchUserByLoginAsync({ login }));
      dispatch(fetchContractorByUserLoginOrIdAsync({ login }));
      const location = signInResponse.data.accountInfo.hasContractor ? '/' : '/account';
      if (callback != null) {
        setImmediate(() => {
          callback(location);
        });
      }
    } catch (error) {
      if ([404, 401].includes(error.response?.status)) {
        dispatch(
          showMessageDialogAsync({
            type: MESSAGE_DIALOG_TYPE.ERROR,
            title: i18n.t('features.session.Sign in failed', 'Sign in failed'),
            text: i18n.t(
              'features.session.Check login and/or password and try again. If the problem persists, contact your system administrator',
              'Check login and/or password and try again. If the problem persists, contact your system administrator',
            ),
            actions: { ok: () => {} },
          }),
        );
      } else {
        showToastWithApiClientErrorInfo({ title: i18n.t('features.session.Sign in error', 'Sign in error'), error });
      }
    }

    dispatch(setIsSigning(false));
    dispatch(setIsBusy(false));
  };

const signOutAsync =
  ({ accountName, callback }) =>
  async (dispatch) => {
    setTimeout(async () => {
      dispatch(setIsBusy(true));
      try {
        await apiClient.post('/auth/sign-out');
        showToast({
          title: i18n.t('features.session.Signed Out', 'Signed Out'),
          description: `${i18n.t('features.session.User', 'User')} ${accountName} ${i18n.t(
            'features.session.has been signed out',
            'has been signed out',
          )}`,
          type: TOAST_TYPE.SUCCESS,
        });
        dispatch(setAccountName());
        dispatch(setAccountRoles([]));
        dispatch(setAccount());
        dispatch(setContractor());
        dispatch(setContractorView());
        dispatch(resetInvoices());
        dispatch(setIsSignedIn(false));
        if (callback != null) {
          setImmediate(() => {
            callback();
          });
        }
      } catch (error) {
        showToastWithApiClientErrorInfo({ title: i18n.t('features.session.Sign out error', 'Sign out error'), error });
      }

      dispatch(setIsBusy(false));
    }, 100);
  };

const checkSessionAsync = () => async (dispatch) => {
  dispatch(setIsRestoring(true));
  dispatch(setIsBusy(true));
  try {
    const checkOutResponse = await apiClient.post('/auth/check-out');
    showToast({
      title: i18n.t('features.session.Session restored', 'Session restored'),
      description: i18n.t('features.session.Last session restored successfully', 'Last session restored successfully'),
      type: TOAST_TYPE.SUCCESS,
    });
    dispatch(setAccountName(checkOutResponse.data.accountInfo.login));
    dispatch(setAccountRoles(checkOutResponse.data.accountInfo.roles));
    dispatch(setIsSignedIn(true));
    dispatch(fetchUserByLoginAsync({ login: checkOutResponse.data.accountInfo.login }));
    dispatch(fetchContractorByUserLoginOrIdAsync({ login: checkOutResponse.data.accountInfo.login }));
  } catch (error) {
    dispatch(setIsSignedIn(false));
    if (error.response?.status !== 401) {
      showToastWithApiClientErrorInfo({
        title: i18n.t('features.session.Check session error', 'Check session error'),
        error,
      });
    }
  }

  dispatch(setIsBusy(false));
  dispatch(setIsRestoring(false));
};

const signUpAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    try {
      const { login, password, mobileNumber, pin } = credentials;
      await apiClient.post('/auth/sign-up', { login, password, mobileNumber, pin });
      showToast({
        title: i18n.t('features.session.Signed Up', 'Signed Up'),
        description: `${i18n.t('features.session.User', 'User')} ${login} ${i18n.t(
          'features.session.has been signed up',
          'has been signed up',
        )}`,
        type: TOAST_TYPE.SUCCESS,
      });
      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: i18n.t('features.session.Sign up error', 'Sign up error'), error });
    }

    dispatch(setIsBusy(false));
    dispatch(setIsSigning(false));
  };

const accountChangeAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    try {
      const { login, mobileNumber, pin } = credentials;
      await apiClient.patch('/users', { login, mobileNumber, pin });
      showToast({
        title: i18n.t('features.session.AccountChanged', 'Account changed'),
        description:
          login === ''
            ? i18n.t('features.session.AccountChangedSuccessFully', 'Account properties changed successfully')
            : i18n.t(
                'features.session.AccountChangedSuccessFullySignIn',
                'Account properties changed successfully. Sign in with new login',
              ),
        type: TOAST_TYPE.SUCCESS,
      });
      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: i18n.t('features.session.AccountChangeError', 'Account change error'),
        error,
      });
    }

    dispatch(setIsBusy(false));
    dispatch(setIsSigning(false));
  };

const sendPinAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    try {
      const { mobileNumber, login } = credentials;
      await apiClient.post('/auth/send-verification-code', { mobileNumber, login });
      showToast({
        title: i18n.t('features.session.Verification code sent', 'Verification code sent'),
        description: i18n.t(
          'features.session.We have sent a verification code to your mobile. Check your messages',
          'We have sent a verification code to your mobile. Check your messages',
        ),
        duration: 10000,
      });
      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: i18n.t('features.session.Verification code sending error', 'Verification code sending error'),
        error,
      });
    }

    dispatch(setIsBusy(false));
    dispatch(setIsSigning(false));
  };

const sendPinForSessionAccountAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    try {
      const { mobileNumber, login } = credentials;
      await apiClient.post('/auth/send-verification-code-for-session-account', { mobileNumber, login });
      showToast({
        title: i18n.t('features.session.Verification code sent', 'Verification code sent'),
        description: i18n.t(
          'features.session.We have sent a verification code to your mobile. Check your messages',
          'We have sent a verification code to your mobile. Check your messages',
        ),
        duration: 10000,
      });
      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: i18n.t('features.session.Verification code sending error', 'Verification code sending error'),
        error,
      });
    }

    dispatch(setIsBusy(false));
    dispatch(setIsSigning(false));
  };

const passwordChangeAsync =
  ({ credentials, callback }) =>
  async (dispatch) => {
    dispatch(setIsSigning(true));
    dispatch(setIsBusy(true));
    try {
      const { login, password, pin } = credentials;
      await apiClient.post('/auth/password-change', { login, password, pin });
      showToast({
        title: i18n.t('features.session.Password changed', 'Password changed'),
        description: i18n.t(
          'features.session.Password changed successfully. Sign in with new password',
          'Password changed successfully. Sign in with new password',
        ),
        type: TOAST_TYPE.SUCCESS,
      });
      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: i18n.t('features.session.Password change error', 'Password change error'),
        error,
      });
    }

    dispatch(setIsBusy(false));
    dispatch(setIsSigning(false));
  };

export {
  setIsSigning,
  setIsRestoring,
  selectIsRestoring,
  selectIsSignedIn,
  selectIsSigning,
  selectAccountName,
  selectAccountRoles,
  checkSessionAsync,
  signInAsync,
  signUpAsync,
  signOutAsync,
  passwordChangeAsync,
  sendPinAsync,
  accountChangeAsync,
  sendPinForSessionAccountAsync,
  registerGetStateInSessionSlice,
};

export default slice.reducer;
