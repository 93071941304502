/* eslint-disable react/prop-types */
import {
  useMediaQuery,
  useDisclosure,
  Heading,
  Text,
  Button,
  Divider,
  HStack,
  Spacer,
  Tooltip,
  VStack,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  FormControl,
  ModalContent,
  FormLabel,
  Checkbox,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import {
  loadData,
  setSelectedId,
  selectSelectedId,
  selectCrudMode,
  selectCrudItem,
  RFID_CARD_CRUD_MODE,
  setCrudItem,
  setCrudMode,
  applyCrudChanges,
  selectContractorFromDatabaseAsync,
  checkRfidExistsByRfidTag,
  setSelectedData,
  selectSelectedData,
} from '../features/rfid-cards';
import { showMessageDialogAsync, MESSAGE_DIALOG_TYPE } from '../features/message-dialog';
import { selectAccountRoles } from '../features/session';
import { includesSome, normalizeRfidTag, normalizeNumber } from '../utils';
import { showToast, TOAST_TYPE } from '../features/toast';
import { selectIsBusy } from '../features/app';
import { selectContractorView } from '../features/account';

// eslint-disable-next-line react/prop-types
const LockedRender = ({ value }) => (
  <div style={{ display: 'inline-block', color: value ? '#ff595e' : null }}>
    {value ? i18n.t('modules.rfidCards.Yes', 'Yes') : i18n.t('modules.rfidCards.No', 'No')}
  </div>
);

const defaultGroups = [{ name: 'ml', header: i18n.t('modules.rfidCards.MonthlyLimit', 'Monthly limit [PLN]') }];
const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.rfidCards.Index', 'No'),
    textAlign: 'end',
    defaultWidth: 75,
    sortable: false,
  },
  { name: 'rfidTag', defaultFlex: 0.6, header: i18n.t('modules.rfidCards.RFIDTag', 'Card/Pedant No') },
  {
    name: 'cardDescription',
    defaultFlex: 1,
    header: i18n.t('modules.rfidCards.CardDescription', 'User'),
  },
  {
    name: 'cardComment',
    defaultFlex: 1,
    header: i18n.t('modules.rfidCards.CardComment', 'Description'),
  },
  {
    name: 'contractor',
    defaultFlex: 2,
    header: i18n.t('modules.rfidCards.Contractor', 'Contractor'),
    defaultVisible: admin,
  },
  {
    name: 'monthlyLimit',
    type: 'number',
    header: i18n.t('modules.rfidCards.ML', 'Value'),
    textAlign: 'end',
    defaultWidth: 125,
    group: 'ml',
  },
  {
    name: 'monthlyLimitLeft',
    type: 'number',
    header: i18n.t('modules.rfidCards.MLL', 'Left'),
    textAlign: 'end',
    defaultWidth: 145,
    group: 'ml',
  },
  {
    name: 'accessMode',
    header: i18n.t('modules.rfidCards.AccessMode', 'Access mode'),
    defaultVisible: admin,
  },
  {
    name: 'locked',
    header: i18n.t('modules.rfidCards.Locked', 'Locked'),
    type: 'bool',
    defaultWidth: 100,
    textAlign: 'center',
    render: ({ value }) => LockedRender({ value }),
  },
  {
    name: 'id',
    header: i18n.t('modules.rfidCards.RfidCardId', 'Id'),
    defaultVisible: false,
    defaultWidth: 75,
  },
];

const RfidCardsFilterBar = ({ setFilter, admin, onOpen, contractorId, contractorName, reload, setReload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedRfidCardId = useSelector(selectSelectedId);
  const [rfidTag, setRfidTag] = useState('');
  const [cardDescription, setCardDescription] = useState('');
  const [cardComment, setCardComment] = useState('');
  const [contractor, setContractor] = useState('');
  const handleClearFilters = () => {
    setRfidTag('');
    setContractor('');
    setCardComment('');
    setCardDescription('');
    setFilter([]);
  };

  const handleApplyFilters = () => {
    const filter = [];

    if (rfidTag !== '') {
      filter.push({
        name: 'rfidTag',
        operator: 'contains',
        type: 'string',
        value: rfidTag,
      });
    }

    if (cardDescription !== '') {
      filter.push({
        name: 'cardDescription',
        operator: 'contains',
        type: 'string',
        value: cardDescription,
      });
    }

    if (cardComment !== '') {
      filter.push({
        name: 'cardComment',
        operator: 'contains',
        type: 'string',
        value: cardComment,
      });
    }

    if (contractor !== '') {
      filter.push({
        name: 'contractor',
        operator: 'contains',
        type: 'string',
        value: contractor,
      });
    }

    setFilter(filter);
  };
  const selectedData = useSelector(selectSelectedData);
  const handleAdd = () => {
    dispatch(setCrudMode(RFID_CARD_CRUD_MODE.ADD));
    dispatch(
      setCrudItem({
        id: { value: null, error: null },
        rfidTag: { value: null, error: null },
        rfidDesc: { value: null, error: null },
        rfidComment: { value: null, error: null },
        weeklyLimit: { value: 0, error: null },
        monthlyLimit: { value: 0, error: null },
        contractorId: { value: admin ? null : contractorId, error: null },
        contractorName: { value: admin ? null : contractorName, error: null },
        locked: { value: false, error: null },
        accessModeId: { value: '1', error: null },
      }),
    );
    onOpen();
  };
  const handleEdit = () => {
    dispatch(setCrudMode(RFID_CARD_CRUD_MODE.EDIT));
    dispatch(
      setCrudItem({
        id: { value: selectedData?.id, error: null },
        rfidTag: { value: selectedData?.rfidTag, error: null },
        rfidDesc: { value: selectedData?.cardDescription, error: null },
        rfidComment: { value: selectedData?.cardComment, error: null },
        weeklyLimit: { value: selectedData?.weeklyLimit, error: null },
        monthlyLimit: { value: selectedData?.monthlyLimit, error: null },
        contractorId: { value: admin ? selectedData?.contractorId : contractorId, error: null },
        contractorName: { value: admin ? selectedData?.contractor : contractorName, error: null },
        locked: { value: selectedData?.locked, error: null },
        accessModeId: { value: `${selectedData?.accessModeId}`, error: null },
      }),
    );
    onOpen();
  };

  const handleDelete = () => {
    const crudMode = RFID_CARD_CRUD_MODE.DELETE;
    dispatch(setCrudMode(crudMode));
    const crudItem = {
      id: { value: selectedData?.id, error: null },
      rfidTag: { value: selectedData?.rfidTag, error: null },
      rfidDesc: { value: selectedData?.cardDescription, error: null },
      rfidComment: { value: selectedData?.cardComment, error: null },
      weeklyLimit: { value: selectedData?.weeklyLimit, error: null },
      monthlyLimit: { value: selectedData?.monthlyLimit, error: null },
      contractorId: { value: admin ? selectedData?.contractorId : contractorId, error: null },
      contractorName: { value: admin ? selectedData?.contractor : contractorName, error: null },
      locked: { value: selectedData?.locked, error: null },
      accessModeId: { value: `${selectedData?.accessModeId}`, error: null },
    };
    dispatch(setCrudItem(crudItem));
    if (selectedData?.id === '' && selectedData?.id == null) {
      dispatch(
        showMessageDialogAsync({
          type: MESSAGE_DIALOG_TYPE.ERROR,
          title: i18n.t('modules.rfidCards.IdIsRequired', 'Id is required'),
          text: i18n.t('modules.rfidCards.IdIsRequiredDesc', 'Id field must have a value.'),
          actions: { ok: () => {} },
        }),
      );

      return;
    }

    dispatch(applyCrudChanges({ crudMode, crudItem, reload, setReload }));
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Button colorScheme="brand" minWidth={200} onClick={handleAdd}>
        {t('modules.rfidCards.AddNewCard', 'Add new user')}
      </Button>
      <Button disabled={selectedRfidCardId == null} colorScheme="brand" minWidth={200} onClick={handleEdit}>
        {t('modules.rfidCards.EditSelectedCard', 'Edit selected user')}
      </Button>
      <Button disabled={selectedRfidCardId == null} colorScheme="silver" minWidth={200} on onClick={handleDelete}>
        {t('modules.rfidCards.DeleteSelectedCard', 'Delete selected user')}
      </Button>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.rfidCards.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <Text size="xs">{t('modules.rfidCards.RFIDTag', 'Card/pedant No')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sx"
        value={rfidTag}
        onChange={(event) => setRfidTag(event.target.value)}
      />
      <Text size="xs">{t('modules.rfidCards.CardDescription', 'User')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sx"
        value={cardDescription}
        onChange={(event) => setCardDescription(event.target.value)}
      />
      <Text size="xs">{t('modules.rfidCards.CardComment', 'Description')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sx"
        value={cardComment}
        onChange={(event) => setCardComment(event.target.value)}
      />
      {admin && <Text size="xs">{t('modules.rfidCards.Contractor', 'Contractor')}</Text>}
      {admin && (
        <Input
          focusBorderColor="brand.500"
          size="sm"
          value={contractor}
          onChange={(event) => setContractor(event.target.value)}
        />
      )}
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={200} onClick={handleApplyFilters}>
        {t('modules.rfidCards.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={200} onClick={handleClearFilters}>
        {t('modules.rfidCards.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

// eslint-disable-next-line react/prop-types
const RfidCards = ({ height, contractorId, contractorName, upAdmin }) => {
  const dispatch = useDispatch();
  const onSelectedIdChange = useCallback((selectedId) => {
    dispatch(setSelectedId(selectedId));
  }, []);
  const onSelectedDataChange = useCallback((data) => {
    dispatch(setSelectedData(data));
  }, []);
  const selectedRfidCardId = useSelector(selectSelectedId);
  const accountRoles = useSelector(selectAccountRoles);
  const [filter, setFilter] = useState({});
  const [reload, setReload] = useState(0);
  const dataSource = useCallback(loadData(dispatch)({ contractorId, filter }), [
    contractorId,
    filter,
    dispatch,
    reload,
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const { t } = useTranslation();
  const { login } = useSelector(selectContractorView) ?? {};
  const admin = upAdmin === false ? includesSome(['admin', 'backoffice'], accountRoles) && login == null : true;
  const color = useColorModeValue('gray.400', 'gray.600');
  const crudMode = useSelector(selectCrudMode);
  const crudItem = useSelector(selectCrudItem);
  const modalTitle =
    crudMode === RFID_CARD_CRUD_MODE.ADD
      ? t('modules.rfidCards.AddingTitle', 'Adding a new user')
      : t('modules.rfidCards.EditingTitle', 'Edit current user');

  const isBusy = useSelector(selectIsBusy);
  const setRfidTag = (value) => {
    dispatch(setCrudItem({ ...crudItem, rfidTag: { value: normalizeRfidTag(value), error: null } }));
  };
  const setRfidDesc = (value) => {
    dispatch(setCrudItem({ ...crudItem, rfidDesc: { value, error: null } }));
  };
  const setRfidComment = (value) => {
    dispatch(setCrudItem({ ...crudItem, rfidComment: { value, error: null } }));
  };
  const setMonthlyLimit = (value, defaultValue) => {
    dispatch(
      setCrudItem({
        ...crudItem,
        monthlyLimit: { value: normalizeNumber(value, defaultValue), error: null },
      }),
    );
  };
  const setLocked = (value) => {
    dispatch(setCrudItem({ ...crudItem, locked: { value, error: null } }));
  };
  const setAccessModeId = (value) => {
    dispatch(setCrudItem({ ...crudItem, accessModeId: { value, error: null } }));
  };
  const setContractorIdAndName = ({ id, name }) => {
    dispatch(
      setCrudItem({
        ...crudItem,
        contractorName: { value: name, error: null },
        contractorId: { value: id, error: null },
      }),
    );
  };

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const selectContractor = () => {
    dispatch(
      selectContractorFromDatabaseAsync({
        slim: isLargerThan800 === false,
        callback: (selectedContractor) => {
          setContractorIdAndName({ id: selectedContractor.id, name: selectedContractor.name });
        },
      }),
    );
  };

  const rfidTagExists = async (rfidTag) => {
    try {
      const exists = await checkRfidExistsByRfidTag(rfidTag);
      return exists;
    } catch (error) {
      showToast({
        title: t(
          'modules.rfidCards.Field "User id" remote validation error',
          'Field "User id" remote validation error',
        ),
        description: `${t('modules.rfidCards.Message', 'Message')}: ${error.response?.statusText ?? error.message}`,
        type: TOAST_TYPE.ERROR,
        duration: 10000,
      });
    }

    return false;
  };

  const submit = async () => {
    if (crudItem.rfidTag.value === '' || crudItem.rfidTag.value == null) {
      dispatch(
        showMessageDialogAsync({
          type: MESSAGE_DIALOG_TYPE.ERROR,
          title: i18n.t('modules.rfidCards.RfidTagIsRequired', 'User id is required'),
          text: i18n.t('modules.rfidCards.RfidTagIsRequiredDesc', '"User id" field must have a value.'),
          actions: { ok: () => {} },
        }),
      );

      initialRef.current.focus();
      return;
    }

    if (crudItem.contractorId.value === '' || crudItem.contractorId.value == null) {
      dispatch(
        showMessageDialogAsync({
          type: MESSAGE_DIALOG_TYPE.ERROR,
          title: i18n.t('modules.rfidCards.ContractorIdIsRequired', 'Contractor is required'),
          text: i18n.t('modules.rfidCards.ContractorIdIsRequiredDesc', '"Contractor" field must have a value.'),
          actions: { ok: () => {} },
        }),
      );

      return;
    }

    if (crudMode === RFID_CARD_CRUD_MODE.ADD) {
      const exists = await rfidTagExists(crudItem.rfidTag.value);
      if (exists) {
        dispatch(
          showMessageDialogAsync({
            type: MESSAGE_DIALOG_TYPE.ERROR,
            title: i18n.t('modules.rfidCards.RfidTagIsInUse', 'This User id is already in use'),
            text: i18n.t('modules.rfidCards.RfidTagIsInUseDesc', 'Enter other User id.'),
            actions: { ok: () => {} },
          }),
        );
        initialRef.current.focus();
        return;
      }
    }

    dispatch(applyCrudChanges({ crudMode, crudItem, reload, setReload }));
    onClose();
  };
  const onRowDoubleClick = useCallback((rowProps) => {
    dispatch(setCrudMode(RFID_CARD_CRUD_MODE.EDIT));
    dispatch(
      setCrudItem({
        id: { value: rowProps.data?.id, error: null },
        rfidTag: { value: rowProps.data?.rfidTag, error: null },
        rfidDesc: { value: rowProps.data?.cardDescription, error: null },
        rfidComment: { value: rowProps.data?.cardComment, error: null },
        weeklyLimit: { value: rowProps.data?.weeklyLimit, error: null },
        monthlyLimit: { value: rowProps.data?.monthlyLimit, error: null },
        contractorId: { value: admin ? rowProps.data?.contractorId : contractorId, error: null },
        contractorName: { value: admin ? rowProps.data?.contractor : contractorName, error: null },
        locked: { value: rowProps.data?.locked, error: null },
        accessModeId: { value: `${rowProps.data?.accessModeId}`, error: null },
      }),
    );
    onOpen();
  }, []);

  return (
    <>
      <HStack alignItems="flex-start">
        <RfidCardsFilterBar
          setFilter={setFilter}
          contractorId={contractorId}
          contractorName={contractorName}
          admin={admin}
          onOpen={onOpen}
          reload={reload}
          setReload={setReload}
        />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          onSelectedIdChange={onSelectedIdChange}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'id', dir: -1 }}
          allowUnsort={false}
          idProperty="id"
          selectedId={selectedRfidCardId}
          onSelectedDataChange={onSelectedDataChange}
          onRowDblClick={onRowDoubleClick}
          groups={defaultGroups}
        />
      </HStack>
      <Modal
        size="xl"
        isCentered
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        isOpen={isOpen && crudItem != null}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack alignItems="flex-end">
              <Checkbox
                mb={4}
                colorScheme="brand"
                isChecked={crudItem?.locked?.value}
                size="lg"
                onChange={(event) => setLocked(event.currentTarget.checked)}
              >
                BLOKADA
              </Checkbox>
            </VStack>
            <HStack>
              <FormControl>
                <FormLabel>{t('modules.rfidCards.CardDescription', 'Description')}</FormLabel>
                <Input
                  focusBorderColor="brand.500"
                  ref={initialRef}
                  value={crudItem?.rfidDesc?.value}
                  onChange={(event) => setRfidDesc(event.target.value)}
                />
              </FormControl>
              <FormControl maxWidth={175} isRequired>
                <FormLabel>{t('modules.rfidCards.RFIDTag', 'User Id')}</FormLabel>
                <Input
                  focusBorderColor="brand.500"
                  value={crudItem?.rfidTag?.value}
                  onChange={(event) => setRfidTag(event.target.value)}
                />
              </FormControl>
            </HStack>
            <FormControl mt={4}>
              <FormLabel>{t('modules.rfidCards.CardComment', 'Comment')}</FormLabel>
              <Input
                focusBorderColor="brand.500"
                value={crudItem?.rfidComment?.value}
                onChange={(event) => setRfidComment(event.target.value)}
              />
            </FormControl>
            {admin && (
              <>
                <FormControl mt={4}>
                  <FormLabel>{t('modules.rfidCards.AccessMode', 'Access mode')}</FormLabel>
                  <RadioGroup
                    colorScheme="brand"
                    defaultValue={crudItem?.accessModeId?.value}
                    value={crudItem?.accessModeId?.value}
                  >
                    <Stack spacing={5} direction="row">
                      <Radio
                        colorScheme="brand"
                        value="1"
                        isChecked={crudItem?.accessModeId?.value === '1'}
                        onChange={(event) => setAccessModeId(event.target.value)}
                      >
                        {t('modules.rfidCards.Customer', 'Customer')}
                      </Radio>
                      <Radio
                        colorScheme="brand"
                        isChecked={crudItem?.accessModeId?.value === '2'}
                        value="2"
                        onChange={(event) => setAccessModeId(event.target.value)}
                      >
                        {t('modules.rfidCards.Employee', 'Employee')}
                      </Radio>
                      <Radio
                        isChecked={crudItem?.accessModeId?.value === '3'}
                        value="3"
                        onChange={(event) => setAccessModeId(event.target.value)}
                      >
                        {t('modules.rfidCards.Admin', 'Admin')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl mt={4} isRequired isReadOnly>
                  <FormLabel>{t('modules.rfidCards.Contractor', 'Contractor')}</FormLabel>
                  <HStack>
                    <Input focusBorderColor="brand.500" tabIndex={-1} value={crudItem?.contractorName?.value} />
                    <Tooltip label={t('modules.rfidCards.ChooseFromTheList', 'Choose from the list')}>
                      <Button onClick={selectContractor} disabled={isBusy}>
                        ...
                      </Button>
                    </Tooltip>
                  </HStack>
                </FormControl>
              </>
            )}
            <HStack mt={4}>
              <FormControl>
                <FormLabel>{t('modules.rfidCards.MonthlyLimit', 'Monthly limit')}</FormLabel>
                <NumberInput
                  textAlign="right"
                  min={0}
                  precision={2}
                  defaultValue={crudItem?.monthlyLimit?.value}
                  value={crudItem?.monthlyLimit?.value}
                  onChange={(event) => setMonthlyLimit(event, '')}
                  onBlur={() => setMonthlyLimit(crudItem?.monthlyLimit?.value, 0)}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Text color={color} fontSize="small" mr={5}>
              {t('modules.rfidCards.FieldsWithStarAreRequired', 'ATTENTION: Fields with red star are required')}
            </Text>
            <Button colorScheme="brand" mr={3} onClick={submit} disabled={isBusy}>
              {t('modules.rfidCards.Save', 'Save')}
            </Button>
            <Button onClick={onClose}>{t('modules.rfidCards.Cancel', 'Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default RfidCards;
