const translations = {
  Days: 'Dni',
  Months: 'Miesięcy',
  Weeks: 'Tygodni',
  Devices: 'Urządzeń',
  Payments: 'Formy płatności',
  GroupBy: 'Grupuj wg',
  Filters: 'Filtry',
  TransactionTypeName: 'Rodzaj płatności',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',

  Index: 'L.p.',
  DeviceId: 'Id urządzenia',
  DeviceName: 'Urządzenie',
  Date: 'Data',
  Week: 'Tydzień',
  Month: 'Miesiąc',
  TextWeekDates: 'Od do',
  TransactionTypeId: 'Id typ transakcji',
  Summary: 'Razem:',
  AmountsSummary: 'Kwota:',
  SummaryInfoPrefix: 'Podsumowanie zawiera sumę wszystkich',
  SummaryInfoPostfix: 'rekordów',
  Print: 'Drukuj',
  CSV: 'Zapisz jako CSV',
};

export default translations;
