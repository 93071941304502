import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { showToast, showToastWithApiClientErrorInfo, TOAST_TYPE } from './toast';
import { setIsBusy } from './app';
import { LOV_SIZE, showLovAsync } from './lov';
import i18n from '../../translations/i18n';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInAccountSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'account',
  initialState: {
    mobileNumber: null,
    login: null,
    id: null,
    contractor: null,
    contractorView: null,
  },
  reducers: {
    setAccount: (state, action) => {
      const stateRef = state;
      stateRef.mobileNumber = action.payload?.mobileNumber;
      stateRef.login = action.payload?.login;
      stateRef.id = action.payload?.id;
      stateRef.contractor = action.payload?.contractor;
      stateRef.contractorView = action.payload?.contractorView;
    },
    setMobileNumber: (state, action) => {
      const stateRef = state;
      stateRef.mobileNumber = action.payload;
    },
    setLogin: (state, action) => {
      const stateRef = state;
      stateRef.login = action.payload;
    },
    setId: (state, action) => {
      const stateRef = state;
      stateRef.id = action.payload;
    },
    setContractor: (state, action) => {
      const stateRef = state;
      stateRef.contractor = action.payload;
    },
    setContractorView: (state, action) => {
      const stateRef = state;
      stateRef.contractorView = action.payload;
    },
  },
});

const { setContractorView, setContractor, setAccount } = slice.actions;
const selectAccount = (state) => state.account;
const selectContractor = (state) => state.account.contractor;
const selectContractorView = (state) => state.account.contractorView;

const checkUserExistsByLogin = async (login) => {
  try {
    const response = await apiClient.get(`/users/find-by-login/${login}`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response?.status === 404) {
      return false;
    }

    throw error;
  }

  return false;
};
const fetchUserByLoginAsync =
  ({ login, callback }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      let response = await apiClient.get(`/users/find-by-login/${login}`);
      if (response.status === 200) {
        const { location } = response.headers;
        response = await apiClient.get(location);
        dispatch(setAccount(response.data));
      }

      if (callback != null) {
        setImmediate(() => {
          callback();
        });
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Fetch user by login error', error });
    }

    dispatch(setIsBusy(false));
  };

const enableContractorView =
  ({ id, name }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.post('/contractor-view/enable', {
        id,
        name,
      });
      dispatch(setContractorView(response.data.contractor));
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Enable Contractor view error',
        error,
      });
    }

    dispatch(setIsBusy(false));
  };

const disableContractorView = async (dispatch) => {
  dispatch(setIsBusy(true));
  try {
    const response = await apiClient.post('/contractor-view/disable');
    dispatch(setContractorView(response.data.contractor));
  } catch (error) {
    showToastWithApiClientErrorInfo({
      title: 'Enable Contractor view error',
      error,
    });
  }

  dispatch(setIsBusy(false));
};

const fetchContractorByUserLoginOrIdAsync =
  ({ login, id, callback }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    let userId = id;
    let onlySetContractorView = false;
    try {
      if (userId == null) {
        onlySetContractorView = false;
        let response = await apiClient.get(`/users/find-by-login/${login}`);
        if (response.status === 200) {
          const { location } = response.headers;
          response = await apiClient.get(location);
          if (response.status === 200) {
            userId = response.data.id;
          }
        }
      } else {
        onlySetContractorView = true;
      }

      try {
        const response = await apiClient.get(`/users/${userId}/contractor`);
        if (onlySetContractorView) {
          dispatch(setContractorView(response.data));
        } else {
          dispatch(setContractor(response.data));
          dispatch(setContractorView(response.data));
        }
      } catch (err) {
        if (err.response?.status === 404) {
          dispatch(setContractor());
          dispatch(setContractorView());
          if (callback != null) {
            setImmediate(() => {
              callback();
            });
          }
        } else {
          throw err;
        }
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Fetch contractor by user login or id error', error });
    }

    dispatch(setIsBusy(false));
  };

const fetchContractorFromGusAsync =
  ({ vatin, callback }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get(`/gus/search/${vatin.replaceAll('-', '')}`);
      let lovCompaniesData = [];
      if (response.status === 200) {
        if (response.data.companies.length === 0 && response.data.message != null) {
          let message = response.data.message.errorMessageEn;
          if (i18n.language.startsWith('pl')) {
            message = response.data.message.errorMessagePl;
          }
          showToast({
            title: i18n.t('features.account.GUS Error Title', 'Not found'),
            description: message,
            type: TOAST_TYPE.ERROR,
            duration: 10000,
          });
        } else {
          lovCompaniesData = (
            Array.isArray(response.data.companies) ? response.data.companies : [response.data.companies]
          ).map((company) => {
            const result = {
              name: company.nazwa,
              address1: `${company.ulica ?? company.miejscowosc} ${company.nrNieruchomosci}${
                company.nrLokalu == null ? '' : `/${company.nrLokalu}`
              }`,
              address2: `${company.kodPocztowy} ${company.miejscowoscPoczty}`,
              vatin: company.nip,
            };

            return result;
          });

          dispatch(
            showLovAsync({
              multiSelect: false,
              title: i18n.t('features.account.GUS.lovTitle', 'Found companies'),
              size: LOV_SIZE.XL4,
              actions: {
                ok: (selectedIds) => () => {
                  Object.entries(selectedIds).forEach((entry) => {
                    if (entry[1] === true) {
                      if (callback != null) {
                        setImmediate(() => {
                          callback(lovCompaniesData[entry[0]]);
                        });
                      }
                    }
                  });
                },
              },
              data: lovCompaniesData,
              columns: [
                {
                  Header: i18n.t('features.account.GUS.Name', 'Name'),
                  accessor: 'name',
                  width: 350,
                },
                {
                  Header: i18n.t('features.account.GUS.Address1', 'Address1'),
                  accessor: 'address1',
                  width: 250,
                },
                {
                  Header: i18n.t('features.account.GUS.Address2', 'Address2'),
                  accessor: 'address2',
                  width: 200,
                },
              ],
            }),
          );
        }
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({ title: 'Fetch contractor from GUS error', error });
    }

    dispatch(setIsBusy(false));
  };

const contractorCancelChangesAsync =
  ({ contractor, callback }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      dispatch(fetchContractorByUserLoginOrIdAsync({ id: contractor?.userId, login: contractor?.login }));
      if (callback) {
        setImmediate(() => callback());
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Contractor cancel changes error',
        error,
      });
    }

    dispatch(setIsBusy(false));
  };

const contractorAddOrUpdateAsync = (contractor) => async (dispatch) => {
  dispatch(setIsBusy(true));
  try {
    await apiClient.post('/contractors', { ...contractor, typeId: 1 });
    showToast({
      title: i18n.t('features.account.contractor.SuccessToastTitle', 'Success'),
      description: i18n.t(
        'features.account.contractor.DataSuccessfullyChanged',
        'Contractor data changed successfully',
      ),
      type: TOAST_TYPE.SUCCESS,
    });
    dispatch(fetchContractorByUserLoginOrIdAsync({ id: contractor?.userId, login: contractor?.login }));
  } catch (error) {
    showToastWithApiClientErrorInfo({
      title: 'Contractor add or update error',
      error,
    });
  }

  dispatch(setIsBusy(false));
};

const refreshContractorAsync =
  ({ contractorId }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      const response = await apiClient.get(`/contractors/${contractorId}`);
      if (response.status === 200) {
        dispatch(setContractor(response.data));
        dispatch(setContractorView(response.data));
      }
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Refresh contractor error',
        error,
      });
    }
  };

const increaseContractorFundsAsync =
  ({ contractorId, paymentId }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    let cId = contractorId;
    try {
      if (cId == null) {
        const response = await apiClient.get(`/stripe/payments/${paymentId}`);
        if (response.status === 200) {
          cId = response.data.contractor_id;
        }
      }
      await apiClient.patch(`/contractors/${cId}/funds`, { paymentId });
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Increase contractor funds error',
        error,
      });
    }

    dispatch(refreshContractorAsync({ contractorId: cId }));
    dispatch(setIsBusy(false));
  };

const adjustmentFundsAsync =
  ({ contractorId, amount, name, description }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      await apiClient.patch(`/contractors/${contractorId}/funds/adjustment`, { amount, description });
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Account funds adjustment error',
        error,
      });
    }

    dispatch(enableContractorView({ id: contractorId, name }));
    showToast({
      title: i18n.t('features.account.contractor.SuccessToastTitle', 'Success'),
      description: i18n.t(
        'features.account.contractor.ContractorFundsSuccessfully',
        'Contractor funds changed successfully',
      ),
      type: TOAST_TYPE.SUCCESS,
    });
    dispatch(setIsBusy(false));
  };

const topUpFundsAsync =
  ({ contractorId, amount, name, description, paymentMethod }) =>
  async (dispatch) => {
    dispatch(setIsBusy(true));
    try {
      await apiClient.patch(`/contractors/${contractorId}/funds/top-up`, { amount, description, paymentMethod });
    } catch (error) {
      showToastWithApiClientErrorInfo({
        title: 'Account funds top-up error',
        error,
      });
    }

    dispatch(enableContractorView({ id: contractorId, name }));
    showToast({
      title: i18n.t('features.account.contractor.SuccessToastTitle', 'Success'),
      description: i18n.t(
        'features.account.contractor.ContractorFundsSuccessfully',
        'Contractor funds changed successfully',
      ),
      type: TOAST_TYPE.SUCCESS,
    });
    dispatch(setIsBusy(false));
  };

export {
  registerGetStateInAccountSlice,
  setAccount,
  setContractor,
  setContractorView,
  selectAccount,
  checkUserExistsByLogin,
  fetchUserByLoginAsync,
  fetchContractorByUserLoginOrIdAsync,
  fetchContractorFromGusAsync,
  selectContractor,
  selectContractorView,
  contractorAddOrUpdateAsync,
  increaseContractorFundsAsync,
  enableContractorView,
  disableContractorView,
  adjustmentFundsAsync,
  topUpFundsAsync,
  contractorCancelChangesAsync,
};

export default slice.reducer;
