const translations = {
  Days: 'Dni',
  Months: 'Miesięcy',
  Years: 'Lat',
  GroupBy: 'Grupuj wg',
  Filters: 'Filtry',
  ContractorName: 'Kontrahent',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',

  Index: 'L.p.',
  Date: 'Data',
  Receipts: 'Wpływy [PLN]',
  Expenses: 'Wydatki [PLN]',
  Balance: 'Saldo [PLN]',
  Summary: 'Razem [PLN]:',
  ReceiptsSummary: 'wpływy:',
  ExpensesSummary: 'wydatki:',
  BalanceSummary: 'saldo:',
  SummaryInfoPrefix: 'Podsumowanie zawiera sumę wszystkich',
  SummaryInfoPostfix: 'rekordów',
  FundsReport: 'Wykaz sald kontrahentów',
  DownloadFundsReport: 'Pobierz wykaz aktualnych sald kontrahentów',
};

export default translations;
