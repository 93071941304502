const translations = {
  PaymentSucceed: 'Płatność zakończona sukcesem',
  PaymentSucceedDescription:
    'Płatność zakończyła się sukcesem. Przejdź do zakładki "Faktury" jeżeli chcesz poznać szczegóły. Numer faktury: ',
  PaymentFailed: 'Płatność nie powiodła się',
  PaymentFailedDescription:
    'Płatność zakończyła się niepowodzeniem. Nie uzyskała autoryzacji lub brak wystarczających środków na koncie',
};

export default translations;
