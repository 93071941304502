const translations = {
  Type: 'Typ',
  Company: 'Firma',
  Person: 'Osoba prywatna',
  Vatin: 'Nip',
  'enter your new name': 'wprowadź nazwę firmy',
  'enter your new vatin': 'wprowadź nip (tylko cyfry)',
  Name: 'Nazwa',
  GetFromRegon: 'pobierz dane z bazy REGON GUS',
  Address1: 'Ulica nr domu/lokalu',
  Address2: 'Kod pocztowy poczta',
  MobileNumber: 'Nr telefonu',
  Gus: 'GUS',
  'enter your new address1': 'wprowadź ulicę i nr domu, lokalu',
  'enter your new address2': 'wprowadź kod pocztowy i miejscowość poczty',
  gdprConsent: 'Zgadzam się na przetwarzanie moich danych osobowych w systemie IMEX EPOS',
  submit: 'Zapisz zmiany',
  SubmitAndClose: 'Zapisz i zamknij',
  cancel: 'Anuluj zmiany',
  CancelAndClose: 'Anuluj i zamknij',
  'A value in this field is required': 'Wartość w tym polu jest wymagana',
  MustAcceptGdprConsent: 'Wyrażenie zgody na przetwarzanie danych osobowych jest wymagane',
  'Vatin is incorrect': 'Nr Nip jest nieprawidłowy',
  'Vatin has wrong format': 'Nr Nip ma nieprawidłowy format',
  LoginMustBeEmail: 'Użytkownik musi być prawidłowym adresem e-mail',
  ValidationError: 'Błąd walidacji',
};

export default translations;
