import { Icon } from '@chakra-ui/react';

const EposIcon = (props) => (
  <Icon viewBox="0 0 60.96 60.96" {...props}>
    <path fill="#e31937" d="M0 0h54v54H0z" />
    <path
      d="M25.66 22.323c-6.577 0-11.905 5.328-11.905 11.9 0 6.573 5.328 11.901 11.905 11.901 6.571 0 11.901-5.328 11.901-11.901 0-6.572-5.33-11.9-11.901-11.9m1.215 18.006c-4.884 0-8.845-3.962-8.845-8.853 0-4.888 3.961-8.848 8.845-8.848 4.89 0 8.853 3.96 8.853 8.848a8.852 8.852 0 01-8.853 8.853M27.003 0C12.088 0 0 12.087 0 27c0 14.91 12.088 27 27.003 27C41.913 54 54 41.91 54 27 54 12.086 41.913 0 27.003 0m3.812 50.704c-.654.06-1.314.092-1.983.092-2.681 0-5.25-.473-7.633-1.332-4.947-2.441-8.357-7.52-8.357-13.41 0-8.256 6.692-14.953 14.953-14.953 8.259 0 14.954 6.697 14.954 14.953 0 7.225-5.122 13.254-11.934 14.65m7.723-2.114a17.947 17.947 0 006.04-13.45c0-9.945-8.061-18.008-18.004-18.008-9.946 0-18.006 8.063-18.006 18.009 0 1.212.121 2.394.35 3.536a22.33 22.33 0 01-2.511-10.305c0-12.38 10.04-22.425 22.425-22.425 12.385 0 22.426 10.044 22.426 22.425 0 8.907-5.195 16.601-12.72 20.218M27.835 24.025a5.45 5.45 0 00-5.449 5.454 5.451 5.451 0 0010.903 0 5.453 5.453 0 00-5.454-5.454"
      fill="#fff"
    />
  </Icon>
);

export default EposIcon;
