import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInLovSlice = (storeGetState) => {
  getState = storeGetState;
};

const LOV_SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XL2: '2xl',
  XL3: '3xl',
  XL4: '4xl',
  XL5: '5xl',
  XL6: '6xl',
};

const slice = createSlice({
  name: 'lov',
  initialState: {
    isOpen: false,
    title: 'Sample data',
    actions: {},
    data: [],
    columns: [],
    multiSelect: false,
    selectedRowIds: {},
    size: LOV_SIZE.XL2,
  },
  reducers: {
    setLovProps: (state, action) => {
      const { title, actions, columns, data, multiSelect, size } = action.payload;
      const stateRef = state;
      stateRef.title = title;
      stateRef.actions = actions ?? {};
      stateRef.data = data;
      stateRef.columns = columns;
      stateRef.multiSelect = multiSelect;
      stateRef.size = size;
    },
    resetLovProps: (state) => {
      const stateRef = state;
      stateRef.title = null;
      stateRef.actions = {};
      stateRef.selectedRowIds = {};
      stateRef.data = [];
      stateRef.columns = [];
      stateRef.multiSelect = false;
      stateRef.size = LOV_SIZE.XL2;
    },
    setLovIsOpen: (state, action) => {
      const stateRef = state;
      stateRef.isOpen = action.payload;
    },
    setLovSelectedRowIds: (state, action) => {
      const stateRef = state;
      stateRef.selectedRowIds = action.payload;
    },
  },
});

const { setLovIsOpen, setLovProps, resetLovProps, setLovSelectedRowIds } = slice.actions;

const selectLovProps = (state) => state.lov;
const selectLovIsOpen = (state) => state.lov.isOpen;

const showLovAsync =
  ({ title, actions, data, columns, size, multiSelect = false }) =>
  async (dispatch) => {
    dispatch(setLovProps({ title, actions, data, columns, multiSelect, size }));
    dispatch(setLovIsOpen(true));
  };

export {
  slice,
  selectLovIsOpen,
  selectLovProps,
  showLovAsync,
  setLovProps,
  setLovIsOpen,
  resetLovProps,
  registerGetStateInLovSlice,
  setLovSelectedRowIds,
  LOV_SIZE,
};

export default slice.reducer;
