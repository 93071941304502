import validator from 'validator';
import {
  Flex,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Button,
  Input,
  Divider,
  PinInput,
  PinInputField,
  HStack,
  VStack,
  Text,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { signUpAsync, selectIsSigning, sendPinAsync } from '../features/session';
import EposIcon from '../core/components/brand/epos-icon';
import ColorModeSwitcher from '../core/components/color-mode-switcher';
import { showToast, TOAST_TYPE } from '../features/toast';
import { useFocus } from '../core/hooks';
import LangSwitcher from '../core/components/lang-switcher';
import { checkUserExistsByLogin } from '../features/account';
import { selectIsBusy } from '../features/app';

const SignUpHeader = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center">
      <Heading>{t('pages.sign-up.Sign Up', 'Sign Up')}</Heading>
    </Box>
  );
};

const SignUpForm = () => {
  const history = useHistory();
  const [pinRef, setPinFocus] = useFocus();
  const [confirmRef, setConfirmFocus] = useFocus();
  const [pin, setPin] = useState();
  const [allowEnterPin, setAllowEnterPin] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    formState: { errors },
  } = useForm();

  const isSigning = useSelector(selectIsSigning);
  const isBusy = useSelector(selectIsBusy);
  const { t } = useTranslation();

  const loginIsUnique = async (login) => {
    try {
      const exists = await checkUserExistsByLogin(login);
      if (exists) {
        return `${t('pages.sign-up.This login is already in use', 'This login is already in use')}`;
      }

      return true;
    } catch (error) {
      showToast({
        title: t('pages.sign-up.Field "Login" remote validation error', 'Field "Login" remote validation error'),
        description: `${t('pages.sign-up.Message', 'Message')}: ${error.response?.statusText ?? error.message}`,
        type: TOAST_TYPE.ERROR,
        duration: 10000,
      });
    }

    return true;
  };

  const validateLogin = async (value) => {
    if (!value) {
      return t('pages.sign-up.A value in this field is required', 'A value in this field is required');
    }

    if (!validator.isEmail(value)) {
      return t('pages.sign-up.LoginMustBeEmail', 'Login must be a valid e-mail adres');
    }

    return loginIsUnique(value);
  };

  const validatePassword = (value) => {
    if (!value) {
      return t('pages.sign-up.A value in this field is required', 'A value in this field is required');
    }

    return true;
  };
  const validatePasswordConfirm = (value) => {
    if (!value) {
      return t('pages.sign-up.A value in this field is required', 'A value in this field is required');
    }

    if (value !== getValues('password')) {
      return t(
        'pages.sign-up.Password and Password confirm must have same values',
        'Password and Password confirm must have same values',
      );
    }

    return true;
  };

  const validateMobileNumber = (value) => {
    if (!value) {
      return t('pages.sign-up.A value in this field is required', 'A value in this field is required');
    }

    if (!validator.isMobilePhone(value, 'pl-PL')) {
      return t('pages.sign-up.Mobile number has incorrect format', 'Mobile number has incorrect format');
    }

    return true;
  };

  const signUp = (values) => {
    dispatch(
      signUpAsync({
        credentials: { ...values, pin },
        callback: () => {
          history.push('/');
        },
      }),
    );
  };

  const sendPinCode = (values) => {
    dispatch(
      sendPinAsync({
        credentials: { mobileNumber: values.mobileNumber },
        callback: () => {
          setPinFocus();
        },
      }),
    );
  };

  const onSubmit = (values) => {
    setAllowEnterPin(true);
    sendPinCode(values);
  };

  const pinCompleted = (value) => {
    setPin(value);
    setTimeout(() => {
      setConfirmFocus();
    }, 100);
  };

  const pinChanged = (value) => {
    setPin(value);
  };

  const handleConfirmButtonClick = () => {
    signUp(getValues());
  };

  const handleResendButtonClick = () => {
    sendPinCode(getValues());
  };

  const color = useColorModeValue('gray.400', 'gray.600');
  const isLoading = isSigning || formState.isSubmitting;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormControl mt={4} isInvalid={errors.login?.message}>
          <FormLabel htmlFor="login">{t('pages.sign-up.Login', 'Login (e-mail address)')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            autoFocus
            {...register('login', { validate: validateLogin })}
            type="login"
            placeholder={t('pages.sign-up.enter your login name', 'enter your email address')}
            onChange={(event) => {
              const { value } = event.target;
              // eslint-disable-next-line no-param-reassign
              event.target.value = (value ?? '').toLowerCase();
            }}
          />
          <FormErrorMessage>{errors.login?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={4} isInvalid={errors.password?.message}>
          <FormLabel htmlFor="password">{t('pages.sign-up.Password', 'Password')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            {...register('password', { validate: validatePassword })}
            type="password"
            placeholder={t('pages.sign-up.enter your password', 'enter your password')}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={4} isInvalid={errors.passwordConfirm?.message}>
          <FormLabel htmlFor="password">{t('pages.sign-up.Password Confirm', 'Password confirm')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            {...register('passwordConfirm', { validate: validatePasswordConfirm })}
            type="password"
            placeholder={t('pages.sign-up.re-enter your new password', 'confirm your password')}
          />
          <FormErrorMessage>{errors.passwordConfirm?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors.mobileNumber?.message}>
          <FormLabel htmlFor="mobileNumber">{t('pages.sign-up.Mobile number', 'Mobile number')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            {...register('mobileNumber', { validate: validateMobileNumber })}
            type="mobile"
            placeholder={t('pages.sign-up.enter your mobile number', 'enter your mobile number')}
          />
          <FormErrorMessage>{errors.mobileNumber?.message}</FormErrorMessage>
        </FormControl>
        <Button width="full" mt={8} colorScheme="brand" isLoading={isLoading} disabled={isBusy} type="submit">
          {t('pages.sign-up.submit', 'Sign Up')}
        </Button>
      </Box>
      <Divider my={4} />
      <VStack alignItems="flex-end">
        <HStack>
          <PinInput size="sm" onComplete={pinCompleted} onChange={pinChanged} isDisabled={!allowEnterPin}>
            <PinInputField ref={pinRef} />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Tooltip label={t('pages.sign-up.SubmitTooltip', 'verify received code')}>
            <Button
              size="sm"
              ref={confirmRef}
              colorScheme="silver"
              minWidth="75"
              onClick={handleConfirmButtonClick}
              disabled={(pin?.length !== 4 && !allowEnterPin) || isBusy}
            >
              {t('pages.sign-up.Submit', 'Submit')}
            </Button>
          </Tooltip>
          <Tooltip label={t('pages.sign-up.ResendTooltip', 'send me new verification code')}>
            <Button
              size="sm"
              colorScheme="silver"
              minWidth="75"
              onClick={handleResendButtonClick}
              disabled={(pin?.length !== 4 && !allowEnterPin) || isBusy}
            >
              {t('pages.sign-up.Resend', 'Resend')}
            </Button>
          </Tooltip>
        </HStack>
        <Text color={color} fontSize="small">
          {t(
            'pages.sign-up.Enter verification code which we sent to your mobile',
            'Enter verification code which we sent to your mobile',
          )}
        </Text>
      </VStack>
    </form>
  );
};

const SignUp = () => (
  <Flex grow="1" alignItems="center" direction="column" justifyContent="center">
    <Box borderWidth={1} px={4} borderRadius="xl" boxShadow="lg" minWidth={[400, 400, 500]} maxWidth={[400, 500, 500]}>
      <HStack justifyContent="space-between" mr={4} mt={4} width="full">
        <LangSwitcher size="sm" />
        <ColorModeSwitcher size="sm" />
      </HStack>
      <Box textAlign="center">
        <EposIcon boxSize={16} color="brand.500" />
      </Box>
      <Box p={4}>
        <SignUpHeader />
        <SignUpForm />
      </Box>
    </Box>
  </Flex>
);

export default SignUp;
