/* eslint-disable react/prop-types */
import validator from 'validator';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  Input,
  Checkbox,
  HStack,
  Tooltip,
  Select,
  useMediaQuery,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import isValidNip from 'is-valid-nip';
import {
  selectAccount,
  fetchContractorFromGusAsync,
  contractorAddOrUpdateAsync,
  contractorCancelChangesAsync,
} from '../features/account';
import { selectFormProps, setFormProps } from '../features/forms';
import { selectIsBusy } from '../features/app';
import { selectIsSignedIn } from '../features/session';
import { showToast, TOAST_TYPE } from '../features/toast';

const ContractorEditForm = ({ close, contractor, contractorView, gdprConsent, formId }) => {
  const isSignedIn = useSelector(selectIsSignedIn);
  if (!isSignedIn && close) {
    close();
  }

  if (contractor?.id === contractorView?.id && close) {
    close();
  }

  const dispatch = useDispatch();
  const formProps = useSelector(selectFormProps(formId)) ?? {};
  const { id, login } = useSelector(selectAccount);
  const mobileNumber = contractor?.mobileNumber;
  const isBusy = useSelector(selectIsBusy);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const watchVatin = watch('vatin');

  const showValidationErrorToast = (toastId, message) => {
    if (gdprConsent) {
      return;
    }

    showToast({
      id: toastId,
      title: t('pages.account.contractor.ValidationError', 'Validation error'),
      description: message,
      type: TOAST_TYPE.ERROR,
      duration: 10000,
    });
  };

  const validateName = (value) => {
    let result = true;
    if (!value) {
      result = t('pages.account.contractor.A value in this field is required', 'A value in this field is required');
      showValidationErrorToast('ce3c0ff67292', 'Wartość w polu Nazwa jest wymagana');
      return result;
    }

    return result;
  };

  const validateVatin = (value) => {
    let result = true;
    if (!value) {
      result = t('pages.account.contractor.A value in this field is required', 'A value in this field is required');
      showValidationErrorToast('b9571dcb4339', 'Wartość w polu NIP jest wymagana');
      return result;
    }
    const isNum = /^\d+$/.test(value);
    if (!isNum) {
      result = t('pages.account.contractor.Vatin has wrong format', 'Vatin number has wrong format');
      showValidationErrorToast('ca48982bcd3f', 'Nr NIP ma nieprawidłowy format');
      return result;
    }

    if (isValidNip(value)) {
      return true;
    }

    result = t('pages.account.contractor.Vatin is incorrect', 'Vatin number is incorrect');
    showValidationErrorToast('e0515681af95', 'Nr NIP jest nieprawidłowy');
    return result;
  };

  const validateGdprConsent = (value) => {
    if (!value) {
      return t('pages.account.contractor.MustAcceptGdprConsent', 'You must personal data processing (gdpr) consent');
    }

    return true;
  };

  const validateMobileNumber = (value) => {
    let result = true;
    if (!value) {
      result = t('pages.sign-up.A value in this field is required', 'A value in this field is required');
      showValidationErrorToast('8de825dbbfa7', 'Wartość w polu Nr telefonu jest wymagana');
      return result;
    }

    if (!validator.isMobilePhone(value, 'pl-PL')) {
      result = t('pages.sign-up.Mobile number has incorrect format', 'Mobile number has incorrect format');
      showValidationErrorToast('7692cbc071c2', 'Nr telefonu ma nieprawidłowy format');
      return result;
    }

    return true;
  };

  const validateLogin = (value) => {
    let result = true;
    if (!value) {
      result = t('pages.sign-up.A value in this field is required', 'A value in this field is required');
      showValidationErrorToast('8cc06d684af2', 'Wartość w polu Login jest wymagana');
      return result;
    }

    if (!validator.isEmail(value)) {
      result = t('pages.sign-up.LoginMustBeEmail', 'Login must be a valid e-mail adres');
      showValidationErrorToast('eccf4dd2e572', 'Login musi byś prawidłowym adresem e-mail');
      return result;
    }

    return true;
  };

  const setDefaultValues = () => {
    if (formProps.defaultSet) {
      return;
    }

    const values = getValues();
    if (values.name === '') {
      setValue('name', contractor?.name ?? '');
    }

    if (values.vatin === '') {
      setValue('vatin', contractor?.vatin ?? '');
    }

    if (values.address1 === '') {
      setValue('address1', contractor?.address1 ?? '');
    }

    if (values.address2 === '') {
      setValue('address2', contractor?.address2 ?? '');
    }

    if (values.address3 === '') {
      setValue('address3', contractor?.address3 ?? '');
    }

    if (values.mobileNumber === '') {
      setValue('mobileNumber', contractor?.mobileNumber ?? '');
    }

    if (values.userLogin === '') {
      setValue('userLogin', contractor?.login ?? '');
    }

    dispatch(setFormProps({ id: formId, props: { defaultSet: true } }));
  };

  const handleClick = () => {
    setDefaultValues();
    if (close) {
      close();
    }
  };

  const handleCancelClick = () => {
    dispatch(
      contractorCancelChangesAsync({
        contractor: {
          ...{},
          ...{ userId: contractor?.userId, login },
          ...{ id: contractor?.id ?? id },
        },
        callback: () => {
          setValue('vatin', contractor.vatin);
          setValue('name', contractor.name);
          setValue('address1', contractor.address1);
          setValue('address2', contractor.address2);
          setValue('mobileNumber', contractor.mobileNumber);
          setValue('userLogin', contractor.login);
          errors.mobileNumber = true;
          errors.name = true;
          errors.vatin = true;
          errors.userLogin = true;
          handleClick();
        },
      }),
    );
    handleClick();
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      setDefaultValues();
    }
  };

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const handleGetDataFromRegonButtonClick = () => {
    const vatin = watchVatin === '' || watchVatin == null ? contractor?.vatin : watchVatin;
    if (vatin === '' || vatin == null) {
      return;
    }

    dispatch(
      fetchContractorFromGusAsync({
        vatin,
        slim: isLargerThan800 === false,
        callback: (selectedCompany) => {
          setValue('vatin', selectedCompany.vatin);
          setValue('name', selectedCompany.name);
          setValue('address1', selectedCompany.address1);
          setValue('address2', selectedCompany.address2);
          errors.name = true;
          errors.vatin = true;
        },
      }),
    );
  };

  const onSubmit = (values) => {
    dispatch(
      contractorAddOrUpdateAsync({
        ...values,
        ...{ userId: contractor?.userId, login },
        ...{ id: contractor?.id ?? id },
      }),
    );
  };

  const isLoading = formState.isSubmitting;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <HStack>
          <FormControl maxWidth="125" id="typeId" isInvalid={errors.typeId?.message} disabled>
            <FormLabel htmlFor="type">{t('pages.account.contractor.Type', 'Type')}</FormLabel>
            <Select disabled type="type" {...register('typeId')}>
              <option value="1" selected={contractor?.typeId === 1}>
                {t('pages.account.contractor.Company', 'Company')}
              </option>
              <option value="2" selected={contractor?.typeId === 2}>
                {t('pages.account.contractor.Person', 'Person')}
              </option>
            </Select>
            <FormErrorMessage>{errors.typeId?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.vatin?.message}>
            <FormLabel htmlFor="vatin">{t('pages.account.contractor.Vatin', 'Vatin')}</FormLabel>
            <HStack>
              <Input
                autoFocus
                focusBorderColor="brand.500"
                onKeyDown={handleKeyDown}
                defaultValue={contractor?.vatin}
                {...register('vatin', { validate: validateVatin })}
                type="vatin"
                placeholder={t('pages.account.contractor.enter your new vatin', 'enter your vatin (digits only)')}
              />
              <Tooltip label={t('pages.account.contractor.GetFromRegon', 'retrieve data from regon database')}>
                <Button
                  colorScheme="silver"
                  minWidth="75"
                  onClick={handleGetDataFromRegonButtonClick}
                  disabled={isBusy}
                >
                  {t('pages.account.contractor.Gus', 'GUS')}
                </Button>
              </Tooltip>
            </HStack>
            <FormErrorMessage>{errors.vatin?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl mt={4} isInvalid={errors.name?.message}>
          <FormLabel htmlFor="name">{t('pages.account.contractor.Name', 'Name')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            onKeyDown={handleKeyDown}
            defaultValue={contractor?.name}
            {...register('name', { validate: validateName })}
            type="name"
            placeholder={t('pages.account.contractor.enter your new name', 'enter your new name')}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors.address1?.message}>
          <FormLabel htmlFor="address1">{t('pages.account.contractor.Address1', 'Address1')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            onKeyDown={handleKeyDown}
            defaultValue={contractor?.address1}
            {...register('address1')}
            type="name"
            placeholder={t('pages.account.contractor.enter your new address1', 'enter your new address1')}
          />
        </FormControl>
        <FormControl mt={4} isInvalid={errors.address2?.message}>
          <FormLabel htmlFor="address2">{t('pages.account.contractor.Address2', 'Address2')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            onKeyDown={handleKeyDown}
            defaultValue={contractor?.address2}
            {...register('address2')}
            type="name"
            placeholder={t('pages.account.contractor.enter your new address2', 'enter your new address2')}
          />
        </FormControl>
        {!gdprConsent && (
          <>
            <FormControl mt={4} isInvalid={errors.userLogin?.message}>
              <FormLabel htmlFor="userLogin">{t('pages.sign-up.Login', 'Login (e-mail address)')}</FormLabel>
              <Input
                focusBorderColor="brand.500"
                defaultValue={contractor?.login}
                {...register('userLogin', { validate: validateLogin })}
                type="login"
                placeholder={t('pages.sign-up.enter your login name', 'enter your email address')}
              />
              <FormErrorMessage>{errors.userLogin?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.mobileNumber?.message}>
              <FormLabel htmlFor="mobile">{t('pages.account.contractor.MobileNumber', 'Mobile number')}</FormLabel>
              <Input
                focusBorderColor="brand.500"
                defaultValue={mobileNumber}
                type="mobile"
                {...register('mobileNumber', { validate: validateMobileNumber })}
                placeholder={t('pages.sign-up.enter your mobile number', 'enter your mobile number')}
              />
              <FormErrorMessage>{errors.mobileNumber?.message}</FormErrorMessage>
            </FormControl>
          </>
        )}
        {gdprConsent && (
          <FormControl mt={4} isInvalid={errors.gdprConsent?.message}>
            <Checkbox
              defaultChecked="true"
              mt={4}
              colorScheme="brand"
              {...register('gdprConsent', { validate: validateGdprConsent })}
            >
              {t(
                'pages.account.contractor.gdprConsent',
                'I agree to the processing of my personal data in the IMEX EPOS sales system',
              )}
            </Checkbox>
            <FormErrorMessage>{errors.gdprConsent?.message}</FormErrorMessage>
          </FormControl>
        )}
        <HStack mt={8}>
          <Button width="full" colorScheme="silver" isLoading={isLoading} disabled={isBusy} onClick={handleCancelClick}>
            {t(
              gdprConsent ? 'pages.account.contractor.cancel' : 'pages.account.contractor.CancelAndClose',
              'Cancel changes',
            )}
          </Button>
          <Button
            width="full"
            colorScheme="brand"
            isLoading={isLoading}
            disabled={isBusy}
            type="submit"
            onClick={handleClick}
          >
            {t(
              gdprConsent ? 'pages.account.contractor.submit' : 'pages.account.contractor.SubmitAndClose',
              'Save changes',
            )}
          </Button>
        </HStack>
      </Box>
    </form>
  );
};

export default ContractorEditForm;
