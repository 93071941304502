const translations = {
  Index: 'L.p.',
  FromDate: 'Od daty',
  ToDate: 'Do daty',
  Refresh: 'Odśwież',
  RefreshBy: 'Odśwież wg zadanego zakresu dat',
  OrderBy: 'Sortuj według',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Date: 'Data',
  DateTime: 'Data czas',
  RFIDTag: 'Nr karty/breloka',
  InvoiceNo: 'Nr faktury',
  ContractorId: 'Id kontrahenta',
  Contractor: 'Kontrahent',
  Description: 'Opis',
  Value: 'Wart. brutto [PLN]',
};

export default translations;
