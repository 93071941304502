// import FileSaver from 'file-saver';
import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { showToastWithApiClientErrorInfo } from './toast';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInPaymentsSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'payments',
  initialState: {
    allRowsCount: 0,
    summary: { receipts: 0, expenses: 0, balance: 0 },
    lastQueryParams: null,
    isPrinting: false,
    isExporting: false,
  },
  reducers: {
    setLastQueryParams: (state, action) => {
      const stateRef = state;
      stateRef.lastQueryParams = action.payload;
    },
    setIsPrinting: (state, action) => {
      const stateRef = state;
      stateRef.isPrinting = action.payload;
    },
    setIsExporting: (state, action) => {
      const stateRef = state;
      stateRef.isExporting = action.payload;
    },
    setAllRowsCount: (state, action) => {
      const stateRef = state;
      stateRef.allRowsCount = action.payload;
    },
    setSummary: (state, action) => {
      const stateRef = state;
      stateRef.summary = action.payload;
    },
    reset: (state) => {
      const stateRef = state;
      stateRef.lastQueryParams = null;
      stateRef.allRowsCount = 0;
      stateRef.summary = { receipts: 0, expenses: 0, balance: 0 };
    },
  },
});

const { setAllRowsCount, setSummary, reset, setLastQueryParams, setIsPrinting, setIsExporting } = slice.actions;

const selectIsPrinting = (state) => state.payments.isPrinting;
const selectIsExporting = (state) => state.payments.isExporting;
const selectLastQueryParams = (state) => state.payments.lastQueryParams;
const selectAllRowsCount = (state) => state.payments.allRowsCount;
const selectSummary = (state) => state.payments.summary;

const loadData =
  (dispatch) =>
  ({ filter: filterValue, groupBy, additionalGroupBy1, additionalGroupBy2 }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));
        const params = {
          limit,
          offset: skip,
          sortInfo,
          filterValue: { filterValue },
          groupBy,
          additionalGroupBy1,
          additionalGroupBy2,
        };
        dispatch(setLastQueryParams(params));
        const response = await apiClient.get('/payments/report', {
          params,
        });

        const { count, summary } = response.data.report;
        dispatch(setSummary(summary));
        dispatch(setAllRowsCount(count));
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.report.rows.map((row, index) => ({
            ...row,
            index: index + 1,
            sum: row.sum.toFixed(2),
            sumTokens: row.sumTokens.toFixed(2),
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

const openPaymentsReportAsync = async (dispatch) => {
  dispatch(setIsBusy(true));
  dispatch(setIsPrinting(true));
  try {
    const state = getState();
    const params = selectLastQueryParams(state);
    const response = await apiClient.get('/payments/report/pdf', {
      params,
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, '_blank');
    w?.focus();
  } catch (error) {
    showToastWithApiClientErrorInfo({ title: 'Open payments report error', error });
  }
  dispatch(setIsBusy(false));
  dispatch(setIsPrinting(false));
};

const saveAsCsvAsync = async (dispatch) => {
  dispatch(setIsBusy(true));
  dispatch(setIsExporting(true));
  try {
    const state = getState();
    const params = selectLastQueryParams(state);
    const response = await apiClient.get('/payments/report/csv', {
      params,
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'text/csv' });
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, '_blank');
    w?.focus();
  } catch (error) {
    showToastWithApiClientErrorInfo({ title: 'Save as CSV error', error });
  }
  dispatch(setIsBusy(false));
  dispatch(setIsExporting(false));
};

export {
  registerGetStateInPaymentsSlice,
  slice,
  reset,
  selectLastQueryParams,
  setLastQueryParams,
  selectAllRowsCount,
  selectSummary,
  loadData,
  openPaymentsReportAsync,
  setIsPrinting,
  selectIsPrinting,
  saveAsCsvAsync,
  setIsExporting,
  selectIsExporting,
};
export default slice.reducer;
