import axios from 'axios';
import env from '../../environment';

const createServiceClient = (baseURL, timeout) => {
  const config = {
    baseURL,
    timeout,
    withCredentials: true,
    xsrfCookieName: env.XSRF_COOKIE_NAME,
    xsrfHeaderName: env.XSRF_HEADER_NAME,
  };

  return axios.create(config);
};

const apiClient = createServiceClient(`/api/${env.USED_SERVER_API_VERSION}`, env.API_CLIENT_TIMEOUT);

export default apiClient;
