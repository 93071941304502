/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  useBoolean,
  Button,
  Divider,
  HStack,
  VStack,
  Spacer,
  Heading,
  Text,
  Input,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { includesSome } from '../utils';
import { selectAccountRoles } from '../features/session';
import { loadData, selectSummary, selectAllRowsCount } from '../features/spp-operations';
import { selectContractor, selectContractorView } from '../features/account';

const dateFormatMask = 'yyyy-mm-dd';

const SppOperationsFilterBar = ({ filter, setFilter, admin }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const handleClearFilters = () => {
    setUseFromDate.off();
    setUseToDate.off();
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setFilter([]);
  };

  const handleApplyFilters = () => {
    const newFilter = [];

    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    if (useFromDate || useToDate) {
      newFilter.push({
        name: 'createdAt',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    setFilter(newFilter);
  };

  const handleTodayClick = () => {
    const now = Date.now();
    const fromDate = dateFormat(Date.parse(now), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentWeekClick = () => {
    const now = Date.now();
    let day = new Date().getDay();
    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentMonthClick = () => {
    const now = Date.now();
    const day = new Date().getDate() - 1;
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handlePreviousMonthClick = () => {
    const date = new Date();
    const now = Date.now();
    const dayTo = new Date().getDate();
    const fromDate = dateFormat(Date.parse(new Date(date.getFullYear(), date.getMonth() - 1, 1)), dateFormatMask);
    const toDate = dateFormat(Date.parse(new Date(now - dayTo * 24 * 60 * 60 * 1000)), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.sppOperations.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
        {t('modules.sppOperations.FromDate', 'From date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        disabled={!useFromDate}
        minWidth={196}
        type="date"
        size="xs"
        value={dateFrom}
        onChange={(event) => setDateFrom(event.currentTarget.value)}
      />
      <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
        {t('modules.sppOperations.ToDate', 'To date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        minWidth={196}
        disabled={!useToDate}
        size="xs"
        type="date"
        value={dateTo}
        onChange={(event) => setDateTo(event.currentTarget.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={204} onClick={handleApplyFilters}>
        {t('modules.sppOperations.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={204} onClick={handleClearFilters}>
        {t('modules.sppOperations.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.sppOperations.Index', 'No'),
    textAlign: 'end',
    maxWidth: 75,
    sortable: false,
  },
  {
    name: 'createdAt',
    header: i18n.t('modules.sppOperations.DateTime', 'Date time'),
    allowUnsort: false,
    minWidth: 175,
  },
  {
    name: 'value',
    type: 'number',
    header: i18n.t('modules.sppOperations.Value', 'Gross value [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
  { name: 'rfidTag', header: i18n.t('modules.sppOperations.RFIDTag', 'Card/pedant No') },
  {
    name: 'contractorId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.sppOperations.ContractorId', 'Contractor Id'),
  },
  {
    name: 'contractor',
    defaultFlex: 2,
    header: i18n.t('modules.sppOperations.Contractor', 'Contractor'),
  },
  {
    name: 'invoiceNo',
    header: i18n.t('modules.sppOperations.InvoiceNo', 'Invoice no'),
  },
  {
    name: 'description',
    header: i18n.t('modules.sppOperations.Description', 'Description'),
    defaultFlex: 2,
  },
];
// eslint-disable-next-line react/prop-types
const SppOperations = ({ height, contractorId, upAdmin }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const accountRoles = useSelector(selectAccountRoles);
  const dataSource = useCallback(loadData(dispatch)({ filter }), [contractorId, filter, dispatch]);
  const { loginContractor } = useSelector(selectContractor) ?? {};
  const { loginContractorView } = useSelector(selectContractorView) ?? {};
  const admin = includesSome(['admin', 'backoffice'], accountRoles) && loginContractor === loginContractorView;

  return (
    <VStack alignItems="stretch">
      <HStack alignItems="flex-start">
        <SppOperationsFilterBar admin={admin} filter={filter} setFilter={setFilter} contractorId={contractorId} />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'createdAt', dir: -1 }}
          allowUnsort={false}
          idProperty="index"
        />
      </HStack>
    </VStack>
  );
};
export default SppOperations;
