/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  useBoolean,
  Button,
  Divider,
  HStack,
  VStack,
  Spacer,
  Heading,
  Text,
  Input,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { includesSome } from '../utils';
import { selectAccountRoles } from '../features/session';
import {
  loadData,
  setSelectedId,
  selectSortInfo,
  selectSelectedId,
  openInvoiceAsync,
  selectLoadedDataExists,
  openInvoicesReportAsync,
  selectSummary,
  selectAllRowsCount,
  deleteLastInvoiceAsync,
} from '../features/invoices';
import { selectContractor, selectContractorView } from '../features/account';

const dateFormatMask = 'yyyy-mm-dd';

const InvoicesFilterBar = ({ filter, setFilter, contractorId, admin, reload, setReload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [invoiceNo, setInvoiceNo] = useState('');
  const [contractorName, setContractorName] = useState('');
  const [source, setSource] = useState('');
  const sortInfo = useSelector(selectSortInfo);
  const loadedDataExists = useSelector(selectLoadedDataExists);
  const selectedInvoiceId = useSelector(selectSelectedId);
  const handleClearFilters = () => {
    setUseFromDate.off();
    setUseToDate.off();
    setInvoiceNo('');
    setContractorName('');
    setSource('');
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setFilter([]);
  };

  const handleApplyFilters = () => {
    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    const newFilter = [];

    if (useFromDate || useToDate) {
      newFilter.push({
        name: 'invoiceDate',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    if (invoiceNo !== '') {
      newFilter.push({
        name: 'invoiceNo',
        operator: 'contains',
        type: 'string',
        value: invoiceNo,
      });
    }

    if (contractorName !== '') {
      newFilter.push({
        name: 'contractorName',
        operator: 'contains',
        type: 'string',
        value: contractorName,
      });
    }

    if (source !== '') {
      newFilter.push({
        name: 'invoiceSource',
        operator: 'contains',
        type: 'string',
        value: source,
      });
    }

    setFilter(newFilter);
  };
  const handleDownloadSelectedInvoiceClick = () => {
    dispatch(openInvoiceAsync({ invoiceId: selectedInvoiceId, contractorId }));
  };
  const handleDownloadInvoicesReportClick = () => {
    dispatch(openInvoicesReportAsync({ sortInfo, contractorId, filter }));
  };
  const handleDeleteLastInvoiceClick = () => {
    dispatch(deleteLastInvoiceAsync({ reload, setReload }));
  };
  const handleTodayClick = () => {
    setInvoiceNo('');
    setContractorName('');
    setSource('');
    const now = Date.now();
    const fromDate = dateFormat(Date.parse(now), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'invoiceDate',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentWeekClick = () => {
    setInvoiceNo('');
    setContractorName('');
    setSource('');
    const now = Date.now();
    let day = new Date().getDay();
    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'invoiceDate',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentMonthClick = () => {
    setInvoiceNo('');
    setContractorName('');
    setSource('');
    const now = Date.now();
    const day = new Date().getDate() - 1;
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'invoiceDate',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handlePreviousMonthClick = () => {
    setInvoiceNo('');
    setContractorName('');
    setSource('');
    const date = new Date();
    const now = Date.now();
    const dayTo = new Date().getDate();
    const fromDate = dateFormat(Date.parse(new Date(date.getFullYear(), date.getMonth() - 1, 1)), dateFormatMask);
    const toDate = dateFormat(Date.parse(new Date(now - dayTo * 24 * 60 * 60 * 1000)), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'invoiceDate',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <HStack>
        <Tooltip label={t('modules.invoices.DownloadInvoicesReport', 'Download invoices report')}>
          <Button
            size={admin ? 'sm' : undefined}
            minWidth={107}
            colorScheme="brand"
            onClick={handleDownloadInvoicesReportClick}
            disabled={!loadedDataExists}
          >
            {t('modules.invoices.Report', 'Report')}
          </Button>
        </Tooltip>
        <Tooltip label={t('modules.invoices.DownloadSelectedInvoice', 'Download selected invoice')}>
          <Button
            size={admin ? 'sm' : undefined}
            minWidth={107}
            colorScheme="brand"
            onClick={handleDownloadSelectedInvoiceClick}
            disabled={selectedInvoiceId == null}
          >
            {t('modules.invoices.Show', 'Show')}
          </Button>
        </Tooltip>
      </HStack>
      {admin && (
        <Tooltip label={t('modules.invoices.DeleteLastTooltip', 'Delete last invoice from system')}>
          <Button
            size="sm"
            minWidth={222}
            colorScheme="silver"
            onClick={handleDeleteLastInvoiceClick}
            disabled={!loadedDataExists}
          >
            {t('modules.invoices.DeleteLast', 'Delete last')}
          </Button>
        </Tooltip>
      )}
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.invoices.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <HStack>
        <Button colorScheme="brand" minWidth={75} size="sm" onClick={handleTodayClick}>
          {t('modules.invoices.Today', 'Today')}
        </Button>
        <Button colorScheme="brand" size="sm" minWidth={138} onClick={handleCurrentWeekClick}>
          {t('modules.invoices.CurrentWeek', 'Current week')}
        </Button>
      </HStack>
      <Button minWidth={222} colorScheme="brand" size="sm" onClick={handleCurrentMonthClick}>
        {t('modules.invoices.CurrentMonth', 'Current month')}
      </Button>
      <Button minWidth={222} colorScheme="brand" size="sm" onClick={handlePreviousMonthClick}>
        {t('modules.invoices.PreviousMonth', 'Previous month')}{' '}
      </Button>
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
        {t('modules.invoices.FromDate', 'From date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        disabled={!useFromDate}
        minWidth={196}
        type="date"
        size="xs"
        value={dateFrom}
        onChange={(event) => setDateFrom(event.currentTarget.value)}
      />
      <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
        {t('modules.invoices.ToDate', 'To date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        minWidth={196}
        disabled={!useToDate}
        size="xs"
        type="date"
        value={dateTo}
        onChange={(event) => setDateTo(event.currentTarget.value)}
      />
      <Divider orientation="horizontal" />
      <Text size="xs">{t('modules.invoices.InvoiceNo', 'Invoice No')}</Text>
      <Input
        minWidth={222}
        focusBorderColor="brand.500"
        size="xs"
        value={invoiceNo}
        onChange={(event) => setInvoiceNo(event.target.value)}
      />
      {admin && <Text size="xs">{t('modules.invoices.ContractorName', 'Contractor')}</Text>}
      {admin && (
        <Input
          minWidth={222}
          focusBorderColor="brand.500"
          size="xs"
          value={contractorName}
          onChange={(event) => setContractorName(event.target.value)}
        />
      )}
      <Text size="xs">{t('modules.invoices.Source', 'Source')}</Text>
      <Input
        minWidth={222}
        focusBorderColor="brand.500"
        size="xs"
        value={source}
        onChange={(event) => setSource(event.target.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={222} onClick={handleApplyFilters}>
        {t('modules.invoices.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={222} onClick={handleClearFilters}>
        {t('modules.invoices.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.invoices.Index', 'No'),
    textAlign: 'end',
    maxWidth: 75,
    sortable: false,
  },
  {
    name: 'invoiceId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.invoices.InvoiceId', 'Id'),
  },
  {
    name: 'invoiceNo',
    header: i18n.t('modules.invoices.InvoiceNo', 'Invoice Number'),
  },
  { name: 'invoiceDate', header: i18n.t('modules.invoices.InvoiceDate', 'Invoice Date'), allowUnsort: false },
  {
    name: 'contractorId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.invoices.ContractorId', 'Contractor Id'),
  },
  {
    name: 'contractorName',
    defaultFlex: 2,
    header: i18n.t('modules.invoices.ContractorName', 'Contractor'),
    defaultVisible: admin,
  },
  {
    name: 'invoiceSource',
    defaultFlex: 1,
    header: i18n.t('modules.invoices.Source', 'Source'),
    sortable: false,
  },
  {
    name: 'grossValue',
    type: 'number',
    header: i18n.t('modules.invoices.GrossValue', 'Gross [PLN]'),
    textAlign: 'end',
  },
  {
    name: 'taxValue',
    type: 'number',
    header: i18n.t('modules.invoices.TaxValue', 'Tax [PLN]'),
    textAlign: 'end',
  },
  {
    name: 'netValue',
    type: 'number',
    header: i18n.t('modules.invoices.NetValue', 'Net [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
];
const InvoicesSummary = () => {
  const { t } = useTranslation();
  const summary = useSelector(selectSummary);
  const count = useSelector(selectAllRowsCount);
  const { grossValue: grossSummary, netValue: netSummary, taxValue: taxSummary } = summary;
  return (
    <VStack>
      <HStack alignSelf="stretch">
        <Spacer />
        <Tooltip
          label={`${t('modules.invoices.SummaryInfoPrefix', 'Summary contains sum of all')} ${count} ${t(
            'modules.invoices.SummaryInfoPostfix',
            'rows',
          )}`}
        >
          <HStack alignSelf="stretch">
            <Heading size="sm">{t('modules.invoices.Summary', 'Summary [PLN]:')}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.invoices.GrossSummary', 'gross:')}</Heading>
            <Heading size="md">{(grossSummary ?? 0).toFixed(2)}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.invoices.TaxSummary', 'tax:')}</Heading>
            <Heading size="md">{(taxSummary ?? 0).toFixed(2)}</Heading>
            <Spacer />
            <Heading size="sm">{t('modules.invoices.NetSummary', 'net:')}</Heading>
            <Heading size="md">{(netSummary ?? 0).toFixed(2)}</Heading>
          </HStack>
        </Tooltip>
      </HStack>
    </VStack>
  );
};
// eslint-disable-next-line react/prop-types
const Invoices = ({ height, contractorId, upAdmin }) => {
  const [reload, setReload] = useState(0);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const onSelectedIdChange = useCallback((selectedId) => {
    dispatch(setSelectedId(selectedId));
  }, []);
  const accountRoles = useSelector(selectAccountRoles);
  const dataSource = useCallback(loadData(dispatch)({ contractorId, filter }), [
    contractorId,
    filter,
    dispatch,
    reload,
  ]);
  const selectedInvoiceId = useSelector(selectSelectedId);
  const { login } = useSelector(selectContractorView) ?? {};
  const admin = upAdmin === false ? includesSome(['admin', 'backoffice'], accountRoles) && login == null : true;

  return (
    <VStack alignItems="stretch">
      <HStack alignItems="flex-start">
        <InvoicesFilterBar
          admin={admin}
          filter={filter}
          setFilter={setFilter}
          contractorId={contractorId}
          reload={reload}
          setReload={setReload}
        />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          onSelectedIdChange={onSelectedIdChange}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'invoiceDate', dir: -1 }}
          allowUnsort={false}
          idProperty="invoiceId"
          selectedId={selectedInvoiceId}
        />
      </HStack>
      <InvoicesSummary />
    </VStack>
  );
};
export default Invoices;
