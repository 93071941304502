const translations = {
  Index: 'L.p.',
  FromDate: 'Od daty',
  ToDate: 'Do daty',
  Refresh: 'Odśwież',
  RefreshBy: 'Odśwież wg zadanego zakresu dat',
  OrderBy: 'Sortuj według',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Date: 'Data',
  DateTime: 'Data czas',
  Head: 'Head',
  Task: 'Task',
  OperType: 'Oper type',
  OperData: 'Oper data',
  TransactionType: 'Typ transakcji',
  AmountsToPay: 'Kwoty do zapłaty',
  TransactionNo: 'Numer STAN transakcji',
  AuthorizationCode: 'Kod autoryzacji',
  OperatorCode: 'Kod operatora',
  TransactionDateTime: 'Data i czas wykonania transakcji',
  TerminalId: 'Identyfikator terminala (TID)',
  PaymentCardNo: 'Zamaskowany numer karty płatniczej',
  AuthorizationType: 'Sposób autoryzacji posiadacza karty',
  PaymentCardType: 'Rodzaj użytej karty',
  Authorizor: 'Podmiot realizujący autoryzacje posiadacza karty',
  TransactionProcessingType: 'Tryb przetwarzania transakcji',
  Balance: 'Stan zbilansowania salda dla raportu zamknięcia dnia',
  PaymentCardName: 'Nazwa karty',
  PaymentTitle: 'Tytuł płatności',
  TransactionFlag: 'Flaga transakcji',
  AdditionalTransactionAmount: 'Dodatkowa kwota transakcji',
  AdditionalPaymentOptions: 'Dodatkowe opcje płatności',
  LogId: 'Identyfikator loga źródłowego',
  OnlyEndsTransactions: 'Zakończenia transakcji',
};

export default translations;
