import {
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  MESSAGE_DIALOG_TYPE,
  selectMessageDialogProps,
  selectMessageDialogIsOpen,
  setMessageDialogIsOpen,
  resetMessageDialogProps,
  MESSAGE_DIALOG_BUTTONS,
} from '../../features/message-dialog';

const buttonMargin = 2;
const typeToTitle = (type) => {
  switch (type) {
    case MESSAGE_DIALOG_TYPE.INFORMATION:
      return 'Information';
    case MESSAGE_DIALOG_TYPE.CONFIRMATION:
      return 'Confirm';
    case MESSAGE_DIALOG_TYPE.WARNING:
      return 'Warning';
    case MESSAGE_DIALOG_TYPE.ERROR:
      return 'Error';
    default:
      return 'Information';
  }
};

const MessageDialogButton = (props) => <Button size="sm" minWidth={75} {...props} />;

const MessageDialog = () => {
  const { title, type, text, buttons, actions } = useSelector(selectMessageDialogProps);
  const isOpen = useSelector(selectMessageDialogIsOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setMessageDialogIsOpen(false));
    dispatch(resetMessageDialogProps);
    if (actions.close) {
      dispatch(actions.close);
    }
  };

  const handleOk = () => {
    if (actions.ok) {
      dispatch(actions.ok);
    }

    handleClose();
  };

  const handleCancel = () => {
    if (actions.cancel) {
      dispatch(actions.cancel);
    }

    handleClose();
  };

  const handleYes = () => {
    if (actions.yes) {
      dispatch(actions.yes);
    }

    handleClose();
  };

  const handleNo = () => {
    if (actions.no) {
      dispatch(actions.no);
    }

    handleClose();
  };

  const handleEsc = () => {
    // eslint-disable-next-line default-case
    switch (buttons) {
      case MESSAGE_DIALOG_BUTTONS.OK_CANCEL:
      case MESSAGE_DIALOG_BUTTONS.YES_NO_CANCEL:
        handleCancel();
        return;
      case MESSAGE_DIALOG_BUTTONS.YES_NO:
        return;
    }
    handleClose();
  };
  const titleFromType = typeToTitle(type);
  const { t } = useTranslation();
  const reallyTitle = title ?? t(`components.ui.message-dialog.${titleFromType}`, titleFromType);

  return (
    <Box zIndex="messageDialog">
      <AlertDialog isOpen={isOpen} isCentered closeOnOverlayClick onEsc={handleEsc}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {reallyTitle}
            </AlertDialogHeader>
            <Divider />
            <Box my={0}>
              <AlertDialogBody>{text}</AlertDialogBody>
            </Box>
            <Divider />

            <AlertDialogFooter>
              {buttons === MESSAGE_DIALOG_BUTTONS.OK && (
                <MessageDialogButton onClick={handleOk}>
                  {t('components.ui.message-dialog.Ok', 'Ok')}
                </MessageDialogButton>
              )}
              {buttons === MESSAGE_DIALOG_BUTTONS.OK_CANCEL && (
                <>
                  <MessageDialogButton onClick={handleOk}>
                    {t('components.ui.message-dialog.Ok', 'Ok')}
                  </MessageDialogButton>
                  <MessageDialogButton ml={buttonMargin} onClick={handleCancel}>
                    {t('components.ui.message-dialog.Cancel', 'Cancel')}
                  </MessageDialogButton>
                </>
              )}
              {buttons === MESSAGE_DIALOG_BUTTONS.YES_NO && (
                <>
                  <MessageDialogButton onClick={handleYes}>
                    {t('components.ui.message-dialog.Yes', 'Yes')}
                  </MessageDialogButton>
                  <MessageDialogButton ml={buttonMargin} onClick={handleNo}>
                    {t('components.ui.message-dialog.No', 'No')}
                  </MessageDialogButton>
                </>
              )}
              {buttons === MESSAGE_DIALOG_BUTTONS.YES_NO_CANCEL && (
                <>
                  <MessageDialogButton onClick={handleYes}>
                    {t('components.ui.message-dialog.Yes', 'Yes')}
                  </MessageDialogButton>
                  <MessageDialogButton mx={buttonMargin} onClick={handleNo}>
                    {t('components.ui.message-dialog.No', 'No')}
                  </MessageDialogButton>
                  <MessageDialogButton onClick={handleCancel}>
                    {t('components.ui.message-dialog.Cancel', 'Cancel')}
                  </MessageDialogButton>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default MessageDialog;
