/* eslint-disable react/prop-types */
import { useBoolean, Button, Divider, HStack, VStack, Spacer, Heading, Text, Input, Checkbox } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { loadData } from '../features/operations';
import env from '../../environment';
import { openInvoiceAsync } from '../features/invoices';

const dateFormatMask = 'yyyy-mm-dd';

const defaultColumns = [
  {
    name: 'createdAt',
    header: i18n.t('modules.operations.OperationDate', 'Start'),
    allowUnsort: false,
    defaultWidth: 175,
  },
  {
    name: 'typeDescription',
    header: i18n.t('modules.operations.TypeDescription', 'Operation/transaction kind'),
    allowUnsort: false,
    sortable: false,
    defaultWidth: 200,
  },
  {
    name: 'value',
    type: 'number',
    header: i18n.t('modules.operations.Value', 'Gross value [PLN]'),
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'rfidDesc',
    defaultFlex: 1,
    header: i18n.t('modules.operations.RFIDDesc', 'User'),
    sortable: false,
  },
  { name: 'rfidTag', header: i18n.t('modules.operations.RFIDTag', 'Card/pedant No'), sortable: false },
  {
    name: 'source',
    header: i18n.t('modules.operations.Source', 'Source'),
    sortable: false,
    defaultVisible: false,
  },
  {
    name: 'invoiceNo',
    header: i18n.t('modules.operations.InvoiceNo', 'Invoice no'),
    sortable: false,
  },
  {
    name: 'invoiceId',
    header: i18n.t('modules.operations.InvoiceId', 'Invoice id'),
    sortable: false,
    defaultVisible: false,
  },
  {
    name: 'operationDescription',
    header: i18n.t('modules.operations.OperationDescription', 'Description'),
    allowUnsort: false,
    sortable: false,
    defaultFlex: 1,
  },
  {
    name: 'id',
    header: i18n.t('modules.operations.operationId', 'Id'),
    defaultVisible: false,
  },
];

const OperationsFilterBar = ({ setFilter }) => {
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [topUp, setTopUp] = useBoolean();
  const [chargeRfid, setChargeRfid] = useBoolean();
  const [adjustment, setAdjustment] = useBoolean();
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [invoiceNo, setInvoiceNo] = useState('');
  const [rfidTag, setRfidTag] = useState('');
  const [rfidDesc, setRfidDesc] = useState('');
  const [source, setSource] = useState('');
  const handleClearFilters = () => {
    setTopUp.off();
    setChargeRfid.off();
    setAdjustment.off();
    setUseFromDate.off();
    setUseToDate.off();
    setInvoiceNo('');
    setRfidTag('');
    setRfidDesc('');
    setSource('');
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setFilter([]);
  };

  const handleTodayClick = () => {
    setTopUp.off();
    setChargeRfid.off();
    setAdjustment.off();
    setInvoiceNo('');
    setRfidTag('');
    setRfidDesc('');
    setSource('');
    const now = Date.now();
    const fromDate = dateFormat(Date.parse(now), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentWeekClick = () => {
    setTopUp.off();
    setChargeRfid.off();
    setAdjustment.off();
    setInvoiceNo('');
    setRfidTag('');
    setRfidDesc('');
    setSource('');
    const now = Date.now();
    let day = new Date().getDay();
    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentMonthClick = () => {
    setTopUp.off();
    setChargeRfid.off();
    setAdjustment.off();
    setInvoiceNo('');
    setRfidTag('');
    setRfidDesc('');
    setSource('');
    const now = Date.now();
    const day = new Date().getDate() - 1;
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handlePreviousMonthClick = () => {
    setTopUp.off();
    setChargeRfid.off();
    setAdjustment.off();
    setInvoiceNo('');
    setRfidTag('');
    setRfidDesc('');
    setSource('');
    const date = new Date();
    const now = Date.now();
    const dayTo = new Date().getDate();
    const fromDate = dateFormat(Date.parse(new Date(date.getFullYear(), date.getMonth() - 1, 1)), dateFormatMask);
    const toDate = dateFormat(Date.parse(new Date(now - dayTo * 24 * 60 * 60 * 1000)), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleApplyFilters = () => {
    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    const typeValue = [];
    if (topUp) {
      typeValue.push(9);
    }

    if (chargeRfid) {
      typeValue.push(6);
    }

    if (adjustment) {
      typeValue.push(11);
    }

    const filter = [];

    if (useFromDate || useToDate) {
      filter.push({
        name: 'createdAt',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    if (topUp || chargeRfid || adjustment) {
      filter.push({
        name: 'typeId',
        operator: 'inlist',
        type: 'number',
        value: typeValue,
      });
    }

    if (invoiceNo !== '') {
      filter.push({
        name: 'invoiceNo',
        operator: 'contains',
        type: 'string',
        value: invoiceNo,
      });
    }

    if (rfidTag !== '') {
      filter.push({
        name: 'rfidTag',
        operator: 'contains',
        type: 'string',
        value: rfidTag,
      });
    }

    if (rfidDesc !== '') {
      filter.push({
        name: 'rfidDesc',
        operator: 'contains',
        type: 'string',
        value: rfidDesc,
      });
    }

    if (source !== '') {
      filter.push({
        name: 'source',
        operator: 'contains',
        type: 'string',
        value: source,
      });
    }

    setFilter(filter);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.operations.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <HStack>
        <Button colorScheme="brand" minWidth={100} size="sm" onClick={handleTodayClick}>
          {t('modules.invoices.Today', 'Today')}
        </Button>
        <Button colorScheme="brand" size="sm" minWidth={170} onClick={handleCurrentWeekClick}>
          {t('modules.operations.CurrentWeek', 'Current week')}
        </Button>
      </HStack>
      <Button minWidth={277} colorScheme="brand" size="sm" onClick={handleCurrentMonthClick}>
        {t('modules.operations.CurrentMonth', 'Current month')}
      </Button>
      <Button minWidth={277} colorScheme="brand" size="sm" onClick={handlePreviousMonthClick}>
        {t('modules.operations.PreviousMonth', 'Previous month')}{' '}
      </Button>
      <Divider orientation="horizontal" />
      <HStack>
        <VStack alignItems="flex-start">
          <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
            {t('modules.operations.FromDate', 'Start from')}
          </Checkbox>
          <Input
            width={135}
            focusBorderColor="brand.500"
            disabled={!useFromDate}
            type="date"
            size="xs"
            value={dateFrom}
            onChange={(event) => setDateFrom(event.currentTarget.value)}
          />
        </VStack>
        <VStack alignItems="flex-start">
          <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
            {t('modules.operations.ToDate', 'Start to')}
          </Checkbox>
          <Input
            width={135}
            focusBorderColor="brand.500"
            disabled={!useToDate}
            size="xs"
            type="date"
            value={dateTo}
            onChange={(event) => setDateTo(event.currentTarget.value)}
          />
        </VStack>
      </HStack>
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={topUp} onChange={setTopUp.toggle}>
        Doładowanie konta
      </Checkbox>
      <Checkbox colorScheme="brand" isChecked={chargeRfid} onChange={setChargeRfid.toggle}>
        Płatność kartą
      </Checkbox>

      <Checkbox colorScheme="brand" isChecked={adjustment} onChange={setAdjustment.toggle}>
        Korekta salda
      </Checkbox>
      <Divider orientation="horizontal" />
      {!env.GROUP_CONTRACTOR_OPERATIONS && (
        <>
          <Text size="xs">{t('modules.operations.InvoiceNo', 'Invoice No')}</Text>
          <Input
            focusBorderColor="brand.500"
            size="xs"
            value={invoiceNo}
            onChange={(event) => setInvoiceNo(event.target.value)}
          />
        </>
      )}
      <Text size="xs">{t('modules.operations.RFIDTag', 'User Id')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="xs"
        value={rfidTag}
        onChange={(event) => setRfidTag(event.target.value)}
      />
      <Text size="xs">{t('modules.operations.RFIDDesc', 'User description')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="xs"
        value={rfidDesc}
        onChange={(event) => setRfidDesc(event.target.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <HStack alignSelf="stretch">
        <Button minWidth={135} colorScheme="gray" onClick={handleClearFilters}>
          {t('modules.operations.ClearFilters', 'Clear filters')}
        </Button>
        <Button minWidth={135} colorScheme="brand" onClick={handleApplyFilters}>
          {t('modules.operations.ApplyFilters', 'Apply filters')}
        </Button>
      </HStack>
    </VStack>
  );
};

const rowStyle = ({ data }) => ({
  color: data.typeId !== 6 && data.value > 0 ? 'green' : null,
});

// eslint-disable-next-line react/prop-types
const Operations = ({ height, contractorId }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const dataSource = useCallback(loadData(dispatch)({ contractorId, filter }), [dispatch, contractorId, filter]);
  const onRowDoubleClick = useCallback((rowProps) => {
    if (rowProps.data?.invoiceId != null) {
      dispatch(openInvoiceAsync({ invoiceId: rowProps.data?.invoiceId, contractorId }));
    }
  }, []);
  return (
    <HStack alignItems="flex-start">
      <OperationsFilterBar setFilter={setFilter} maxHeight={height} />
      <Divider orientation="vertical" />
      <DataGrid
        height={height}
        dataSource={dataSource}
        columns={defaultColumns}
        pagination="remote"
        skip={0}
        limit={100}
        sortInfo={{ name: 'createdAt', dir: -1 }}
        allowUnsort={false}
        onRowDblClick={onRowDoubleClick}
        idProperty="id"
        rowStyle={rowStyle}
        enableSelection={false}
      />
    </HStack>
  );
};
export default Operations;
