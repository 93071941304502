import PropTypes from 'prop-types';
import { useColorMode, useColorModeValue, IconButton, Tooltip } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ColorModeSwitcher = ({ size }) => {
  const { toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const tooltipKey = useColorModeValue(
    'components.ui.color-mode-switcher.dark.tooltip',
    'components.ui.color-mode-switcher.light.tooltip',
  );
  const tooltipDefaultValue = useColorModeValue('switch to dark theme', 'switch to light theme');
  return (
    <Tooltip label={t(tooltipKey, tooltipDefaultValue)}>
      <IconButton color="current" onClick={toggleColorMode} icon={<SwitchIcon />} variant="ghost" size={size} />
    </Tooltip>
  );
};

ColorModeSwitcher.defaultProps = {
  size: 'md',
};

ColorModeSwitcher.propTypes = {
  size: PropTypes.string,
};

export default ColorModeSwitcher;
