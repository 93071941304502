import { createSlice } from '@reduxjs/toolkit';
import apiClient from './api-client';
import { setIsBusy } from './app';
import { showToastWithApiClientErrorInfo } from './toast';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInReportSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'report',
  initialState: {
    contractorId: null,
    allRowsCount: 0,
    summary: { receipts: 0, expenses: 0, balance: 0 },
  },
  reducers: {
    setContractorId: (state, action) => {
      const stateRef = state;
      stateRef.contractorId = action.payload;
    },
    setAllRowsCount: (state, action) => {
      const stateRef = state;
      stateRef.allRowsCount = action.payload;
    },
    setSummary: (state, action) => {
      const stateRef = state;
      stateRef.summary = action.payload;
    },
    reset: (state) => {
      const stateRef = state;
      stateRef.contractorId = null;
      stateRef.allRowsCount = 0;
      stateRef.summary = { receipts: 0, expenses: 0, balance: 0 };
    },
  },
});

const { setContractorId, setAllRowsCount, setSummary, reset } = slice.actions;

const selectContractorId = (state) => state.report.contractorId;
const selectAllRowsCount = (state) => state.report.allRowsCount;
const selectSummary = (state) => state.report.summary;

const loadData =
  (dispatch) =>
  ({ contractorId, filter: filterValue, groupBy }) =>
  ({ skip, limit, sortInfo }) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsBusy(true));

        const response = await apiClient.get(contractorId == null ? '/report' : `/contractors/${contractorId}/report`, {
          params: { limit, offset: skip, sortInfo, filterValue: { filterValue }, groupBy },
        });

        const { count, summary } = response.data.report;
        dispatch(setSummary(summary));
        dispatch(setAllRowsCount(count));
        dispatch(setContractorId(contractorId));
        dispatch(setIsBusy(false));
        resolve({
          data: response.data.report.rows.map((row, index) => ({
            ...row,
            index: index + 1,
            receipts: row.receipts.toFixed(2),
            expenses: row.expenses.toFixed(2),
            balance: row.balance.toFixed(2),
          })),
          count,
        });
      } catch (error) {
        dispatch(setIsBusy(false));
        reject(error);
      }
    });

const openContractorsFundsReportAsync = async (dispatch) => {
  dispatch(setIsBusy(true));
  try {
    const response = await apiClient.get('/contractors/funds/pdf', {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, '_blank');
    w?.focus();
  } catch (error) {
    showToastWithApiClientErrorInfo({ title: 'Open contractors funds report error', error });
  }
  dispatch(setIsBusy(false));
};

export {
  registerGetStateInReportSlice,
  slice,
  reset,
  selectContractorId,
  selectAllRowsCount,
  selectSummary,
  loadData,
  openContractorsFundsReportAsync,
};
export default slice.reducer;
