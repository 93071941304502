const translations = {
  Login: 'Użytkownik (adres e-mail)',
  'enter your new login name': 'wprowadź swoją nową nazwę użytkownika',
  'Mobile number': 'Nr telefonu komórkowego',
  'enter your new mobile number': 'wprowadź swój nowy numer telefonu komórkowego',
  submit: 'Zapisz zmiany',
  Submit: 'Prześlij',
  Resend: 'Nowy sms',
  SubmitTooltip: 'zweryfikuj otrzymany kod',
  ResendTooltip: 'prześlij mi nowy kod weryfikacyjny',
  'Enter verification code which we sent to your mobile':
    'Wprowadź kod weryfikacyjny, który przesłaliśmy na twoją komórkę',
  'Field "Login" remote validation error': 'Błąd walidacji pola "Użytkownik"',
  Message: 'Komunikat',
  'This login is already in use': 'Wybrana nazwa użytkownika jest zajęta',
  'A value in this field is required': 'Pole musi mieć wartość',
  'Mobile number has incorrect format': 'Numer telefonu komórkowego ma nieprawidłowy format',
  warning: 'UWAGA: Po zmianie nazwy Użytkownika konieczne będzie ponowne zalogowanie się do aplikacji',
  LoginMustBeEmail: 'Użytkownik musi być prawidłowym adresem e-mail',
};

export default translations;
