import {
  home,
  /* shop, */ rfidCards,
  invoices,
  adminPanel,
  account,
  funds,
  privacyPolicy,
  regulations,
} from './pages-translations';
import {
  session,
  account as featuresAccount,
  rfidCards as featuresRfidCards,
  stripe as featuresStripe,
  invoices as featuresInvoices,
  userAccounts as featuresUserAccounts,
  devices as featuresDevices,
} from './features-translations';
import {
  invoices as invoicesModule,
  transactions,
  rfidCards as rfidCardsModule,
  operations,
  report,
  payments,
  sppLogs,
  sppOperations,
  sppSalesEverything,
  userAccounts as userAccountsModule,
  devices as devicesModule,
} from './modules-translations';

const translations = {
  'We are sorry, page not found!': 'Przepraszamy, nie znaleziono strony!',
  'The page you are looking for might have been removed had its name changed or is temporarily unavailable':
    'Strona której szukasz mogła zostać usunięta, zmieniono jej nazwę, lub jest tymczasowo niedostępna',
  'Please wait...': 'Proszę czekać...',
  'Cookie consent': 'Zgoda na używanie ciasteczek',
  'By continuing to use the website, you consent to the use of cookies':
    'Pozostając na tej stronie, wyrażasz zgodę na korzystanie z plików cookie',
  pages: {
    Home: 'Historia',
    // Shop: 'Sklep',
    Funds: 'Doładowania',
    'RFID Cards': 'Użytkownicy',
    Invoices: 'Faktury',
    'Admin panel': 'Administrator',
    home,
    // shop,
    funds,
    rfidCards,
    invoices,
    adminPanel,
    account,
    regulations,
    privacyPolicy,
    'sign-in': {
      'Sign In': 'Logowanie',
      Login: 'Użytkownik (adres e-mail)',
      'enter your login name': 'wprowadź swój adres e-mail',
      Password: 'Hasło',
      'enter your password': 'wprowadź swoje hasło',
      'Do not have account': 'Nie masz konta? Utwórz je!',
      'Forgot your password': 'Nie pamiętasz hasła?',
      submit: 'Zaloguj',
      'A value in this field is required': 'Pole musi mieć wartość',
      LoginMustBeEmail: 'Użytkownik musi być prawidłowym adresem e-mail',
    },
    'sign-up': {
      'Sign Up': 'Rejestracja',
      Login: 'Użytkownik (adres e-mail)',
      'enter your login name': 'wprowadź swój adres e-mail',
      Password: 'Hasło',
      'enter your password': 'wprowadź swoje hasło',
      'Password Confirm': 'Potwierdź hasło',
      're-enter your new password': 'wprowadź ponownie swoje hasło',
      'Mobile number': 'Nr telefonu komórkowego',
      'enter your mobile number': 'wprowadź swój numer telefonu komórkowego',
      submit: 'Zarejestruj',
      Submit: 'Prześlij',
      Resend: 'Nowy sms',
      SubmitTooltip: 'zweryfikuj otrzymany kod',
      ResendTooltip: 'prześlij mi nowy kod weryfikacyjny',
      'Enter verification code which we sent to your mobile':
        'Wprowadź kod weryfikacyjny, który przesłaliśmy na twoją komórkę',
      'Field "Login" remote validation error': 'Błąd walidacji pola "Użytkownik"',
      Message: 'Komunikat',
      'This login is already in use': 'Wybrana nazwa użytkownika jest zajęta',
      'A value in this field is required': 'Pole musi mieć wartość',
      'Password and Password confirm must have same values':
        'Hasło i potwierdzenie hasła muszą mieć takie same wartości',
      'Mobile number has incorrect format': 'Numer telefonu komórkowego ma nieprawidłowy format',
      LoginMustBeEmail: 'Użytkownik musi być prawidłowym adresem e-mail',
    },
    'password-change': {
      'Password Change': 'Zmiana hasła',
      Login: 'Użytkownik (adres e-mail)',
      'enter your login name': 'wprowadź swój adres e-mail',
      Password: 'Nowe hasło',
      'enter your password': 'wprowadź swoje nowe hasło',
      'Password Confirm': 'Potwierdź nowe hasło',
      're-enter your new password': 'wprowadź ponownie swoje nowe hasło',
      submit: 'Zmień',
      Submit: 'Prześlij',
      Resend: 'Nowy sms',
      SubmitTooltip: 'zweryfikuj otrzymany kod',
      ResendTooltip: 'prześlij mi nowy kod weryfikacyjny',
      'Enter verification code which we sent to your mobile':
        'Wprowadź kod weryfikacyjny, który przesłaliśmy na twoją komórkę',
      'Field "Login" remote validation error': 'Błąd walidacji pola "Użytkownik"',
      Message: 'Komunikat',
      'Account for selected login does not exists': 'Konto dla podanej nazwy użytkownika nie istnieje',
      'A value in this field is required': 'Pole musi mieć wartość',
      'Password and Password confirm must have same values':
        'Hasło i potwierdzenie hasła muszą mieć takie same wartości',
      LoginMustBeEmail: 'Użytkownik musi być prawidłowym adresem e-mail',
    },
  },
  components: {
    sections: {
      'landing-header': {
        'popover-trigger': 'Zalogowany użytkownik',
        ContractorView: 'Widok kontrahenta',
        AvailableFunds: 'Dostępne środki',
        AvailableFundsContractorView: 'Dostępne środki (widok kontrahenta)',
        EnableContractorView: 'Włącz widok kontrahenta',
        DisableContractorView: 'Wyłącz widok kontrahenta',
        'popover-footer': 'Aby zamknąć to okienko kliknij ponownie przycisk z nazwą użytkownika',
        'manage-button': 'Zarządzaj',
        'logout-button': 'Wyloguj',
        PaymentMethod: 'Forma płatności',
        Card: 'Karta kredytowa',
        P24: 'Serwis Przelewy24',
        'top-up-menu-close': 'Zamknij bez doładowania konta',
      },
    },
    ui: {
      'lang-switcher': { 'en-tooltip': 'przełącz na angielski', 'pl-tooltip': 'przełącz na angielski' },
      'color-mode-switcher': { 'dark.tooltip': 'przełącz na ciemny motyw', 'light.tooltip': 'przełącz na jasny motyw' },
      copyright: { Copyright: 'Prawa autorskie' },
      'server-info': { ver: 'wer.' },
      'message-dialog': {
        Information: 'Informacja',
        Confirm: 'Potwierdź',
        Warning: 'Ostrzeżenie',
        Error: 'Błąd',
        Yes: 'Tak',
        No: 'Nie',
        Cancel: 'Anuluj',
        Ok: 'Ok',
      },
      stripe: {
        Close: 'Zamknij',
        'Pay now': 'Zapłać teraz',
        PaymentSucceed:
          'Płatność zakończyła się sukcesem. Przejdź do zakładki "Faktury" jeżeli chcesz poznać szczegóły, lub kliknij poniższy link aby pobrać fakturę w formacie pdf',
        Invoice: 'Faktura',
        EmailAddress: 'Adres e-mail',
        EmailAddressIsRequired: 'Wartość w polu Adres e-mail jest wymagana',
        P24Consent1: 'Oświadczam, że zapoznałem się z',
        P24Consent2: 'regulaminem',
        P24Consent3: 'i',
        P24Consent4: 'obowiązkiem informacyjnym',
        P24Consent5: 'serwisu Przelewy24.',
      },
      lov: { Cancel: 'Anuluj' },
      lovTable: {
        GlobalFilterLabel: 'Szukaj',
        GlobalFilterTooltip:
          'Wpisz szukaną wartość aby przefiltrować listę wartości (fragment nazwy, numeru nip, lub adresu)',
        GlobalFilterPlaceholder: 'Szukana wartość',
      },
    },
  },
  features: {
    session,
    account: featuresAccount,
    rfidCards: featuresRfidCards,
    stripe: featuresStripe,
    invoices: featuresInvoices,
    userAccounts: featuresUserAccounts,
    devices: featuresDevices,
  },
  modules: {
    invoices: invoicesModule,
    transactions,
    rfidCards: rfidCardsModule,
    operations,
    report,
    payments,
    sppLogs,
    sppOperations,
    sppSalesEverything,
    userAccounts: userAccountsModule,
    devices: devicesModule,
  },
};

export default translations;
