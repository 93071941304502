/* eslint-disable react/prop-types */
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import '@inovua/reactdatagrid-community/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useColorMode } from '@chakra-ui/react';

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DataGrid = ({
  height,
  columns: defaultColumns,
  dataSource,
  pagination,
  skip: defaultSkip,
  limit: defaultLimit,
  sortInfo: defaultSortInfo,
  idProperty,
  allowUnsort,
  onSelectedIdChange,
  selectedId,
  filterValue: defaultFilterValue,
  rowStyle,
  onSelectedDataChange,
  onRowDblClick,
  enableSelection = true,
  groups,
}) => {
  const { colorMode } = useColorMode();
  const [columns] = useState(defaultColumns);
  const [skip, setSkip] = useState(defaultSkip);
  const [limit, setLimit] = useState(defaultLimit);
  const [sortInfo, setSortInfo] = useState(defaultSortInfo);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const onSelectionChange = useCallback(({ selected, data }) => {
    if (onSelectedIdChange) {
      onSelectedIdChange(selected);
    }

    if (onSelectedDataChange) {
      onSelectedDataChange(data);
    }
  }, []);
  let gridTranslations = { ...ReactDataGrid.defaultProps.i18n };
  let loadingText = 'Loading data please wait...';
  let emptyText = 'No records available';
  const { i18n } = useTranslation();
  if (i18n.language.startsWith('pl')) {
    loadingText = 'Ładowanie danych proszę czekać...';
    emptyText = 'Brak dostępnych rekordów';
    gridTranslations = {
      ...gridTranslations,
      ...{
        pageText: 'Strona ',
        ofText: ' z ',
        perPageText: 'Wyników na stronie',
        showingText: 'Wyświetlono ',
        clearAll: 'Wyczyść wszystko',
        clear: 'Wyczyść',
        showFilteringRow: 'Pokaż panel filtrowania',
        hideFilteringRow: 'Ukryj panel filtrowania',
        dragHeaderToGroup: 'Przeciągnij nagłówek żby zgrupować',
        disable: 'Wyłącz',
        enable: 'Włącz',
        sortAsc: 'Sortuj rosnąco',
        sortDesc: 'Sortuj malejąco',
        unsort: 'Bez sortowania',
        group: 'Grupuj',
        ungroup: 'Rozgrupuj',
        lockStart: 'Zablokuj na początku',
        lockEnd: 'Zablokuj an końcu',
        unlock: 'Odblokuj',
        columns: 'Kolumny',
        contains: 'Zawiera',
        startsWith: 'Zaczyna się od',
        endsWith: 'Kończy się na',
        notContains: 'Nie zawiera',
        neq: 'Nierówne',
        eq: 'Równe',
        notEmpty: 'Nie puste',
        empty: 'Puste',
        lt: 'Mniejsze',
        lte: 'Mniejsze lub równe',
        gt: 'Większe',
        gte: 'Większe lub równe',
        before: 'Przed',
        after: 'Po',
        beforeOrOn: 'Przed lub w dniu',
        afterOrOn: 'Po lub w dniu',
        inrange: 'Z przedziału',
        notinrange: 'Z poza przedziału',
        'calendar.todayButtonText': 'Dzisiaj',
        'calendar.clearButtonText': 'Wyczyść',
        'calendar.okButtonText': 'OK',
        'calendar.cancelButtonText': 'Anuluj',
      },
    };
  }

  const onRenderRow = useCallback((rowProps) => {
    // save the original handlers to be called later
    const { onDoubleClick } = rowProps;

    // eslint-disable-next-line no-param-reassign
    rowProps.onDoubleClick = (event) => {
      if (onRowDblClick) {
        onRowDblClick(rowProps);
      }

      if (onDoubleClick) {
        onDoubleClick(event);
      }
    };
  }, []);

  return (
    <ReactDataGrid
      style={{ minHeight: height }}
      idProperty={idProperty}
      columns={columns}
      sortInfo={sortInfo}
      onSortInfoChange={setSortInfo}
      onFilterValueChange={setFilterValue}
      limit={limit}
      skip={skip}
      onLimitChange={setLimit}
      onSkipChange={setSkip}
      dataSource={dataSource}
      theme={colorMode === 'light' ? 'default-light' : 'default-dark'}
      onSelectionChange={onSelectionChange}
      pagination={pagination}
      showColumnMenuTool={false}
      i18n={gridTranslations}
      loadingText={loadingText}
      allowUnsort={allowUnsort}
      emptyText={emptyText}
      enableSelection={enableSelection}
      selected={selectedId}
      filterValue={filterValue}
      columnUserSelect={true}
      rowStyle={rowStyle}
      onRenderRow={onRenderRow}
      groups={groups}
      reorderColumns={false}
    />
  );
};
export default DataGrid;
