/* eslint-disable max-len */
import { FaArrowLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import {
  Flex,
  Heading,
  Box,
  Center,
  OrderedList,
  ListItem,
  UnorderedList,
  Icon,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StretchBox from '../core/components/stretch-box';
import { selectIsSignedIn } from '../features/session';

const ParagraphNo = ({ number }) => (
  <Center m={4}>
    <Heading size="md">{`§ ${number}.`}</Heading>
  </Center>
);

ParagraphNo.propTypes = {
  number: PropTypes.number.isRequired,
};

const ParagraphHeader = ({ text }) => (
  <Center m={4}>
    <Heading size="md">{(text ?? '').toUpperCase()}</Heading>
  </Center>
);

const OList = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  return <OrderedList>{children}</OrderedList>;
};

const UList = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  return <UnorderedList>{children}</UnorderedList>;
};

const LItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  return <ListItem>{children}</ListItem>;
};

ParagraphHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const Regulations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isSignedIn = useSelector(selectIsSignedIn);
  return (
    <Flex grow="1" direction="column" justifyContent="stretch" p="4">
      <Button
        mb={4}
        colorScheme="brand"
        display={isSignedIn ? 'none' : 'block'}
        variant="outline"
        onClick={() => {
          history.push('/');
        }}
      >
        <HStack>
          <Icon as={FaArrowLeft} />
          <Heading size="md">{t('pages.privacyPolicy.BackToApplication', 'Go back to application')}</Heading>
        </HStack>
      </Button>
      <Heading>
        {t('pages.regulations.RegulationsTitle', 'Regulations of the application "client.speedwash.pl"')}
      </Heading>
      <StretchBox yOffset={isSignedIn ? 180 : 235}>
        <Box p={4} sx={{ 'overflow-y': 'scroll' }}>
          <ParagraphNo number={1} />
          <ParagraphHeader text="POSTANOWIENIA WSTĘPNE" />
          <OList>
            <LItem>
              Organizatorem oraz dostawcą Aplikacji jest IMEX RZESZÓW SP. Z O.O. 35-105 Rzeszów, ul. Przemysłowa 18, NIP:
              8133913896 (dalej określany jako „Organizator” lub „Imex”).
            </LItem>
            <LItem>
              Celem Aplikacji jest umożliwienie Użytkownikom korzystania z urządzeń dostępnych dla Użytkowników na
              samoobsługowej myjni samochodowej Speedwash w Rzeszowie(dalej zdefiniowanej jako Myjnia).
            </LItem>
            <LItem>
              Program jest organizowany i obowiązuje wyłącznie na myjni Speedwash w Rzeszowie ul. Przemysłowa 18.
            </LItem>
            <LItem>Program trwa od 1 marca 2022. do odwołania.</LItem>
          </OList>
          <ParagraphNo number={2} />
          <ParagraphHeader text="DEFINICJE" />
          <OList>
            <LItem>Pojęcia zapisane w Regulaminie od dużej litery mają następujące znaczenie: </LItem>
          </OList>
          <UList>
            <LItem>„Aplikacja” – aplikacja, za pomocą której Uczestnik uczestniczy w Programie.</LItem>
            <LItem>
              „Adres e-mail” - adres elektronicznej skrzynki pocztowej, adres e-mail pełni rolę loginu i wraz z hasłem
              służy do logowania się do Aplikacji i jej usług.
            </LItem>
            <LItem>
              „Użytkownik” - osoba fizyczna lub reprezentant osoby prawnej, która skutecznie aktywuje Konto Użytkownika.
            </LItem>
            <LItem>
              „ID Użytkownika” – unikalny kod przypisany do karty/breloka Użytkownika i jego Konta w ramach Programu od
              chwili rejestracji w Aplikacji; Uczestnik może posiadać w Programie maksymalnie 100 ID w tym samym czasie.
            </LItem>
            <LItem>
              „Konto użytkownika” – indywidualne konto Użytkownika prowadzone w systemie Organizatora i dostępne poprzez
              Aplikację po dokonaniu rejestracji, na którym zapisywane są informacje o działaniach i aktywnościach
              Uczestnika w ramach Programu, w tym o liczbie zgromadzonych środków pieniężnych.
            </LItem>
            <LItem>
              „Polityka Prywatności” – dokument dostępy pod adresem speedwash.pl opisujący w szczególności zasady
              przetwarzania danych osobowych Użytkowników w związku z Programem i korzystaniem z Aplikacji.
            </LItem>
            <LItem>„Regulamin” – niniejszy regulamin.</LItem>
          </UList>
          <ParagraphNo number={3} />
          <ParagraphHeader text="OPIS DZIAŁANIA KONTA UŻYTKOWNIKA" />
          <OList>
            <LItem>
              Poniższe warunki regulują korzystanie z Konta Użytkownika oraz usług oferowanych przez Administratora.
            </LItem>
            <LItem>
              Konto jest usługą, która umożliwia poprzez zalogowanie (podanie poprawnego loginu i hasła) dostęp do usług
              świadczonych przez Administratora na Myjni Speedwash.
            </LItem>
            <LItem>
              Usługi świadczone na Myjni, do których Użytkownik uzyskuje dostęp po założeniu Konta Użytkownika, opisane
              zostały w odpowiednich regulaminach.
            </LItem>
            <LItem>
              Zakończenie rejestracji i utworzenie Konta jest niezwłocznie potwierdzane przez Organizatora za pomocą
              wiadomości e-mail.
            </LItem>
            <LItem>
              W celu realizacji wybranych usług dostępnych na Myjni Speedwash niektóre dane mogą być zbierane od
              Użytkownika w trakcie używania Konta Użytkownika. Przekazanie dodatkowych danych jest dobrowolne, a
              informacje o celu ich przetwarzania i administratorze danych osobowych każdorazowo są prezentowane w
              widocznym miejscu.
            </LItem>
            <LItem>Dany Użytkownik może założyć wyłącznie jedno Konto Użytkownika.</LItem>
          </OList>
          <ParagraphNo number={4} />
          <ParagraphHeader text="DOSTĘPNOŚĆ UŻYTKOWNIKA" />
          <OList>
            <LItem>
              Z Konta Użytkownika można korzystać za pomocą dowolnej przeglądarki stron internetowych. Do właściwego
              działania Konta Użytkownika niezbędna jest zgoda Użytkownika na używanie plików cookies.
            </LItem>
          </OList>
          <ParagraphNo number={5} />
          <ParagraphHeader text="REJESTRACJA I LOGOWANIE DO KONTA UŻYTKOWNIKA" />
          <OList>
            <LItem>
              Warunkiem założenia Konta Użytkownika jest przeprowadzenie przez Użytkownika w całości procedury jego
              rejestracji.
            </LItem>
            <LItem>
              Rejestracja Konta Użytkownika możliwa jest wyłącznie przez zarejestrowanie posiadanego adresu e-mail oraz
              potwierdzenie poprzez podanie kodu SMS przesłanego na wskazany w procesie rejestracji przez Użytkownika
              numer telefonu.
            </LItem>
            <LItem>
              Użytkownik, podając dane osobowe w formularzu rejestracyjnym oświadcza, że informacje podane przez niego
              Administratorowi podczas rejestracji są zgodne z prawdą.
            </LItem>
            <LItem>
              Po dokonaniu rejestracji Użytkownik uzyskuje dostęp do Profilu, którym może zarządzać samodzielnie oraz do
              innych usług udostępnionych w https://client.speedwash.pl, chyba, że regulamin danej usługi stanowi
              inaczej.
            </LItem>
            <LItem>
              Założenie Konta Użytkownika jest bezpłatne i konieczne do korzystania z części usług świadczonych na Myjni
              Speedwash(w tym m.in. do sprawdzania historii dokonywanych transakcji).
            </LItem>
            <LItem>
              Umowa o korzystanie z Konta Użytkownika zostaje zawarta pomiędzy Użytkownikiem a Administratorem na czas
              nieokreślony i jest regulowana niniejszym Regulaminem. Okres umowy rozpoczyna się wraz z zakończeniem
              przez Użytkownika procesu rejestracji.
            </LItem>
          </OList>
          <ParagraphNo number={6} />
          <ParagraphHeader text="ODPOWIEDZIALNOŚĆ" />
          <OList>
            <LItem>
              Administrator zobowiązuje Użytkownika, aby ten chronił swoje dane do logowania i nie udostępniał ich
              osobom trzecim, ponieważ wejście w posiadanie przez osoby trzeci danych do jego Konta Użytkownika może
              powodować szkody.
            </LItem>
            <LItem>
              W żadnym wypadku Użytkownik nie jest upoważniony do korzystania z danych dostępowych do Konta Użytkownika
              innego Użytkownika.
            </LItem>
            <LItem>Za sposób korzystania z Konta Użytkownika przez Użytkownika odpowiedzialny jest Użytkownik.</LItem>
          </OList>
          <ParagraphNo number={7} />
          <ParagraphHeader text="PRAWO ODSTĄPIENIA OD UMOWY" />
          <OList>
            <LItem>
              Użytkownik może odstąpić od umowy świadczenia usługi drogą elektroniczną bez podania przyczyn, składając
              stosowne oświadczenie na piśmie na adres: biuro@speedwash.pl z prośbą o usunięcie Konta Użytkownika.
            </LItem>
            <LItem>
              Administrator niezwłocznie prześle potwierdzenie otrzymania informacji o odstąpieniu od umowy pocztą
              elektroniczną na adres e-mail wskazany podczas rejestracji.
            </LItem>
            <LItem>Wypowiedzenie umowy skutkować będzie utratą dostępu do usług, które wymagają logowania.</LItem>
          </OList>
          <ParagraphNo number={8} />
          <ParagraphHeader text="OCHRONA DANYCH OSOBOWYCH" />
          <OList>
            <LItem>
              Podanie danych osobowych jest dobrowolne, jednakże konieczne w celu prawidłowej realizacji Usługi Konta
              Użytkownika.
            </LItem>
            <LItem>
              Dane osobowe Użytkownika podane w procesie rejestracji i w Profilu będą przetwarzane lub wykorzystywane
              tylko wtedy, gdy Użytkownik wyrazi na to zgodę, bądź gdy zezwalają na to obowiązujące przepisy.
            </LItem>
            <LItem>
              Udostępniane dane osobowe są chronione zgodne z postanowieniami rozporządzenia o ochronie danych osobowych
              z dnia 27 kwietnia 2016 r.
            </LItem>
            <LItem>
              Udostępnianie danych jest dobrowolne. Udostępniający ma prawo wglądu, żądania zmiany, poprawiania i
              usunięcia danych osobowych z bazy należącej do Administratora.
            </LItem>
          </OList>
          <ParagraphNo number={9} />
          <ParagraphHeader text="REKLAMACJE" />
          <OList>
            <LItem>
              Wszelkie reklamacje związane z Kontem Użytkownika i jego funkcjonowaniem powinny być zgłaszane
              Administratorowi mailowo na adres: biuro@speedwash.pl lub telefonicznie na numer telefonu: +48 606-303-117
            </LItem>
            <LItem>
              Zgłoszenia reklamacyjne będą rozpatrywane przez Administratora niezwłocznie, jednak w terminie nie
              dłuższym niż 14 dni od zgłoszenia reklamacji.
            </LItem>
            <LItem>
              Administrator zastrzega sobie prawo do wydłużenia terminu podanego w § 8 ust. 2 w przypadku, gdy
              rozpoznanie reklamacji wymaga wiadomości specjalnych lub napotka przeszkody z przyczyn niezależnych od
              Administratora (awaria sprzętu, sieci internetowej, itp.). Administrator ponadto zastrzega, że
              rozpatrzenie reklamacji może wymagać uzyskania od Użytkownika dodatkowych wyjaśnień. Czas udzielania
              wyjaśnień przez Użytkownika każdorazowo przedłuża okres rozpoznania reklamacji.
            </LItem>
            <LItem>
              Zainteresowani Użytkownicy zostaną powiadomieni o rozpatrzeniu reklamacji drogą elektroniczną,
              niezwłocznie po rozpatrzeniu reklamacji przez Administratora.
            </LItem>
            <LItem>
              Spory pomiędzy Administratorem a Użytkownikiem będą rozwiązywane polubownie, lub w przypadku braku takiej
              możliwości, przed sądem właściwym dla miejsca prowadzenia działalności gospodarczej Administratora.
            </LItem>
          </OList>
          <ParagraphNo number={10} />
          <ParagraphHeader text="ZAKOŃCZENIE" />
          <OList>
            <LItem>
              Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszym Regulaminie po odpowiednim
              uprzedzeniu Użytkownika.
            </LItem>
            <LItem>
              Jeśli w niniejszym Regulaminie lub regulaminach danych usług nie ustalono inaczej, Administrator będzie
              komunikować się z Użytkownikiem drogą mailową lub telefoniczną.
            </LItem>
            <LItem>
              Użytkownik zapewnia, że adres e-mail podany przez niego podczas rejestracji jest regularnie sprawdzany pod
              kątem wiadomości otrzymywanych przez Administratora.
            </LItem>
            <LItem>
              Użytkownicy i osoby zainteresowane udziałem w Programie mogą się kontaktować w sprawach dotyczących
              Programu oraz działania Aplikacji i zgłaszania ewentualnych nieprawidłowości w jej funkcjonowaniu
              telefonicznie na numer telefonu: +48 606-303-117, e- mailowo na adres: biuro@speedwash.pl
            </LItem>
            <LItem>Regulamin wchodzi w życie z dniem 1.03.2022 r.</LItem>
          </OList>
        </Box>
      </StretchBox>
    </Flex>
  );
};

export default Regulations;
