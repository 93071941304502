/* eslint-disable react/prop-types */
import { Heading, Text, Button, Divider, HStack, Spacer, VStack, Input } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import {
  loadData,
  setSelectedId,
  selectSelectedId,
  USER_ACCOUNT_CRUD_MODE,
  setCrudItem,
  setCrudMode,
  applyCrudChanges,
  setSelectedData,
  selectSelectedData,
} from '../features/user-accounts';

const defaultColumns = () => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.userAccounts.Index', 'No'),
    textAlign: 'end',
    defaultWidth: 75,
    sortable: false,
  },
  { name: 'login', defaultFlex: 0.6, header: i18n.t('modules.userAccounts.Login', 'Login') },
  {
    name: 'mobileNumber',
    defaultFlex: 1,
    header: i18n.t('modules.userAccounts.MobileNumber', 'Mobile Number'),
  },
  {
    name: 'contractorName',
    defaultFlex: 2,
    header: i18n.t('modules.userAccounts.ContractorName', 'Contractor'),
  },
  {
    name: 'contractorVatin',
    defaultFlex: 1,
    header: i18n.t('modules.userAccounts.ContractorVatin', 'Vatin'),
  },
  {
    name: 'id',
    header: i18n.t('modules.userAccounts.id', 'Id'),
    defaultVisible: false,
    defaultWidth: 75,
  },
];

const UserAccountsFilterBar = ({ setFilter, reload, setReload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUserAccountId = useSelector(selectSelectedId);
  const [login, setLogin] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [contractorName, setContractorName] = useState('');
  const [contractorVatin, setContractorVatin] = useState('');
  const handleClearFilters = () => {
    setLogin('');
    setMobileNumber('');
    setContractorName('');
    setContractorVatin('');
    setFilter([]);
  };

  const handleApplyFilters = () => {
    const filter = [];

    if (login !== '') {
      filter.push({
        name: 'login',
        operator: 'contains',
        type: 'string',
        value: login,
      });
    }

    if (mobileNumber !== '') {
      filter.push({
        name: 'mobileNumber',
        operator: 'contains',
        type: 'string',
        value: mobileNumber,
      });
    }

    if (contractorName !== '') {
      filter.push({
        name: 'contractorName',
        operator: 'contains',
        type: 'string',
        value: contractorName,
      });
    }

    if (contractorVatin !== '') {
      filter.push({
        name: 'contractorVatin',
        operator: 'contains',
        type: 'string',
        value: contractorVatin,
      });
    }

    setFilter(filter);
  };
  const selectedData = useSelector(selectSelectedData);
  const handleDelete = () => {
    const crudMode = USER_ACCOUNT_CRUD_MODE.DELETE;
    dispatch(setCrudMode(crudMode));
    const crudItem = {
      id: { value: selectedData?.id, error: null },
      login: { value: selectedData?.login, error: null },
      mobileNumber: { value: selectedData?.mobileNumber, error: null },
      contractorName: { value: selectedData?.cardComment, error: null },
      contractorVatin: { value: selectedData?.contractorVatin, error: null },
    };
    dispatch(setCrudItem(crudItem));
    dispatch(applyCrudChanges({ crudMode, crudItem, reload, setReload }));
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Button disabled={selectedUserAccountId == null} colorScheme="silver" minWidth={200} on onClick={handleDelete}>
        {t('modules.userAccounts.DeleteSelectedUserAccount', 'Delete selected user account')}
      </Button>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.userAccounts.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <Text size="xs">{t('modules.userAccounts.Login', 'Login')}</Text>
      <Input focusBorderColor="brand.500" size="sx" value={login} onChange={(event) => setLogin(event.target.value)} />
      <Text size="xs">{t('modules.userAccounts.MobileNumber', 'Mobile number')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sx"
        value={mobileNumber}
        onChange={(event) => setMobileNumber(event.target.value)}
      />
      <Text size="xs">{t('modules.userAccounts.ContractorName', 'Contractor')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sm"
        value={contractorName}
        onChange={(event) => setContractorName(event.target.value)}
      />
      <Text size="xs">{t('modules.userAccounts.ContractorVatin', 'Vatin')}</Text>
      <Input
        focusBorderColor="brand.500"
        size="sx"
        value={contractorVatin}
        onChange={(event) => setContractorVatin(event.target.value)}
      />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={200} onClick={handleApplyFilters}>
        {t('modules.userAccounts.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={200} onClick={handleClearFilters}>
        {t('modules.userAccounts.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const Accounts = ({ height }) => {
  const dispatch = useDispatch();
  const onSelectedIdChange = useCallback((selectedId) => {
    dispatch(setSelectedId(selectedId));
  }, []);
  const onSelectedDataChange = useCallback((data) => {
    dispatch(setSelectedData(data));
  }, []);
  const selectedUserAccountId = useSelector(selectSelectedId);
  const [filter, setFilter] = useState({});
  const [reload, setReload] = useState(0);
  const dataSource = useCallback(loadData(dispatch)({ filter }), [filter, dispatch, reload]);

  return (
    <>
      <HStack alignItems="flex-start">
        <UserAccountsFilterBar setFilter={setFilter} reload={reload} setReload={setReload} />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns()}
          onSelectedIdChange={onSelectedIdChange}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'login', dir: 1 }}
          allowUnsort={false}
          idProperty="id"
          selectedId={selectedUserAccountId}
          onSelectedDataChange={onSelectedDataChange}
        />
      </HStack>
    </>
  );
};
export default Accounts;
