const translations = {
  Index: 'L.p.',
  InvoiceNo: 'Nr faktury',
  InvoiceDate: 'Data faktury',
  Source: 'Źródło',
  GrossValue: 'Brutto [PLN]',
  NetValue: 'Netto [PLN]',
  TaxValue: 'VAT [PLN]',
  Today: 'Dziś',
  Last7: 'Ostatnie 7 dni',
  Last30: 'Ostatnie 30 dni',
  Last90: 'Ostatnie 90 dni',
  FromDate: 'Od daty',
  ToDate: 'Do daty',
  Refresh: 'Odśwież',
  RefreshBy: 'Odśwież wg zadanego zakresu dat',
  OrderBy: 'Sortuj według',
  InvoiceDateAsc: 'Data faktury rosnąco',
  InvoiceDateDesc: 'Data faktury malejąco',
  InvoiceNoAsc: 'Numer faktury rosnąco',
  InvoiceNoDesc: 'Numer faktury malejąco',
  GrossValueAsc: 'Wartość brutto rosnąco',
  GrossValueDesc: 'Wartość brutto malejąco',
  TaxValueAsc: 'Wartość VAT rosnąco',
  TaxValueDesc: 'Wartość VAT malejąco',
  DownloadSelectedInvoice: 'Pobierz wybraną fakturę',
  DownloadInvoicesReport: 'Pobierz zestawienie faktur',
  Report: 'Raport',
  Show: 'Pokaż',
  Summary: 'Razem [PLN]:',
  GrossSummary: 'brutto:',
  NetSummary: 'netto:',
  TaxSummary: 'vat:',
  Range: 'Zakres dat',
  ContractorName: 'Nabywca',
  SummaryInfoPrefix: 'Podsumowanie zawiera sumę wszystkich',
  SummaryInfoPostfix: 'rekordów',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  CurrentWeek: 'Bieżący tydzień',
  CurrentMonth: 'Bieżący miesiąc',
  PreviousMonth: 'Poprzedni miesiąc',
  DeleteLast: 'Usuń ostatnią',
  DeleteLastTooltip: 'Usuń z systemu ostatnią fakturę',
};

export default translations;
