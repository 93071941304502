import Wrapper from '@ncodefactory/dotenv-wrapper';

const environment = new Wrapper();

environment.addNumber('PORT', 3001);
environment.addNumber('REACT_APP_API_CLIENT_TIMEOUT', 200_000);
environment.addNumber('REACT_APP_GRID_FILTER_DELAY', 1000);
environment.addString('REACT_APP_COPYRIGHT_OWNER_NAME', 'bartosz.solarski@hotmail.com');
environment.addString('REACT_APP_USED_SERVER_API_VERSION', 'v1');
environment.addBoolean('REACT_APP_BLOCK_ROUTE_BEFORE_UNLOAD_COMPONENT', true);
environment.addString('REACT_APP_XSRF_COOKIE_NAME', 'XSRF-TOKEN');
environment.addString('REACT_APP_XSRF_HEADER_NAME', 'X-XSRF-TOKEN');
environment.addString('REACT_APP_STRIPE_SECRET', '');
environment.addString('REACT_APP_STRIPE_P24_COMPLETE_URL', 'http://localhost:3000/funds');
environment.addBoolean('REACT_APP_GROUP_CONTRACTOR_OPERATIONS', false);

const env = {
  PORT: environment.PORT,
  API_CLIENT_TIMEOUT: environment.REACT_APP_API_CLIENT_TIMEOUT,
  COPYRIGHT_OWNER_NAME: environment.REACT_APP_COPYRIGHT_OWNER_NAME,
  BLOCK_ROUTE_BEFORE_UNLOAD_COMPONENT: environment.REACT_APP_BLOCK_ROUTE_BEFORE_UNLOAD_COMPONENT,
  USED_SERVER_API_VERSION: environment.REACT_APP_USED_SERVER_API_VERSION,
  XSRF_COOKIE_NAME: environment.REACT_APP_XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME: environment.REACT_APP_XSRF_HEADER_NAME,
  STRIPE_SECRET: environment.REACT_APP_STRIPE_SECRET,
  GRID_FILTER_DELAY: environment.REACT_APP_GRID_FILTER_DELAY,
  STRIPE_P24_COMPLETE_URL: environment.REACT_APP_STRIPE_P24_COMPLETE_URL,
  GROUP_CONTRACTOR_OPERATIONS: environment.REACT_APP_GROUP_CONTRACTOR_OPERATIONS,
};

export { environment };
export default env;
