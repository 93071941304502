const translations = {
  Index: 'L.p.',
  Name: 'Nazwa',
  IsVcrEnabled: 'Obsługa WKF włączona',
  SwitchVcrSelectedUserAccount: 'Przełącz flagę WKF',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Message: 'Komunikat',
  VCRSwitchConfirm:
    'Flaga określająca włączoną obsługę Wirtualnych Kas Fiskalnych zostanie zmieniona na przeciwną. Kontynuować?',
  Yes: 'Tak',
  No: 'Nie',
};

export default translations;
