import { extendTheme } from '@chakra-ui/react';

const config = { initialColorMode: 'light', useSystemColorMode: false };
const styles = {
  global: (props) => ({
    '*, *::before, &::after': {
      borderColor: props.colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.300',
    },
  }),
};

const colors = {
  brand: {
    50: '#FCE8EB',
    100: '#F8BFC7',
    200: '#F395A3',
    300: '#EF6C7F',
    400: '#EA435B',
    500: '#E61937',
    600: '#B8142C',
    700: '#8A0F21',
    800: '#5C0A16',
    900: '#2E050B',
  },
  silver: {
    50: '#F0F2F5',
    100: '#D4DAE3',
    200: '#B8C3D0',
    300: '#9DABBE',
    400: '#8194AC',
    500: '#667C99',
    600: '#51637B',
    700: '#3D4B5C',
    800: '#29323D',
    900: '#14191F',
  },
};

const zIndices = {
  stripe: 1405,
  lov: 1410,
  messageDialog: 1415,
};

const components = {
  Divider: {
    baseStyle: (props) => ({
      borderColor: props.colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.400',
    }),
  },
};

const theme = extendTheme({ config, styles, colors, zIndices, components });

export default theme;
