import { useEffect, StrictMode } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import LandingLayout from './core/components/landing-layout';
import theme from './theme';
import env from '../environment';
import { checkSessionAsync, selectIsSignedIn } from './features/session';
import { closeAllToasts } from './features/toast';

const blockRoute = env.BLOCK_ROUTE_BEFORE_UNLOAD_COMPONENT;
// eslint-disable-next-line react/prop-types
const App = ({ hideLoader }) => {
  useEffect(hideLoader, []);
  closeAllToasts();
  const dispatch = useDispatch();

  if (blockRoute) {
    useBeforeunload((event) => event.preventDefault());
  }

  const isSignedIn = useSelector(selectIsSignedIn);
  if (!isSignedIn) {
    dispatch(checkSessionAsync());
  }

  return (
    <StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <Router>
          <LandingLayout />
        </Router>
      </ChakraProvider>
    </StrictMode>
  );
};

export default App;
