const translations = {
  layout: {
    title: 'Panel Administratora',
    Invoices: 'Faktury',
    Transactions: 'Transakcje',
    RFIDCards: 'Użytkownicy',
    Report: 'Raport obrotów',
    Payments: 'Zestawienie płatności',
    Spp: 'Minikasa',
    UserAccounts: 'Konta użytkowników',
    Devices: 'Urządzenia',
  },
  sppLayout: {
    SppOperationsHistory: 'Historia doładowań kart',
    SppLogs: 'Logi terminal',
    SalesEverything: 'Sprzedaż, wszystko',
  },
};

export default translations;
