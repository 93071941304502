const translations = {
  'Sign in failed': 'Logowanie nie powiodło się',
  'Check login and/or password and try again. If the problem persists, contact your system administrator':
    'Sprawdź login i/lub hasło i spróbuj ponownie. Jeżeli problem będzie się powtarzał, skontaktuj się z administratorem systemu',
  'Signed In': 'Zalogowany',
  User: 'Użytkownik',
  'has been signed in': 'został zalogowany poprawnie',
  'Sign in error': 'Błąd logowania',
  'Signed Out': 'Wylogowany',
  'has been signed out': 'został wylogowany poprawnie',
  'Sign out error': 'Błąd wylogowywania',
  'Session restored': 'Sesja przywrócona',
  'Last session restored successfully': 'Ostatnia sesja została przywrócona poprawnie',
  'Check session error': 'Błąd weryfikacji sesji',
  'Signed Up': 'Zarejestrowany',
  'has been signed up': 'został zarejestrowany poprawnie',
  'Sign up error': 'Błąd rejestracji',
  'Verification code sent': 'Kod weryfikacyjnym został wysłany',
  'We have sent a verification code to your mobile. Check your messages':
    'Wysłaliśmy kod weryfikacyjny na twoją komórkę. Sprawdź wiadomości',
  'Verification code sending error': 'Błąd wysyłania kodu weryfikacyjnego',
  'Password changed': 'Hasło zostało zmienione',
  'Password changed successfully. Sign in with new password':
    'Zmiana hasła przebiegła prawidłowo. Zaloguj się za pomocą nowego hasła',
  'Password change error': 'Błąd zmiany hasła',
};

export default translations;
