const translations = {
  OperationDate: 'Start',
  TypeDescription: 'Rodzaj operacji/transakcji',
  OperationDescription: 'Opis',
  Source: 'Źródło',
  Value: 'Wart. brutto [PLN]',
  InvoiceNo: 'Nr faktury',
  RFIDTag: 'Nr karty/breloka',
  RFIDDesc: 'Użytkownik',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Range: 'Przedział czasu',
  FromDate: 'Start od',
  ToDate: 'Start do',
  Content: 'Zawartość',
  CurrentWeek: 'Bieżący tydzień',
  CurrentMonth: 'Bieżący miesiąc',
  PreviousMonth: 'Poprzedni miesiąc',
};

export default translations;
