import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { selectContractorView } from '../features/account';
import StretchBox from '../core/components/stretch-box';
import Operations from '../modules/operations';

const Home = () => {
  const location = useLocation();
  const history = useHistory();
  if (location.search != null && location.search !== '') {
    history.push(`/funds${location.search}`);
  }
  const contractor = useSelector(selectContractorView);
  return (
    <Flex grow="1" alignItems="stretch" direction="column" justifyContent="stretch" p="4">
      {contractor == null ? (
        <></>
      ) : (
        <StretchBox yOffset={150}>
          <Operations contractorId={contractor?.id} />
        </StretchBox>
      )}
    </Flex>
  );
};

export default Home;
