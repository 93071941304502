import React from 'react';
import {
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectLovProps, setLovIsOpen, resetLovProps } from '../../../features/lov';
import LovTable from './lov-table';

const buttonMargin = 2;
const LovButton = (props) => <Button size="sm" minWidth={75} {...props} />;

const Lov = ({ isOpen }) => {
  const { t } = useTranslation();
  const { title, actions, columns, data, selectedRowIds, multiSelect, size } = useSelector(selectLovProps);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setLovIsOpen(false));
    dispatch(resetLovProps);
    if (actions.close) {
      dispatch(actions.close);
    }
  };
  const okDisabled = multiSelect ? Object.keys(selectedRowIds).length === 0 : Object.keys(selectedRowIds).length !== 1;
  const handleOk = () => {
    if (actions.ok) {
      dispatch(actions.ok(selectedRowIds));
    }

    handleClose();
  };

  const handleCancel = () => {
    if (actions.cancel) {
      dispatch(actions.cancel);
    }

    handleClose();
  };

  const handleEsc = () => {
    handleCancel();
    handleClose();
  };

  const lovColumns = React.useMemo(() => columns, []);
  const lovData = React.useMemo(() => data, []);
  return (
    <Box zIndex="lov">
      <AlertDialog size={size} isOpen={isOpen} isCentered closeOnOverlayClick onEsc={handleEsc}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <Divider />
            <Box my={0}>
              <AlertDialogBody>
                <LovTable columns={lovColumns} data={lovData} multiSelect={multiSelect} />
              </AlertDialogBody>
            </Box>
            <Divider />
            <AlertDialogFooter>
              <LovButton colorScheme="brand" onClick={handleOk} disabled={okDisabled}>
                {t('components.ui.lov.Ok', 'Ok')}
              </LovButton>
              <LovButton ml={buttonMargin} onClick={handleCancel}>
                {t('components.ui.lov.Cancel', 'Cancel')}
              </LovButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

Lov.defaultProps = {
  isOpen: false,
};

Lov.propTypes = {
  isOpen: PropTypes.bool,
};
export default Lov;
