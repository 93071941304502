// import validator from 'validator';
import { Flex, Box, FormControl, FormErrorMessage, FormLabel, Heading, Button, Input, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signInAsync, selectIsSigning, selectIsRestoring } from '../features/session';
import EposIcon from '../core/components/brand/epos-icon';
import ColorModeSwitcher from '../core/components/color-mode-switcher';
import LangSwitcher from '../core/components/lang-switcher';
import { selectIsBusy } from '../features/app';
import CircularProgres from '../core/components/circular-progress';

const SignInHeader = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center">
      <Heading>{t('pages.sign-in.Sign In', 'Sign In')}</Heading>
    </Box>
  );
};

const SignInForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const isSigning = useSelector(selectIsSigning);
  const isBusy = useSelector(selectIsBusy);
  // const contractor = useSelector(selectContractorView);
  const validateLogin = (value) => {
    if (!value) {
      return t('pages.sign-in.A value in this field is required', 'A value in this field is required');
    }

    // if (!validator.isEmail(value)) {
    //   return t('pages.sign-up.LoginMustBeEmail', 'Login must be a valid e-mail adres');
    // }

    return true;
  };

  const validatePassword = (value) => {
    if (!value) {
      return t('pages.sign-in.A value in this field is required', 'A value in this field is required');
    }

    return true;
  };

  const handleCreateAccount = () => {
    history.push('/signup');
  };

  const handleRecoverPassword = () => {
    history.push('/password-change');
  };

  const onSubmit = (values) => {
    dispatch(
      signInAsync({
        credentials: values,
        callback: (location) => {
          history.push(location);
        },
      }),
    );
  };

  const isLoading = isSigning || formState.isSubmitting;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormControl mt={4} isInvalid={errors.login?.message}>
          <FormLabel htmlFor="login">{t('pages.sign-in.Login', 'Login (e-mail address)')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            autoFocus
            {...register('login', { validate: validateLogin })}
            type="login"
            placeholder={t('pages.sign-in.enter your login name', 'enter your e-mail address')}
            onChange={(event) => {
              const { value } = event.target;
              // eslint-disable-next-line no-param-reassign
              event.target.value = (value ?? '').toLowerCase();
            }}
          />
          <FormErrorMessage>{errors.login?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={4} isInvalid={errors.password?.message}>
          <FormLabel htmlFor="password">{t('pages.sign-in.Password', 'Password')}</FormLabel>
          <Input
            focusBorderColor="brand.500"
            {...register('password', { validate: validatePassword })}
            type="password"
            placeholder={t('pages.sign-in.enter your password', 'enter your password')}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <Stack color="brand.500" justifyContent="space-between" direction={['column', 'row', 'row']}>
          <Button colorScheme="brand" variant="link" disabled={isBusy} onClick={handleCreateAccount}>
            {t('pages.sign-in.Do not have account', "Don't have account? Create!")}
          </Button>
          <Button colorScheme="brand" variant="link" disabled={isBusy} onClick={handleRecoverPassword}>
            {t('pages.sign-in.Forgot your password', 'Forgot your password?')}
          </Button>
        </Stack>
        <Button width="full" my={4} colorScheme="brand" isLoading={isLoading} disabled={isBusy} type="submit">
          {t('pages.sign-in.submit', 'Sign In')}
        </Button>
      </Box>
    </form>
  );
};

const SignIn = () => {
  const isRestoring = useSelector(selectIsRestoring);

  return isRestoring ? (
    <CircularProgres />
  ) : (
    <Flex grow="1" alignItems="center" direction="column" justifyContent="center">
      <Box
        borderWidth={1}
        px={4}
        borderRadius="xl"
        boxShadow="lg"
        minWidth={[400, 400, 500]}
        maxWidth={[400, 500, 500]}
      >
        <Stack direction="row" justifyContent="space-between" mr={4} mt={4} width="full">
          <LangSwitcher size="sm" />
          <ColorModeSwitcher size="sm" />
        </Stack>
        <Box textAlign="center">
          <EposIcon boxSize={16} color="brand.500" />
        </Box>
        <Box p={4}>
          <SignInHeader />
          <SignInForm />
        </Box>
      </Box>
    </Flex>
  );
};

export default SignIn;
