const translations = {
  GUS: { lovTitle: 'Znalezione podmioty', Name: 'Nazwa', Address1: 'Ulica nr domu', Address2: 'Kod pocztowy' },

  contractor: {
    SuccessToastTitle: 'Operacja zakończona sukcesem',
    DataSuccessfullyChanged: 'Dane kontrahenta zostały zmienione',
    ContractorFundsSuccessfully: 'Fundusze kontrahenta zostały zmienione',
  },
};

export default translations;
