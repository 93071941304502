import { Flex, VStack, CircularProgress as Progress, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CircularProgres = () => {
  const { t } = useTranslation();
  return (
    <Flex grow="1" alignItems="center" direction="column" justifyContent="center">
      <VStack>
        <Progress isIndeterminate color="brand.500" />
        <Text>{t('Please wait...', 'Please wait...')}</Text>
      </VStack>
    </Flex>
  );
};

export default CircularProgres;
