import { Flex, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import StretchBox from '../../core/components/stretch-box';
// import SppLogs from '../../modules/spp-logs';
import SppOperations from '../../modules/spp-operations';
import SppSalesEverything from '../../modules/spp-sales-everything';

// eslint-disable-next-line react/prop-types
const SppLayout = ({ upAdmin }) => {
  const { t } = useTranslation();
  return (
    <Flex p="0" grow="1" alignItems="stretch" direction="column" justifyContent="stretch">
      <Tabs grow="1" isLazy variant="enclosed" colorScheme="brand">
        <TabList>
          <Tab>{t('pages.adminPanel.sppLayout.SppOperationsHistory', 'History of top-ups')}</Tab>
          {/* <Tab>{t('pages.adminPanel.sppLayout.SppLogs', 'Terminal logs')}</Tab> */}
          <Tab>{t('pages.adminPanel.sppLayout.SalesEverything', 'Sales, everything')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <StretchBox yOffset={250}>
              <SppOperations upAdmin={upAdmin} />
            </StretchBox>
          </TabPanel>
          {/* <TabPanel>
            <StretchBox yOffset={250}>
              <SppLogs upAdmin={upAdmin} />
            </StretchBox>
          </TabPanel> */}
          <TabPanel>
            <StretchBox yOffset={250}>
              <SppSalesEverything upAdmin={upAdmin} />
            </StretchBox>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default SppLayout;
