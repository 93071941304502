import AdminPanel from './admin-panel';
import Home from './home';
import PasswordChange from './password-change';
import Invoices from './invoices';
import Account from './account';
import RfidCards from './rfid-cards';
import Funds from './funds';
// import Shop from './shop';
import SignIn from './sign-in';
import SignUp from './sign-up';

const navBarPages = [
  { index: 1, to: '/', title: 'pages.Home', defaultTitle: 'History', roles: [], checkContractor: false },
  // { index: 2, to: '/shop', title: 'pages.Shop', defaultTitle: 'Shop', roles: [], checkContractor: true },
  { index: 3, to: '/users', title: 'pages.RFID Cards', defaultTitle: 'Users', roles: [], checkContractor: true },
  {
    index: 4,
    to: '/invoices',
    title: 'pages.Invoices',
    defaultTitle: 'Invoices',
    roles: [],
    checkContractor: true,
  },
  { index: 5, to: '/funds', title: 'pages.Funds', defaultTitle: 'Top-up', roles: [], checkContractor: true },
  {
    index: 6,
    to: '/admin',
    title: 'pages.Admin panel',
    defaultTitle: 'Admin panel',
    roles: ['admin', 'backoffice'],
    checkContractor: false,
  },
];

export {
  navBarPages,
  AdminPanel,
  Home,
  PasswordChange,
  Invoices,
  Account,
  RfidCards,
  /* Shop, */ SignIn,
  SignUp,
  Funds,
};
