import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import sessionReducer, { registerGetStateInSessionSlice } from './features/session';
import messageDialogReducer, { registerGetStateInMessageDialogSlice } from './features/message-dialog';
import { registerGetStateInToastSlice } from './features/toast';
import lovReducer, { registerGetStateInLovSlice } from './features/lov';
import accountReducer, { registerGetStateInAccountSlice } from './features/account';
import formsReducer, { registerGetStateInFormsSlice } from './features/forms';
import stripeReducer, { registerGetStateInStripeSlice } from './features/stripe';
import appReducer, { registerGetStateInAppSlice } from './features/app';
import invoicesReducer, { registerGetStateInInvoicesSlice } from './features/invoices';
import transactionsReducer, { registerGetStateInTransactionsSlice } from './features/transactions';
import rfidCardsReducer, { registerGetStateInRfidCardsSlice } from './features/rfid-cards';
import operationsReducer, { registerGetStateInOperationsSlice } from './features/operations';
import reportReducer, { registerGetStateInReportSlice } from './features/report';
import paymentsReducer, { registerGetStateInPaymentsSlice } from './features/payments';
import sppLogsReducer, { registerGetStateInSppLogsSlice } from './features/spp-logs';
import sppSalesEverythingReducer, { registerGetStateInSppSalesEverythingSlice } from './features/spp-sales-everything';
import sppOperationsReducer, { registerGetStateInSppOperationsSlice } from './features/spp-operations';
import userAccountsReducer, { registerGetStateInUserAccountsSlice } from './features/user-accounts';
import devicesReducer, { registerGetStateInDevicesSlice } from './features/devices';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    messageDialog: messageDialogReducer,
    lov: lovReducer,
    account: accountReducer,
    forms: formsReducer,
    stripe: stripeReducer,
    app: appReducer,
    invoices: invoicesReducer,
    transactions: transactionsReducer,
    rfidCards: rfidCardsReducer,
    operations: operationsReducer,
    report: reportReducer,
    payments: paymentsReducer,
    sppLogs: sppLogsReducer,
    sppSalesEverything: sppSalesEverythingReducer,
    sppOperations: sppOperationsReducer,
    userAccounts: userAccountsReducer,
    devices: devicesReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

registerGetStateInSessionSlice(store.getState);
registerGetStateInToastSlice(store.getState);
registerGetStateInMessageDialogSlice(store.getState);
registerGetStateInLovSlice(store.getState);
registerGetStateInAccountSlice(store.getState);
registerGetStateInFormsSlice(store.getState);
registerGetStateInStripeSlice(store.getState);
registerGetStateInAppSlice(store.getState);
registerGetStateInInvoicesSlice(store.getState);
registerGetStateInTransactionsSlice(store.getState);
registerGetStateInRfidCardsSlice(store.getState);
registerGetStateInOperationsSlice(store.getState);
registerGetStateInReportSlice(store.getState);
registerGetStateInPaymentsSlice(store.getState);
registerGetStateInSppLogsSlice(store.getState);
registerGetStateInSppSalesEverythingSlice(store.getState);
registerGetStateInSppOperationsSlice(store.getState);
registerGetStateInUserAccountsSlice(store.getState);
registerGetStateInDevicesSlice(store.getState);

export default store;
