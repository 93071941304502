const translations = {
  Index: 'L.p.',
  Login: 'Login',
  MobileNumber: 'Nr telefonu',
  ContractorName: 'Kontrahent',
  ContractorVatin: 'NIP',
  DeleteSelectedUserAccount: 'Usuń konto użytkownika',
  Filters: 'Filtry',
  ApplyFilters: 'Zastosuj filtry',
  ClearFilters: 'Wyczyść filtry',
  Message: 'Komunikat',
  UserAccountDeleteConfirm: 'Wybrane konto użytkownika zostanie usunięte. Kontynuować?',
};

export default translations;
