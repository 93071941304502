import account from './account-translations';
import contractor from './contractor-translations';

const translations = {
  profileManagement: 'zarządzanie profilem',
  Account: 'Konto',
  Contractor: 'Kontrahent',
  account,
  contractor,
};

export default translations;
