import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInFormsSlice = (storeGetState) => {
  getState = storeGetState;
};

const slice = createSlice({
  name: 'forms',
  initialState: {},
  reducers: {
    setFormProps: (state, action) => {
      const { id, props } = action.payload;
      const stateRef = state;
      stateRef[id] = props;
    },
    resetFormProps: (state, action) => {
      const stateRef = state;
      stateRef[action.payload.id] = {};
    },
  },
});

const { setFormProps, resetFormProps } = slice.actions;

const selectFormProps = (formId) => (state) => state.forms[formId];

export { slice, selectFormProps, setFormProps, resetFormProps, registerGetStateInFormsSlice };

export default slice.reducer;
