/* eslint-disable react/prop-types */
import { Heading, Text, Button, Divider, HStack, Spacer, VStack, Input } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import {
  loadData,
  setSelectedId,
  selectSelectedId,
  DEVICE_CRUD_MODE,
  setCrudItem,
  setCrudMode,
  applyCrudChanges,
  setSelectedData,
  selectSelectedData,
} from '../features/devices';

// eslint-disable-next-line react/prop-types
const LockedRender = ({ value }) => (
  <div style={{ display: 'inline-block', color: value ? '#ff595e' : null }}>
    {value ? i18n.t('modules.devices.Yes', 'Yes') : i18n.t('modules.devices.No', 'No')}
  </div>
);
const defaultColumns = () => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.devices.Index', 'No'),
    textAlign: 'end',
    defaultWidth: 75,
    sortable: false,
  },
  { name: 'name', defaultFlex: 2, header: i18n.t('modules.devices.Name', 'Name') },
  {
    name: 'isVcrEnabled',
    header: i18n.t('modules.devices.IsVcrEnabled', 'VCR support is enabled'),
    type: 'bool',
    defaultWidth: 300,
    textAlign: 'center',
    render: ({ value }) => LockedRender({ value }),
  },
  {
    name: 'id',
    header: i18n.t('modules.devices.id', 'Id'),
    defaultVisible: false,
    defaultWidth: 75,
  },
];

const DevicesFilterBar = ({ setFilter, reload, setReload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedDeviceId = useSelector(selectSelectedId);
  const [name, setName] = useState('');
  const handleClearFilters = () => {
    setName('');
    setFilter([]);
  };

  const handleApplyFilters = () => {
    const filter = [];

    if (name !== '') {
      filter.push({
        name: 'name',
        operator: 'contains',
        type: 'string',
        value: name,
      });
    }

    setFilter(filter);
  };
  const selectedData = useSelector(selectSelectedData);
  const handleSwitch = () => {
    const crudMode = DEVICE_CRUD_MODE.SWITCH_VCR;
    dispatch(setCrudMode(crudMode));
    const crudItem = {
      id: { value: selectedData?.id, error: null },
      name: { value: selectedData?.name, error: null },
      isVcrEnabled: { value: selectedData?.isVcrEnabled, error: null },
    };
    dispatch(setCrudItem(crudItem));
    dispatch(applyCrudChanges({ crudMode, crudItem, reload, setReload }));
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Button disabled={selectedDeviceId == null} colorScheme="silver" minWidth={200} on onClick={handleSwitch}>
        {t('modules.devices.SwitchVcrSelectedUserAccount', 'Switch VCR flag')}
      </Button>
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.devices.Filters', 'Filters')}
      </Heading>
      <Divider orientation="horizontal" />
      <Text size="xs">{t('modules.devices.Name', 'Name')}</Text>
      <Input focusBorderColor="brand.500" size="sx" value={name} onChange={(event) => setName(event.target.value)} />
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={200} onClick={handleApplyFilters}>
        {t('modules.devices.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={200} onClick={handleClearFilters}>
        {t('modules.devices.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

const Devices = ({ height }) => {
  const dispatch = useDispatch();
  const onSelectedIdChange = useCallback((selectedId) => {
    dispatch(setSelectedId(selectedId));
  }, []);
  const onSelectedDataChange = useCallback((data) => {
    dispatch(setSelectedData(data));
  }, []);
  const selectedDeviceId = useSelector(selectSelectedId);
  const [filter, setFilter] = useState({});
  const [reload, setReload] = useState(0);
  const dataSource = useCallback(loadData(dispatch)({ filter }), [filter, dispatch, reload]);

  return (
    <>
      <HStack alignItems="flex-start">
        <DevicesFilterBar setFilter={setFilter} reload={reload} setReload={setReload} />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns()}
          onSelectedIdChange={onSelectedIdChange}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'name', dir: 1 }}
          allowUnsort={false}
          idProperty="id"
          selectedId={selectedDeviceId}
          onSelectedDataChange={onSelectedDataChange}
        />
      </HStack>
    </>
  );
};
export default Devices;
