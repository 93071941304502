import keyMirror from 'keymirror';
import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line no-unused-vars
let getState;
const registerGetStateInMessageDialogSlice = (storeGetState) => {
  getState = storeGetState;
};

const MESSAGE_DIALOG_TYPE = keyMirror({
  INFORMATION: null,
  CONFIRMATION: null,
  WARNING: null,
  ERROR: null,
});

const MESSAGE_DIALOG_BUTTONS = keyMirror({
  OK: null,
  OK_CANCEL: null,
  YES_NO: null,
  YES_NO_CANCEL: null,
});

const slice = createSlice({
  name: 'messageDialog',
  initialState: {
    isOpen: false,
    title: null,
    text: null,
    type: null,
    buttons: null,
    actions: {},
  },
  reducers: {
    setMessageDialogProps: (state, action) => {
      const { title, text, type, buttons, actions } = action.payload;
      const stateRef = state;
      stateRef.title = title;
      stateRef.text = text;
      stateRef.type = type;
      stateRef.buttons = buttons;
      stateRef.actions = actions ?? {};
    },
    resetMessageDialogProps: (state) => {
      const stateRef = state;
      stateRef.title = null;
      stateRef.text = null;
      stateRef.type = null;
      stateRef.buttons = null;
      stateRef.actions = {};
    },
    setMessageDialogIsOpen: (state, action) => {
      const stateRef = state;
      stateRef.isOpen = action.payload;
    },
  },
});

const { setMessageDialogIsOpen, setMessageDialogProps, resetMessageDialogProps } = slice.actions;

const selectMessageDialogProps = (state) => state.messageDialog;
const selectMessageDialogIsOpen = (state) => state.messageDialog.isOpen;

const showMessageDialogAsync =
  ({ title, text, type, buttons, actions }) =>
  async (dispatch) => {
    dispatch(setMessageDialogProps({ title, text, type, buttons: buttons || MESSAGE_DIALOG_BUTTONS.OK, actions }));
    dispatch(setMessageDialogIsOpen(true));
  };

export {
  slice,
  MESSAGE_DIALOG_TYPE,
  MESSAGE_DIALOG_BUTTONS,
  setMessageDialogProps,
  resetMessageDialogProps,
  selectMessageDialogProps,
  selectMessageDialogIsOpen,
  setMessageDialogIsOpen,
  showMessageDialogAsync,
  registerGetStateInMessageDialogSlice,
};

export default slice.reducer;
