import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { loadData, setSelectedId, selectSelectedId } from '../features/transactions';
import env from '../../environment';

const defaultColumns = [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.transactions.Index', 'No'),
    textAlign: 'end',
    defaultWidth: 75,
    sortable: false,
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'createdAt',
    header: i18n.t('modules.transactions.OperationDate', 'Operation date'),
    allowUnsort: false,
    defaultWidth: 175,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'YYYY-MM-DD',
      cancelButton: false,
      highlightWeekends: true,
    }),
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'typeDescription',
    header: i18n.t('modules.transactions.TypeDescription', 'Operation type'),
    allowUnsort: false,
    defaultWidth: 200,
    filterDelay: env.GRID_FILTER_DELAY,
  },
  { name: 'rfidTag', header: i18n.t('modules.transactions.RFIDTag', 'User id'), filterDelay: env.GRID_FILTER_DELAY },
  {
    name: 'invoiceNo',
    header: i18n.t('modules.transactions.InvoiceNo', 'Invoice no'),
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'contractorId',
    type: 'number',
    defaultVisible: false,
    header: i18n.t('modules.transactions.ContractorId', 'Contractor Id'),
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'contractor',
    defaultFlex: 2,
    header: i18n.t('modules.transactions.Contractor', 'Contractor'),
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'source',
    defaultFlex: 1,
    header: i18n.t('modules.transactions.Source', 'Source'),
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'properties',
    header: i18n.t('modules.transactions.Properties', 'Properties'),
    defaultFlex: 2,
    filterDelay: env.GRID_FILTER_DELAY,
  },
  {
    name: 'id',
    header: i18n.t('modules.transactions.TransactionId', 'Transaction id'),
    filterDelay: env.GRID_FILTER_DELAY,
  },
];

const defaultFilterValue = [
  { name: 'createdAt', operator: 'eq', type: 'date', value: '' },
  { name: 'typeDescription', operator: 'contains', type: 'string', value: '' },
  { name: 'rfidTag', operator: 'startsWith', type: 'string', value: '' },
  { name: 'invoiceNo', operator: 'startsWith', type: 'string', value: '' },
  { name: 'contractor', operator: 'contains', type: 'string', value: '' },
  { name: 'source', operator: 'startsWith', type: 'string', value: '' },
  { name: 'properties', operator: 'contains', type: 'string', value: '' },
  { name: 'id', operator: 'eq', type: 'string', value: '' },
];

// eslint-disable-next-line react/prop-types, no-unused-vars
const Transactions = ({ height, upAdmin }) => {
  const dispatch = useDispatch();
  const onSelectedIdChange = useCallback((selectedId) => {
    dispatch(setSelectedId(selectedId));
  }, []);
  const dataSource = useCallback(loadData(dispatch), [dispatch]);
  const selectedTransactionId = useSelector(selectSelectedId);
  return (
    <DataGrid
      height={height}
      dataSource={dataSource}
      columns={defaultColumns}
      onSelectedIdChange={onSelectedIdChange}
      pagination="remote"
      skip={0}
      limit={50}
      sortInfo={{ name: 'createdAt', dir: -1 }}
      allowUnsort={false}
      idProperty="id"
      selectedId={selectedTransactionId}
      filterValue={defaultFilterValue}
    />
  );
};
export default Transactions;
