import { Flex } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from './footer';
import LandingHeader from './landing-header';
import { selectIsSignedIn } from '../../features/session';
import MessageDialog from './message-dialog';
import Stripe from './stripe';
import {
  AdminPanel,
  Home,
  Invoices,
  Account,
  RfidCards,
  // Shop,
  SignIn,
  SignUp,
  PasswordChange,
  Funds,
} from '../../pages';
import CookieConsent from './cookie-consent';
import NotFound from './not-found';
import Lov from './lov';
import { selectLovIsOpen } from '../../features/lov';
import PrivacyPolicy from '../../pages/privacy-policy';
import Regulations from '../../pages/regulations';

const LandingLayout = () => {
  const isSignedIn = useSelector(selectIsSignedIn);
  const lovIsOpen = useSelector(selectLovIsOpen);
  return (
    <Flex minHeight="100vh" direction="column" alignContent="space-between">
      <LandingHeader />
      <Flex grow="1">
        <Switch>
          <Route exact path="/" component={isSignedIn ? Home : SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/password-change" component={PasswordChange} />
          {/* <Route exact path="/shop" component={isSignedIn ? Shop : SignIn} /> */}
          <Route exact path="/users" component={isSignedIn ? RfidCards : SignIn} />
          <Route exact path="/funds" component={isSignedIn ? Funds : SignIn} />
          <Route exact path="/invoices" component={isSignedIn ? Invoices : SignIn} />
          <Route exact path="/admin" component={isSignedIn ? AdminPanel : SignIn} />
          <Route exact path="/account" component={isSignedIn ? Account : SignIn} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/regulations" component={Regulations} />
          <Route component={NotFound} />
        </Switch>
      </Flex>
      <CookieConsent />
      <Footer />
      {isSignedIn && <Stripe />}
      {isSignedIn && lovIsOpen && <Lov isOpen={lovIsOpen} />}
      <MessageDialog />
    </Flex>
  );
};

export default LandingLayout;
